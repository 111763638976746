import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { DrawerDataProps } from "@pages/wrapper/drawer/drawer.interface";
import {
  DrawerItemControllerProps,
  DrawerItemProps,
} from "./drawerItem.interface";
import { ItemProps } from "@component/masterDropdown/masterDropdown.interface";
import { logout, setNavigation } from "@services/redux/user/action";
import { removeUserList } from "@services/redux/userList/action";
import { TableCellDataProps } from "@component/customTable/table.interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";

const drawerItemController = ({
  data,
  onItemClick,
  isMobile,
  handleCollapse,
  id,
}: DrawerItemProps): DrawerItemControllerProps => {
  const navigation = useNavigate();
  const [masterId, setMasterId] = useState<number>();
  const [openSubDetails, setOpenSubDetails] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { navigationTo } = useAppSelector((state) => state.userReducer);

  const theme = useTheme();

  const selectedItem = (item: TableCellDataProps): string => {
    if (item.title === navigationTo) {
      return theme.palette.secondary.main;
    } else {
      return theme.palette.common.white;
    }
  };

  const onLogout = (): void => {
    navigation("/");
    dispatch(logout());
    dispatch(removeUserList());
  };

  const onItemPress = (item: DrawerDataProps): void => {
    item.navigation && dispatch(setNavigation(item.title));
    item.navigation && navigation(item.navigation);
    if (item.expand) {
      dispatch(
        setNavigation(item.title === "Master" ? "Master" : "Post Requirement")
      );
      handleCollapse(item.id);
      if (item?.id != id) {
        setOpenSubDetails(false);
        setOpenSubDetails(true);
      } else {
        setOpenSubDetails(!openSubDetails);
      }
    }
  };

  const onMasterData = (item: ItemProps): void => {
    navigation(item?.screen);
    setMasterId(item?.id);
    isMobile && onItemClick && onItemClick();
  };

  return {
    selectedItem,
    openSubDetails,
    onItemPress,
    onMasterData,
    onLogout,
    masterId,
  };
};

export default drawerItemController;
