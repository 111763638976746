import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Button, styled, lighten, List } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  margin: theme.spacing(4, 0),
  padding: theme.spacing(4, 0),
  textTransform: "none",
  "&:hover": {
    background: lighten(theme.palette.primary.main, 0.3),
  },
}));

export const StyledLogout = styled(Button)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  height: 50,
  background: lighten(theme.palette.primary.main, 0.2),
  marginBottom: theme.spacing(8),
  textTransform: "none",
  "&:hover": {
    background: lighten(theme.palette.primary.main, 0.3),
  },
}));

export const StyledBorder = styled(Box)(({ theme }) => ({
  height: 20,
  width: theme.spacing(2),
  borderRadius: theme.spacing(4),
}));

export const StyledExpandMoreIcon = styled(ExpandMore)(({ theme }) => ({
  marginLeft: theme.spacing(6),
  color: theme.palette.common.white,
}));

export const StyledExpandLessIcon = styled(ExpandLess)(({ theme }) => ({
  marginLeft: theme.spacing(6),
  color: theme.palette.common.white,
}));

export const StyledListContainer = styled(Box)(({ theme }) => ({
  height: "90%",
  overflow: "hidden",
  position: "relative",
}));

export const StyledList = styled(List)(({ theme }) => ({
  overflow: "auto",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: "-15px",
  overflowY: "scroll",
  marginBottom: theme.spacing(18),
}));
