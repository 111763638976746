export const expertiseData = [
  {
    id: 1,
    name: "None",
  },
  {
    id: 2,
    name: "Helper",
    children: [
      {
        id: 3,
        name: "Cylindrical Lathe",
      },
      {
        id: 4,
        name: "VTL",
      },
      {
        id: 5,
        name: "Drilling",
      },
      {
        id: 6,
        name: "Shaping",
      },
      {
        id: 7,
        name: "Milling",
      },
      {
        id: 8,
        name: "Boring",
      },
      {
        id: 9,
        name: "Plano miller",
      },
      {
        id: 10,
        name: "VMC",
      },
      {
        id: 11,
        name: "CNC",
      },
      {
        id: 12,
        name: "Honning",
      },
      {
        id: 13,
        name: "Other",
      },
    ],
  },
  {
    id: 14,
    name: "Fitter",
  },
  {
    id: 15,
    name: "Welder",
    children: [
      {
        id: 16,
        name: "Argen",
      },
      {
        id: 17,
        name: "Gas",
      },
      {
        id: 18,
        name: "Mig",
      },
      {
        id: 19,
        name: "Spot",
      },
      {
        id: 20,
        name: "Arc",
      },
      {
        id: 21,
        name: "Other",
      },
    ],
  },
  {
    id: 22,
    name: "Operator",
    children: [
      {
        id: 23,
        name: "Cylindrical Lathe",
      },
      {
        id: 24,
        name: "VTL",
      },
      {
        id: 25,
        name: "Surface grinding",
      },
      {
        id: 26,
        name: "Centerless grinding",
      },
      {
        id: 27,
        name: "Cylindrical grinding",
      },
      {
        id: 28,
        name: "Polishing",
      },
      {
        id: 29,
        name: "Wire cut",
      },
      {
        id: 30,
        name: "EDM/Spark",
      },
      {
        id: 31,
        name: "Radial Drilling",
      },
      {
        id: 32,
        name: "Tapping",
      },
      {
        id: 33,
        name: "DRO milling",
      },
      {
        id: 34,
        name: "Conventional milling",
      },
      {
        id: 35,
        name: "Table Boring",
      },
      {
        id: 36,
        name: "Floor Boring",
      },
      {
        id: 37,
        name: "CNC Lathe",
      },
      {
        id: 38,
        name: "CNC VTL",
      },
      {
        id: 39,
        name: "VMC",
        children: [
          {
            id: 40,
            name: "3 Axis",
          },
          {
            id: 41,
            name: "4 Axis",
          },
          {
            id: 42,
            name: "5 Axis",
          },
        ],
      },
      {
        id: 43,
        name: "Turn mill",
      },
      {
        id: 44,
        name: "Honning",
      },
      {
        id: 45,
        name: "Broaching",
      },
      {
        id: 46,
        name: "Deep Hole",
      },
      {
        id: 47,
        name: "Waterjet",
      },
      {
        id: 48,
        name: "Shapping",
      },
      {
        id: 49,
        name: "Planing",
      },
      {
        id: 50,
        name: "Gear shaper",
      },
      {
        id: 51,
        name: "Tool and Cutter",
      },
      {
        id: 52,
        name: "Laser cutting",
      },
      {
        id: 53,
        name: "Plano miller",
      },
      {
        id: 54,
        name: "Hobbing",
      },
      {
        id: 55,
        name: "Trob operator",
      },
      {
        id: 56,
        name: "Band saw",
      },
      {
        id: 57,
        name: "Profile projector",
      },
      {
        id: 58,
        name: "Lapping",
      },
      {
        id: 59,
        name: "Sheet Cutting",
      },
      {
        id: 60,
        name: "Bending",
        children: [
          {
            id: 61,
            name: "CNC",
          },
          {
            id: 62,
            name: "Conventional",
          },
        ],
      },
      {
        id: 63,
        name: "Hydraulic press",
        children: [
          {
            id: 64,
            name: "Manual",
          },
          {
            id: 65,
            name: "CNC",
          },
        ],
      },
      {
        id: 66,
        name: "Gas Cutting",
      },
      {
        id: 67,
        name: "Other",
      },
    ],
  },
  {
    id: 68,
    name: "Programmers",
    children: [
      {
        id: 69,
        name: "CNC",
      },
      {
        id: 70,
        name: "VMC",
        children: [
          {
            id: 71,
            name: "2D",
          },
          {
            id: 72,
            name: "3D",
          },
        ],
      },
      {
        id: 73,
        name: "CNC Cutting",
      },
      {
        id: 74,
        name: "CNC Bending",
      },
      {
        id: 75,
        name: "Other",
      },
    ],
  },
  {
    id: 76,
    name: "Designer",
    children: [
      {
        id: 77,
        name: "AutoCad",
      },
      {
        id: 78,
        name: "Master Cam",
      },
      {
        id: 79,
        name: "Solid Edge",
      },
      {
        id: 80,
        name: "Solid Works",
      },
      {
        id: 81,
        name: "ProvE",
      },
      {
        id: 82,
        name: "Catia",
      },
      {
        id: 83,
        name: "Unigraphics",
      },
      {
        id: 84,
        name: "Delcam",
      },
      {
        id: 85,
        name: "Edgecam",
      },
      {
        id: 86,
        name: "Other",
      },
    ],
  },
  {
    id: 87,
    name: "Engineer",
    children: [
      {
        id: 88,
        name: "QC Engineer",
        children: [
          {
            id: 89,
            name: "CMM",
          },
          {
            id: 90,
            name: "Trimos",
          },
          {
            id: 91,
            name: "QC",
          },
        ],
      },
      {
        id: 92,
        name: "Sales Engineer",
        children: [
          {
            id: 93,
            name: "Cutting Tools",
          },
          {
            id: 94,
            name: "Hand Tools",
          },
          {
            id: 95,
            name: "Cutting Oil",
          },
          {
            id: 96,
            name: "Machine Tool",
          },
          {
            id: 97,
            name: "Instrument",
          },
          {
            id: 98,
            name: "Software",
          },
        ],
      },
      {
        id: 99,
        name: "Service Engineer",
        children: [
          {
            id: 100,
            name: "Machine",
          },
          {
            id: 101,
            name: "Instrument",
          },
          {
            id: 102,
            name: "Crane/hoist",
          },
        ],
      },
      {
        id: 103,
        name: "Production Engineer",
        children: [
          {
            id: 104,
            name: "Machine Shop",
          },
          {
            id: 105,
            name: "Fabrication Shop",
          },
        ],
      },
      {
        id: 106,
        name: "Purchase Engineer",
      },
      {
        id: 107,
        name: "Other",
      },
    ],
  },
  {
    id: 108,
    name: "Accountant",
  },
  {
    id: 109,
    name: "Manager",
  },
  {
    id: 110,
    name: "Supervisor",
  },
  {
    id: 111,
    name: "Tele caller",
  },
  {
    id: 112,
    name: "Others",
  },
];
