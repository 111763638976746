import React, { memo } from "react";
import { useTheme } from "@mui/material";
import { UserStepOneProps } from "@pages/home/userDetails/editUserModal/userStepOne/userStepOne.interface";
import userStepTwoController from "./companyStepTwo.controller";
import {
  StyledBox,
  StyledButton,
  StyledButtonContainer,
  StyledContainer,
  StyledInput,
} from "../../../userDetails/editUserModal/userStepOne/userStepOne.style";

const CompanyStepTwo: React.FC<UserStepOneProps> = ({ handleNext, userId }) => {
  const {
    legalFirmName,
    setLegalFirmName,
    ownerName,
    setOwnerName,
    companyEmail,
    setCompanyEmail,
    mobileNo,
    setMobileNo,
    address,
    setAddress,
    validation,
    error,
  } = userStepTwoController({ handleNext, userId });
  const theme = useTheme();
  return (
    <StyledContainer>
      <StyledBox>
        <StyledInput
          variant="outlined"
          placeholder="Legal Firm Name"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={legalFirmName}
          onChange={(val) => setLegalFirmName(val?.target?.value)}
          helperText={error.legalFirmName}
        />
        <StyledInput
          variant="outlined"
          placeholder="Owner Name"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={ownerName}
          onChange={(val) => setOwnerName(val?.target?.value)}
          helperText={error.ownerName}
        />
        <StyledInput
          variant="outlined"
          placeholder="Company Email"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={companyEmail}
          onChange={(val) => setCompanyEmail(val?.target?.value)}
          helperText={error.companyEmail}
        />
        <StyledInput
          variant="outlined"
          placeholder="Mobile Number"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={mobileNo}
          onChange={(val) => setMobileNo(val.target.value.slice(0, 10))}
          helperText={error.mobileNo}
        />
        <StyledInput
          variant="outlined"
          placeholder="Address"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={address}
          onChange={(val) => setAddress(val?.target?.value)}
          helperText={error.address}
        />
      </StyledBox>
      <StyledButtonContainer>
        <StyledButton onClick={validation}>Next</StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default memo(CompanyStepTwo);
