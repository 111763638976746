export const jobVacancyCell = [
  {
    id: 1,
    title: "Sr No.",
  },
  {
    id: 2,
    title: "Title",
  },
  {
    id: 3,
    title: "Company Name",
  },
  {
    id: 4,
    title: "Experience",
  },
  {
    id: 5,
    title: "No Of Position",
  },
  {
    id: 6,
    title: "Work As",
  },
  {
    id: 4,
    title: "Action",
  },
];
