import { useState } from "react";
import {
  AddNotificationControllerProps,
  AddNotificationProps,
  ErrorProps,
} from "./addNotification.interface";
import { axiosInstance } from "@services/api/api";
import { checkString } from "@utils/validation/validation";
import { Constant } from "@services/constant";
import validationMessage from "@utils/validation/validationMessage";

const addNotificationController = ({
  onClose,
  getUpdate,
}: AddNotificationProps): AddNotificationControllerProps => {
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [error, setError] = useState<ErrorProps>({
    title: null,
    description: null,
  });

  const clearData = (): void => {
    setTitle(undefined);
    setDescription(undefined);
    setError({
      title: null,
      description: null,
    });
    setSnackbar(false);
  };

  const handleDescription = (event: any) => {
    if (event.target.value.length <= 250) {
      setDescription(event.target.value);
    }
  };
  const handleTitle = (event: any) => {
    if (event.target.value.length <= 50) {
      setTitle(event.target.value);
    }
  };

  const onHandleClose = (): void => {
    clearData();
    onClose();
  };

  const handleSnackbar = (): void => {
    setSnackbar(false);
  };

  const addMassNotification = async () => {
    /* API call for adding mass Notification */
    try {
      setLoading(true);
      const formData = {
        title: title,
        description: description,
      };
      clearData();
      const { data } = await axiosInstance.post(
        Constant.addMassNotification,
        formData
      );
      setLoading(false);
      setSnackbar(true);
      setMessage(data?.message);
      setTimeout(() => onHandleClose(), 2000);
      getUpdate();
    } catch (error: any) {
      setLoading(false);
      setSnackbar(true);
      setMessage(error?.data?.message);
    }
  };

  const validation = (): void => {
    let isValid = true;
    if (!title?.trim()) {
      isValid = false;
      error.title = validationMessage.emptyTitle;
    } else if (!checkString(title)) {
      isValid = false;
      error.title = validationMessage.invalidTitle;
    } else {
      error.title = null;
    }
    if (!description?.trim()) {
      isValid = false;
      error.description = validationMessage.emptyDescription;
    } else {
      error.description = null;
    }
    setError({ ...error });
    if (isValid) {
      addMassNotification();
    }
  };

  return {
    loading,
    title,
    description,
    error,
    validation,
    onHandleClose,
    snackbar,
    message,
    handleSnackbar,
    handleTitle,
    handleDescription,
  };
};

export default addNotificationController;
