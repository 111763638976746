import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "@services/redux/controller";
import { UserDetailsControllerProps } from "./userDetails.interface";
import { User } from "@services/redux/userList/interface";

const userDetailsController = (): UserDetailsControllerProps => {
  const [open, setOpen] = useState<boolean>(false);

  const { userId } = useParams();

  const navigation = useNavigate();
  const userList = useAppSelector((state) => state.userListReducer);
  const userDetails = userList.userList.filter((item: User) => {
    if (item._id === userId) {
      return item;
    }
  });
  const goBack = (): void => {
    navigation(-1);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const onClose = (): void => {
    setOpen(false);
  };

  return { goBack, userDetails, handleOpen, onClose, open, userId };
};

export default userDetailsController;
