import React from "react";
import { useTheme } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import EditUserModal from "./editUserModal/EditUserModal";
import userDetailsController from "./userDetails.controller";
import { UserProfileDetails } from "@component";
import {
  StyledButton,
  StyledContainer,
  StyledDetailsContainer,
  StyledText,
} from "./userDetails.style";

const UserDetails: React.FC = () => {
  const theme = useTheme();
  const { goBack, userDetails, handleOpen, onClose, open, userId } =
    userDetailsController();
  return (
    <StyledContainer>
      <StyledButton
        startIcon={<ChevronLeft color="primary" />}
        variant="text"
        onClick={goBack}
        id="back"
      >
        Back
      </StyledButton>
      <StyledText variant="h5" mt={theme.spacing(10)}>
        Profile Details
      </StyledText>
      <StyledDetailsContainer>
        <UserProfileDetails
          userDetailsData={userDetails}
          profileImage={userDetails[0]?.profileImage}
          firstName={userDetails[0]?.firstName}
          lastName={userDetails[0]?.lastName ?? ""}
          email={userDetails[0]?.email}
          isAccountVerified={userDetails[0]?.isAccountVerified}
          lastLogin={userDetails[0]?.lastLogin}
          onEdit={handleOpen}
        />
      </StyledDetailsContainer>
      <EditUserModal open={open} onClose={onClose} userId={userId} />
    </StyledContainer>
  );
};

export default UserDetails;
