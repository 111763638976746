import React, { memo } from "react";
import { Step, StepButton, Stepper, useTheme } from "@mui/material";
import editUserModalController from "./editUserModal.controller";
import { EditUserModalProps } from "./editUserModal.interface";
import { stepsData } from "@pages/home/users/addUserSlider/addUserSlider.const";
import UserStepOne from "./userStepOne/UserStepOne";
import UserStepThree from "./userStepThree/UserStepThree";
import UserStepTwo from "./userStepTwo/UserStepTwo";
import { StyledContainer, StyledModal } from "./editUserModal.style";

const EditUserModal: React.FC<EditUserModalProps> = ({
  open,
  onClose,
  userId,
}) => {
  const { activeStep, completed, onNext, onCloseModal } =
    editUserModalController({
      onClose,
      open,
      userId,
    });
  const theme = useTheme();

  const getStepContent = (step: number) => {
    switch (step) {
      case 0: {
        return (
          <UserStepOne
            handleNext={onNext}
            userId={userId}
            registerType={1}
            handleClose={onClose}
          />
        );
      }
      case 1: {
        return <UserStepTwo handleNext={onNext} userId={userId} />;
      }
      case 2: {
        return <UserStepThree handleNext={onNext} userId={userId} />;
      }
    }
  };
  return (
    <StyledModal
      open={open}
      onClose={onCloseModal}
      aria-labelledby="user-edit-modal"
      aria-describedby="user-edit-modal-description"
    >
      <StyledContainer>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ mb: theme.spacing(4) }}
        >
          {stepsData.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {getStepContent(activeStep)}
      </StyledContainer>
    </StyledModal>
  );
};

export default memo(EditUserModal);
