import React, { useState } from "react";
import { Box, Theme, Toolbar, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet } from "react-router-dom";
import { Drawer as MUIDrawer, DrawerItem, Text } from "@component";
import { drawerData } from "./drawer.const";
import { HeaderMobile } from "./Drawer.style";
import { useAppSelector } from "@services/redux/controller";

const Drawer: React.FC = () => {
  const { userData } = useAppSelector((state) => state.userReducer);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [index, setIndex] = useState<any>();

  const handleSublist = (id: number) => {
    setIndex(id);
  };

  const drawerList =
    userData?.adminType === 1
      ? drawerData
      : drawerData.filter(
          (item) =>
            item.id === 2 || item.id === 3 || item.id === 9 || item.id === 7
        );

  const drawerWidth = 240;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const handleCloseMenu = () => {
    setOpenDrawer(!openDrawer);
  };
  return (
    <Box display="flex">
      {isMobile && (
        <HeaderMobile>
          <Text flex={1}>BSetuAdmin</Text>
          <MenuIcon onClick={handleCloseMenu} color={"info"} />
        </HeaderMobile>
      )}
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
      >
        <MUIDrawer
          open
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              overflow: "hidden",
            },
          }}
        >
          <DrawerItem
            data={drawerList}
            handleCollapse={(id) => {
              handleSublist(id);
            }}
            id={index}
          />
        </MUIDrawer>
        <MUIDrawer
          open={openDrawer}
          variant="temporary"
          onClose={handleCloseMenu}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              overflow: "hidden",
            },
          }}
        >
          <DrawerItem
            data={drawerList}
            isMobile
            onItemClick={handleCloseMenu}
            handleCollapse={(id) => handleSublist(id)}
            id={index}
          />
        </MUIDrawer>
      </Box>
      <Box
        flexGrow={1}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
        }}
      >
        {isMobile && <Toolbar />}
        <Box flex={1} height={"100vh"}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Drawer;
