import { Box, styled } from "@mui/material";
import { Button, Input, Selection, Text } from "@component";

export const StyledContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 8),
}));

export const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.common.black,
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(6),
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  width: "100%",
  alignSelf: "center",
  padding: theme.spacing(2, 0),
  input: {
    color: theme.palette.common.black,
    height: 10,
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));

export const StyledErrorText = styled(Text)(({ theme }) => ({
  fontSize: 13,
  alignSelf: "flex-start",
  color: "red",
  marginLeft: theme.spacing(6),
  marginTop: theme.spacing(2),
}));

export const StyledFileInput = styled("input")(({ theme }) => ({
  display: "none",
}));

export const StyledLabel = styled("label")(({ theme }) => ({
  minWidth: "25%",
  [theme.breakpoints.down("lg")]: {
    minWidth: "40%",
  },
}));

export const StyledChooseFileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(6),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

export const StyledChooseButton = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  padding: theme.spacing(4),
  borderRadius: theme.spacing(10),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.common.white,
  fontSize: 15,
  width: "100%",
  cursor: "pointer",
}));

export const StyledFileText = styled(Text)(({ theme }) => ({
  color: theme.palette.secondary.main,
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(6),
  marginLeft: theme.spacing(6),
  wordBreak: "break-word",
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(6),
    marginLeft: 0,
  },
}));

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(12),
  width: "30%",
  height: 40,
  display: "flex",
  alignSelf: "center",
  [theme.breakpoints.down("sm")]: {
    width: "45%",
  },
}));

export const StyledSelectionButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey[300],
  marginTop: theme.spacing(6),
  height: 38,
  justifyContent: "flex-start",
  padding: theme.spacing(0, 10),
  "&:hover": {
    background: theme.palette.grey[300],
  },
  marginLeft: theme.spacing(5),
}));

export const StyledChooseText = styled(Text)(({ theme }) => ({
  color: theme.palette.common.black,
  marginLeft: theme.spacing(6),
}));

export const StyledSelection = styled(Selection)(({ theme }) => ({
  borderWidth: 0,
  display: "flex",
  width: "32vw",
  ".MuiSelect-outlined": {
    color: theme.palette.grey[900],
  },
  [theme.breakpoints.down("sm")]: {
    width: "72vw",
  },
}));
