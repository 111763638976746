import React, { memo } from "react";
import { IconButton } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const BackButton: React.FC = () => {
  const navigation = useNavigate();

  const goBack = (): void => {
    navigation(-1);
  };
  return (
    <IconButton onClick={goBack}>
      <ChevronLeft color="primary" fontSize="large" />
    </IconButton>
  );
};

export default memo(BackButton);
