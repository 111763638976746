import React, { memo } from "react";
import { Box } from "@mui/material";
import { CompanyMachineProcessCardProps } from "./companyMachineProcessCard.interface";
import { Constant } from "@services/constant";
import { Delete, Edit } from "@mui/icons-material";
import { image } from "@assets";
import {
  StyledCircularProgress,
  StyledContainer,
  StyledIconButton,
  StyledIconContainer,
  StyledPlaceholder,
  StyledText,
} from "./companyMachineProcessCard.style";

const CompanyMachineProcessCard: React.FC<CompanyMachineProcessCardProps> = ({
  item,
  onEditIcon,
  onDeleteIcon,
  loading,
  id,
}) => {
  return (
    <StyledContainer>
      <StyledPlaceholder
        src={
          item.file !== "files/" && item.file !== ""
            ? `${Constant.imageUrl}files/${item.file}`
            : image.bsetuLogo
        }
      />
      <Box flex={0.9}>
        <StyledText variant="h6">{item.title}</StyledText>
        <StyledText>{item.description}</StyledText>
      </Box>
      <StyledIconContainer>
        <StyledIconButton
          onClick={() => onEditIcon(item._id)}
          id={`edit${item._id}`}
        >
          <Edit color="secondary" />
        </StyledIconButton>
        {loading && item._id === id ? (
          <StyledCircularProgress size={18} color="secondary" />
        ) : (
          <StyledIconButton
            onClick={() => onDeleteIcon(item._id)}
            id={`delete${item._id}`}
          >
            <Delete color="secondary" />
          </StyledIconButton>
        )}
      </StyledIconContainer>
    </StyledContainer>
  );
};

export default memo(CompanyMachineProcessCard);
