import React, { memo } from "react";
import {
  TableContainer,
  Table as MUITable,
  TableHead,
  TableRow,
  useTheme,
  Paper,
  TableBody,
  TablePagination,
} from "@mui/material";
import TableItemCard from "./tableItemCard/TableItemCard";
import DeleteDialog from "@component/deleteDialog/DeleteDialog";
import tableController from "./table.controller";
import { TableCellDataProps, TableProps } from "./table.interface";
import { StyledContainer, StyledTableCell, StyledText } from "./table.style";

const Table: React.FC<TableProps> = ({
  tableCellData,
  tableData,
  navigationTo,
  isButton,
  buttonText,
  isDeleteButton,
  isEditButton,
  handleEdit,
  openDialog = false,
  handleCloseDialog,
  handleOpenDialog,
  handleDeleteDialog,
  handleSwitch,
  handleDeleteDialogLoading,
  isViewButton,
  onView,
  search,
  backendPagination = false,
  backendPageNumber,
  backendRowPerPage,
  setRowsPerPage,
  setCurrentPage,
  setBackendRowsPerPage,
  setBackendCurrentPage,
  totalDataCount,
}) => {
  const theme = useTheme();
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleUserDetails,
    handleBackendChangeRowsPerPage,
    handleBackendChangePage,
  } = tableController({
    tableCellData,
    tableData,
    navigationTo,
    isButton,
    buttonText,
    isDeleteButton,
    isEditButton,
    handleEdit,
    openDialog,
    handleCloseDialog,
    handleOpenDialog,
    handleDeleteDialog,
    handleSwitch,
    handleDeleteDialogLoading,
    isViewButton,
    onView,
    search,
    backendPagination,
    backendPageNumber,
    backendRowPerPage,
    setRowsPerPage,
    setCurrentPage,
    setBackendRowsPerPage,
    setBackendCurrentPage,
    totalDataCount,
  });
  return (
    <>
      {tableData?.length !== 0 ? (
        <TableContainer component={Paper} sx={{ mt: theme.spacing(8) }}>
          <MUITable sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                {tableCellData?.map((item: TableCellDataProps) => (
                  <StyledTableCell key={item.id} align="center">
                    {item.title}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            {backendPagination ? (
              <TableBody>
                {tableData?.map((item: any, index: number) => (
                  <React.Fragment key={item?._id}>
                    <TableItemCard
                      key={item?._id}
                      item={item}
                      isButton={isButton}
                      buttonText={buttonText}
                      isDeleteButton={isDeleteButton}
                      handleDetails={() =>
                        handleUserDetails(item._id, navigationTo ?? "")
                      }
                      isEditButton={isEditButton}
                      handleDeleteButton={() =>
                        handleOpenDialog &&
                        handleOpenDialog(item?._id, item?.user_id)
                      }
                      handleEdit={() => handleEdit && handleEdit(item)}
                      onHandleSwitch={(check) => {
                        handleSwitch && handleSwitch(item?._id, check);
                      }}
                      isViewButton={isViewButton}
                      onView={() => onView && onView(item?._id)}
                      checked={item.status === 0}
                    />
                  </React.Fragment>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {tableData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((item: any, index: number) => (
                    <React.Fragment key={item?._id}>
                      <TableItemCard
                        key={item?._id}
                        item={item}
                        isButton={isButton}
                        buttonText={buttonText}
                        isDeleteButton={isDeleteButton}
                        handleDetails={() =>
                          handleUserDetails(item._id, navigationTo ?? "")
                        }
                        isEditButton={isEditButton}
                        handleDeleteButton={() =>
                          handleOpenDialog &&
                          handleOpenDialog(item?._id, item?.user_id)
                        }
                        handleEdit={() => handleEdit && handleEdit(item)}
                        onHandleSwitch={(check) => {
                          handleSwitch && handleSwitch(item?._id, check);
                        }}
                        isViewButton={isViewButton}
                        onView={() => onView && onView(item?._id)}
                        checked={item.status === 0}
                      />
                    </React.Fragment>
                  ))}
              </TableBody>
            )}
          </MUITable>
          {backendPagination ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100]}
              component="div"
              count={totalDataCount || 0}
              rowsPerPage={backendRowPerPage || 10}
              page={backendPageNumber || 0}
              onPageChange={handleBackendChangePage}
              onRowsPerPageChange={handleBackendChangeRowsPerPage}
            />
          ) : (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100]}
              component="div"
              count={tableData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      ) : (
        <StyledContainer>
          <StyledText>Nothing to show here</StyledText>
        </StyledContainer>
      )}
      <DeleteDialog
        dialogTitle="Are you sure you want to delete this user?"
        open={openDialog}
        onClose={handleCloseDialog}
        handleDelete={() => handleDeleteDialog && handleDeleteDialog()}
        handleCancel={handleCloseDialog}
        loading={handleDeleteDialogLoading}
      />
    </>
  );
};

export default memo(Table);
