import React, { useState } from "react";
import {
  Box,
  Grid,
  Theme,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { appBarData } from "./appBar.const";
import { SettingsBar } from "@component";
import { StyledBox } from "./appBar.style";
import { useAppSelector } from "@services/redux/controller";

const AppBar: React.FC = () => {
  const navigation = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [select, setSelect] = useState<number>(1);

  const { userData } = useAppSelector((state) => state.userReducer);

  const data =
    userData?.adminType === 1
      ? appBarData
      : appBarData.filter((item) => item?.id === 1 || item?.id === 2);

  return (
    <StyledBox>
      <Grid container spacing={4}>
        {data.map((item, index) => (
          <Grid item xs={6} md={3} key={item.id}>
            <SettingsBar
              item={item}
              index={index}
              onClick={() => {
                navigation(item.navigation);
                setSelect(item.id);
              }}
              id={item.title}
              sx={{
                background:
                  select === item.id
                    ? theme.palette.primary.main
                    : theme.palette.primary.light,
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Box flexGrow={1}>
        {isMobile && <Toolbar />}
        <Box flex={1} height={"100vh"}>
          <Outlet />
        </Box>
      </Box>
    </StyledBox>
  );
};

export default AppBar;
