import { useEffect, useMemo, useState } from "react";
import {
  addJobWorkData,
  editJobWorkData,
} from "@services/redux/allCompanyDetails/action";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  EditMachineSliderControllerProps,
  EditMachineSliderProps,
  ErrorProps,
  JobWorkType,
} from "./editMachineSlider.interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const editMachineSliderController = ({
  userId,
  handleCloseIcon,
  machineId,
  openDrawer,
  type,
  data,
}: EditMachineSliderProps): EditMachineSliderControllerProps => {
  const { jobWork } = useAppSelector((state) => state.allCompanyDetailsReducer);
  const dispatch = useAppDispatch();

  const machineData =
    type === "master"
      ? data
      : useMemo(
          () =>
            jobWork?.filter((item: any) => {
              return item._id === machineId;
            })[0],
          [jobWork]
        );

  const [selectedMachining, setSelectedMachining] = useState<
    JobWorkType | string | any
  >("");
  const [selectMachiningMainCategory, setSelectMachiningMainCategory] =
    useState<JobWorkType | string | any>("");
  const [selectMachiningSubMainCategory, setSelectMachiningSubMainCategory] =
    useState<JobWorkType | string | any>("");
  const [description, setDescription] = useState<string>();
  // const [noOfMachine, setNoOfMachine] = useState<string>(
  //   machineData[0]?.noOfMachining
  // );
  const [selectedFile, setSelectedFile] = useState<Blob | string | any>();
  const [removeFile, setRemoveFile] = useState<string>();
  const [error, setError] = useState<ErrorProps>({
    selectMachineError: null,
    noOfMachineError: null,
  });
  const [selectedFileError, setSelectedFileError] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  useEffect(() => {
    setDescription(
      type === "master"
        ? machineData?.description
        : machineData?.machinigJobDescription
    );
    setSelectedFile(
      type === "master"
        ? machineData?.profileImage
        : machineData?.machiningFiles
    );
  }, [machineData]);

  const machineName =
    type === "master" ? machineData?.product : machineData?.machining;

  const selectedMachine: string = selectMachiningSubMainCategory
    ? `${selectMachiningMainCategory?.name}-${selectMachiningSubMainCategory?.name}-${selectedMachining?.name}`
    : selectedMachining
    ? `${
        selectMachiningMainCategory
          ? selectMachiningMainCategory.name + "-" + selectedMachining?.name
          : selectedMachining?.name
      }`
    : machineName
    ? machineName
    : "Select Machine";

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const handleSelectedFile = (e: any): void => {
    /* Functionality for uploading documents */
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 200000000) {
        setSelectedFile("");
        setSelectedFileError(
          "The uploaded file exceeds the maximum allowed size of 200MB"
        );
      } else {
        setSelectedFile(selectedFile);
        setSelectedFileError("");
      }
    }
  };

  const validation = (): void => {
    /* Validation for edit machine form */
    let isValidate = true;
    if (!machineId) {
      if (!selectedMachining) {
        isValidate = false;
        error.selectMachineError = validationMessage.selectMachineValidation;
      } else {
        error.selectMachineError = "";
      }
    }
    // if (!noOfMachine) {
    //   isValidate = false;
    //   error.noOfMachineError = validationMessage.noOfMachineValidation;
    // } else if (!checkNumeric(noOfMachine)) {
    //   isValidate = false;
    //   error.noOfMachineError = validationMessage.validNoOfMachineValidation;
    // } else {
    //   error.noOfMachineError = "";
    // }
    setError({ ...error });
    if (isValidate) {
      if (machineId) {
        editMachine();
      } else {
        addMachine();
      }
    }
  };

  const onDeleteIcon = (): void => {
    if (selectedFile) {
      setRemoveFile(
        type === "master"
          ? machineData?.profileImage
          : machineData?.machiningFiles
      );
    }
    setSelectedFile("");
  };

  const addMachine = async () => {
    /* API call for adding machine */
    const formData = new FormData();
    if (typeof userId === "string") {
      formData.append("userId", userId);
    }
    formData.append("machining", selectedMachine);
    if (description) {
      formData.append("machinigJobDescription", description);
    }
    // formData.append("noOfMachining", noOfMachine);
    formData.append("machiningFiles", selectedFile);
    if (removeFile) {
      formData.append("removeMachiningUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(Constant.addJobWork, formData);
      setLoading(false);
      setSelectMachiningMainCategory("");
      setSelectMachiningSubMainCategory("");
      setSelectedMachining("");
      setDescription("");
      // setNoOfMachine("");
      setSelectedFile("");
      dispatch(addJobWorkData(data.data));
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  let id = typeof machineId === "string" ? machineId : "";

  const editMachine = async () => {
    /* API call for editing machine */
    const formData = new FormData();
    if (typeof userId === "string") {
      formData.append("userId", userId);
    }
    formData.append("jobWorkId", id);
    if (selectedMachine) {
      formData.append("machining", selectedMachine);
    }
    if (description) {
      formData.append("machinigJobDescription", description);
    }
    // if (noOfMachine) {
    //   formData.append("noOfMachining", noOfMachine);
    // }
    if (selectedFile) {
      formData.append("machiningFiles", selectedFile);
    }
    if (removeFile) {
      formData.append("removeMachiningUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.editJobWorkAdmin,
        formData
      );
      setLoading(false);
      dispatch(editJobWorkData(data.data));
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  return {
    description,
    error,
    handleSelectedFile,
    selectedFile,
    selectedFileError,
    setDescription,
    validation,
    handleCloseSnackBar,
    loading,
    openSnackBar,
    snackBarMessage,
    onDeleteIcon,
    selectedMachining,
    selectMachiningMainCategory,
    selectMachiningSubMainCategory,
    setSelectedMachining,
    setSelectMachiningMainCategory,
    setSelectMachiningSubMainCategory,
    machineData,
    selectedMachine,
  };
};

export default editMachineSliderController;
