import { Box, List, styled } from "@mui/material";
import { Button, Text } from "@component";

export const StyledContainer = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
}));

export const StyledErrorText = styled(Text)(({ theme }) => ({
  fontSize: 13,
  alignSelf: "flex-start",
  color: "red",
  marginLeft: theme.spacing(6),
  marginTop: theme.spacing(2),
}));

export const StyledTitleText = styled(Text)(({ theme }) => ({
  color: theme.palette.common.black,
  marginTop: theme.spacing(6),
}));

export const StyledSelectionButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey[300],
  marginTop: theme.spacing(6),
  width: "100%",
  height: 38,
  justifyContent: "flex-start",
  paddingLeft: theme.spacing(14),
  "&:hover": {
    background: theme.palette.grey[300],
  },
}));

export const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.common.black,
  marginLeft: theme.spacing(6),
}));

export const StyledList = styled(List)(({ theme }) => ({
  height: "70%",
  overflowY: "auto",
}));

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "31vw",
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
    alignItems: "center",
    justifyContent: "center",
  },
  justifyContent: "flex-end",
  marginTop: theme.spacing(5),
}));
