import { useEffect, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  JobworkControllerProps,
  MachineDetailsProps,
  MachineListProps,
  ProcessListProps,
} from "./jobwork.interface";

const jobworkController = (): JobworkControllerProps => {
  const [jobwork, setJobwork] = useState<number>(0);
  const [productDetails, setProductDetails] = useState<MachineDetailsProps>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteSpinner, setDeleteSpinner] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [machineList, setMachineList] = useState<MachineListProps[]>([]);
  const [searchList, setSearchList] = useState<
    MachineListProps[] | ProcessListProps[] | null
  >(null);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [editProcessModal, setEditProcessModal] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>();
  const [productId, setProductId] = useState<string>();

  useEffect(() => {
    getMachine();
  }, [search, currentPage, rowsPerPage]);

  const handleOpenDelete = (id: string, userId?: string): void => {
    setDeleteDialog(true);
    setUserId(userId);
    setProductId(id);
  };

  const handleCloseDelete = (): void => {
    setDeleteDialog(false);
  };

  const handleCloseEdit = (): void => {
    setEditModal(false);
  };

  const handleCloseProcessEdit = (): void => {
    setEditProcessModal(false);
  };

  const handleEdit = (item: any): void => {
    if (item?.type === "Machine") {
      setEditModal(true);
    } else {
      setEditProcessModal(true);
    }
    setProductDetails(item);
  };

  const handleSearch = (value: string): void => {
    /* Jobwork search functionality */
    setSearch(value);
    // let filterData = machineList.filter((item: MachineListProps) => {
    //   let search = [item?.product];
    //   let regex = search.toString().toLowerCase();
    //   return regex?.includes(value?.toLowerCase());
    // });
    // setSearchList(filterData);
  };

  const getMachine = async () => {
    /* API call for getting machine list */
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(Constant.getPortoflioJobwork, {
        params: {
          page: currentPage + 1,
          search,
          perPage: rowsPerPage,
        },
      });

      const serialNumber = (data?.data?.currentPage - 1) * rowsPerPage + 1;
      let temp: MachineListProps[] = data?.data?.Products?.map(
        (item: MachineListProps, index: number) => {
          return {
            id: serialNumber + index,
            _id: item?._id,
            user_id: item?.createrId?._id,
            profileImage: item?.processesFiles?.length
              ? item?.processesFiles
              : item?.machiningFiles,
            product: item?.processes?.length
              ? item?.processes
              : item?.machining,
            company: item?.createrId?.legalFirmName,
            type: item?.processes?.length ? "Process" : "Machine",
            description: item?.machinigJobDescription?.length
              ? item?.machinigJobDescription
              : item?.processesJobDescription,
            noOfMachine: item?.noOfMachining,
          };
        }
      );
      setMachineList(temp);
      setTotalDataCount(data?.data?.totalCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteProduct = async () => {
    /* API call for deleting machine */
    try {
      setDeleteSpinner(true);
      const formData = new URLSearchParams();
      if (userId) {
        formData.append("userId", userId);
      }
      formData.append("type", "1");
      if (productId) {
        formData.append("jobWorkId", productId);
      }
      const { data } = await axiosInstance.post(
        Constant.removePortfolioProductJobWork,
        formData.toString()
      );
      setDeleteSpinner(false);
      handleCloseDelete();
    } catch (error) {
      setDeleteSpinner(false);
    }
  };

  return {
    handleSearch,
    loading,
    machineList,
    search,
    searchList,
    deleteSpinner,
    handleCloseDelete,
    handleOpenDelete,
    deleteDialog,
    handleEdit,
    editModal,
    handleCloseEdit,
    productDetails,
    jobwork,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    totalDataCount,
    editProcessModal,
    handleCloseProcessEdit,
    deleteProduct,
  };
};

export default jobworkController;
