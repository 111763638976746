import React, { memo, useState } from "react";
import { DeleteOutline, Edit, Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Constant } from "@services/constant";
import { image } from "@assets";
import Switch from "@component/switch/Switch";
import { TableItemCardProps } from "./tableItemCard.interface";
import {
  StyledButton,
  StyledTableCell,
  StyledTableRow,
  StyledUserProfile,
} from "./tableItemCard.style";

const TableItemCard: React.FC<TableItemCardProps> = ({
  item,
  handleDetails,
  isButton,
  buttonText,
  isDeleteButton,
  handleDeleteButton,
  isEditButton,
  handleEdit,
  onHandleSwitch,
  checked: isChecked,
  isViewButton,
  onView,
}) => {
  const [checked, setChecked] = useState(!isChecked);
  return (
    <StyledTableRow key={item._id}>
      {Object.keys(item)
        .filter(
          (k) =>
            k !== "_id" &&
            k !== "children" &&
            k !== "user_id" &&
            k !== "noOfMachine" &&
            k !== "description"
        )
        .map((x) => (
          <React.Fragment key={`${item._id}${x}`}>
            {x === "profileImage" ? (
              <StyledTableCell component="th" scope="item" align="center">
                <StyledUserProfile
                  src={
                    item[x]?.endsWith("jpg") ||
                    item[x]?.endsWith("jpeg") ||
                    item[x]?.endsWith("png")
                      ? `${Constant.imageUrl}files/${
                          item[x]?.startsWith("files/") ||
                          item[x]?.startsWith("profile/")
                            ? item[x]?.split("/")[1]
                            : item[x]
                        }`
                      : image.userProfile
                  }
                />
              </StyledTableCell>
            ) : (
              <>
                {x !== "status" ? (
                  <StyledTableCell
                    align="center"
                    sx={{
                      width:
                        x === ("description" || "title")
                          ? "30%"
                          : x === "title"
                          ? "28%"
                          : "auto",
                    }}
                  >
                    {item[x] ? item[x] : "-"}
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="center">
                    <Switch
                      checked={item[x] === 1 ? true : false}
                      id={`switch${item._id}`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        {
                          setChecked(!checked);
                          onHandleSwitch && onHandleSwitch(!checked);
                        }
                      }}
                    />
                  </StyledTableCell>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      {isButton && (
        <StyledTableCell align="center">
          <StyledButton onClick={handleDetails}>{buttonText}</StyledButton>
        </StyledTableCell>
      )}
      {(isEditButton || isViewButton || isDeleteButton) && (
        <StyledTableCell align="center">
          {isEditButton && (
            <IconButton onClick={handleEdit} id={`edit${item._id}`}>
              <Edit color="secondary" />
            </IconButton>
          )}
          {isViewButton && (
            <IconButton onClick={onView}>
              <Visibility fontSize="medium" color="secondary" />
            </IconButton>
          )}
          {isDeleteButton && (
            <IconButton onClick={handleDeleteButton} id={`delete${item._id}`}>
              <DeleteOutline sx={{ color: "#EE4B2B" }} />
            </IconButton>
          )}
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
};

export default memo(TableItemCard);
