import { Box, Select, Typography, styled } from "@mui/material";

export const StyledSelection = styled(Select)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  ".MuiSelect-outlined": {
    color: theme.palette.grey[900],
  },
  width: "30vw",
  [theme.breakpoints.down("sm")]: {
    width: "45vw",
  },
}));

export const StyledProgressContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "80vh",
}));

export const StyledSelectionContainer = styled(Box)(({ theme }) => ({
  marginLeft: 10,
  marginRight: 10,
}));

export const TotalText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  marginTop: 10,
  marginRight: 10,
  color: theme.palette.grey[900],
  fontWeight: "bold",
  textAlign: "right",
}));

export const StyledLable = styled(Typography)(({ theme }) => ({
  marginLeft: 5,
  marginBottom: 5,
  fontSize: 14,
  color: theme.palette.common.black,
}));
