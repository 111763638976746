import React from "react";
import salesController from "./sales.controller";
import { CircularProgress, SnackBar, Table } from "@component";
import { Add } from "@mui/icons-material";
import AddSalesUser from "./addSalesUser/AddSalesUser";
import { tableCellData } from "./sales.const";
import {
  StyledBox,
  StyledButton,
  StyledContainer,
  StyledProgressContainer,
  StyledSearchInput,
  StyledText,
} from "./sales.style";

const Sales: React.FC = () => {
  const {
    loading,
    search,
    open,
    handleOpen,
    handleClose,
    salesUsers,
    closeSnackbar,
    snackbar,
    message,
    deleteSpinner,
    filter,
    index,
    handleCloseDelete,
    removeUser,
    handleDialogOpen,
    deleteSalesUser,
    onAddEdit,
    filterData,
  } = salesController();
  return (
    <StyledContainer>
      <StyledBox>
        <StyledText variant="h4">Sales Users</StyledText>
        <StyledSearchInput
          placeholder="Type something..."
          value={search}
          onChange={(val) => filter(val?.target.value)}
        />
        <StyledButton
          onClick={() => handleOpen()}
          startIcon={<Add fontSize="large" />}
          id="addSalesPerson"
        >
          Add Sales User
        </StyledButton>
        <AddSalesUser
          openDrawer={open}
          handleCloseIcon={handleClose}
          salesUserId={index}
          handleCloseSlider={onAddEdit}
        />
      </StyledBox>
      {loading ? (
        <StyledProgressContainer>
          <CircularProgress size={40} color={"primary"} />
        </StyledProgressContainer>
      ) : (
        <Table
          tableData={filterData === null ? salesUsers : filterData}
          tableCellData={tableCellData}
          isEditButton
          handleEdit={(item) => handleOpen(item?._id)}
          isDeleteButton
          handleOpenDialog={(id) => {
            handleDialogOpen(id);
          }}
          openDialog={removeUser}
          handleCloseDialog={handleCloseDelete}
          handleDeleteDialog={deleteSalesUser}
          handleDeleteDialogLoading={deleteSpinner}
          search={search}
        />
      )}
      <SnackBar
        open={snackbar}
        onClose={closeSnackbar}
        onCloseClick={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message={message}
      />
    </StyledContainer>
  );
};

export default Sales;
