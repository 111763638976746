import React from "react";
import { useTheme } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import AllCompanyDetails from "./allCompanyDetails/AllCompanyDetails";
import companyDetailsController from "./companyDetails.controller";
import CompanyPortfolio from "./companyPortfolio/CompanyPortfolio";
import { CompanyProfileDetails } from "@component";
import EditCompanyModal from "./editCompanyModal/EditCompanyModal";
import {
  StyledBox,
  StyledButton,
  StyledContainer,
  StyledDetailsContainer,
  StyledText,
} from "./companyDetails.style";

const CompanyDetails: React.FC = () => {
  const theme = useTheme();
  const {
    goBack,
    companyDetails,
    loading,
    companyId,
    portfolioLoading,
    handleClose,
    handleOpen,
    open,
  } = companyDetailsController();
  return (
    <StyledContainer>
      <StyledButton
        startIcon={<ChevronLeft color="primary" />}
        variant="text"
        onClick={goBack}
        id="companyDetailsBack"
      >
        Back
      </StyledButton>
      <StyledDetailsContainer>
        <StyledBox
          sx={{
            [theme.breakpoints.up("md")]: {
              marginRight: 10,
            },
          }}
        >
          <StyledText variant="h5" mt={theme.spacing(10)}>
            Company Profile Details
          </StyledText>
          <CompanyProfileDetails
            userDetailsData={companyDetails}
            profileImage={companyDetails[0]?.profileImage}
            firstName={companyDetails[0]?.firstName}
            lastName={companyDetails[0]?.lastName}
            email={companyDetails[0]?.email}
            isAccountVerified={companyDetails[0]?.isAccountVerified}
            lastLogin={companyDetails[0]?.lastLogin}
            onEdit={handleOpen}
          />
        </StyledBox>
        <StyledBox>
          <StyledText variant="h5" mt={theme.spacing(10)}>
            Post Requirements
          </StyledText>
          <AllCompanyDetails loading={loading} userId={companyId} />
        </StyledBox>
      </StyledDetailsContainer>
      <CompanyPortfolio loading={portfolioLoading} userId={companyId} />
      <EditCompanyModal
        open={open}
        onClose={handleClose}
        companyId={companyId}
      />
    </StyledContainer>
  );
};

export default CompanyDetails;
