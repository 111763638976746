import React, { memo } from "react";
import { Add } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { CompanyMachineProcessCard } from "@component";
import EditProcessSlider from "./editProcessSlider/EditProcessSlider";
import processController from "./process.controller";
import { ProcessProps } from "./process.interface";
import { StyledContainer, StyledText } from "./process.style";
import {
  StyledList,
  StyledListContainer,
} from "@component/usersFromCountries/usersFromCountries.style";
import {
  StyledFabButton,
  StyledFabButtonContainer,
} from "../products/products.style";

const Process: React.FC<ProcessProps> = ({ userId }) => {
  const {
    processData,
    handleCloseDrawer,
    handleOpenDrawer,
    index,
    openDrawer,
    removeProcess,
    loading,
    processId,
  } = processController({ userId });
  const theme = useTheme();
  return (
    <>
      {processData?.length !== 0 ? (
        <StyledListContainer>
          <StyledList>
            {processData?.map((item) => (
              <CompanyMachineProcessCard
                item={item}
                key={item._id}
                onEditIcon={(id) => handleOpenDrawer(id)}
                onDeleteIcon={(id) => removeProcess(id)}
                loading={loading}
                id={processId}
              />
            ))}
          </StyledList>
        </StyledListContainer>
      ) : (
        <StyledContainer>
          <StyledText>Nothing to show here</StyledText>
        </StyledContainer>
      )}
      {openDrawer && (
        <EditProcessSlider
          openDrawer={openDrawer}
          handleCloseIcon={handleCloseDrawer}
          processId={index}
          userId={userId}
        />
      )}
      <StyledFabButtonContainer>
        <StyledFabButton
          sx={{ top: "-19%", [theme.breakpoints.down("sm")]: { top: "-23%" } }}
          color="primary"
          onClick={() => handleOpenDrawer()}
          id="addPostRequirementProcess"
        >
          <Add />
        </StyledFabButton>
      </StyledFabButtonContainer>
    </>
  );
};

export default memo(Process);
