import React, { memo } from "react";
import { useTheme } from "@mui/material";
import { CircularProgress } from "@component";
import portfolioJobWorkController from "./portfolioJobWork.controller";
import { PortfolioJobWorkProps } from "./portfolioJobWork.interface";
import PortfolioMachine from "../portfolioMachine/PortfolioMachine";
import PortfolioProcess from "../portfolioProcess/PortfolioProcess";
import { tabData } from "./portfolioJobWork.const";
import {
  StyledBox,
  StyledButton,
  StyledContainer,
} from "./portfolioJobWork.style";

const PortfolioJobWork: React.FC<PortfolioJobWorkProps> = ({
  userId,
  loading,
}) => {
  const { select, setSelect } = portfolioJobWorkController();
  const theme = useTheme();

  const getPostData = (select: number) => {
    switch (select) {
      case 1: {
        return <PortfolioMachine userId={userId} />;
      }
      case 2: {
        return <PortfolioProcess userId={userId} />;
      }
    }
  };
  return (
    <>
      <StyledContainer>
        {tabData.map((item, index) => (
          <StyledButton
            variant="text"
            key={index}
            onClick={() => setSelect(item.id)}
            id={`portfolio${item.title}`}
            sx={{
              borderColor:
                select !== item.id ? theme.palette.primary.dark : undefined,
              borderTopRightRadius: item.title === "Process" ? 10 : 0,
              borderTopLeftRadius: item.title === "Machine" ? 10 : 0,
              borderBottomLeftRadius: item.title === "Machine" ? 10 : 0,
              borderBottomRightRadius: item.title === "Process" ? 10 : 0,
              color:
                select === item.id
                  ? theme.palette.common.white
                  : theme.palette.primary.dark,
              background:
                select === item.id
                  ? theme.palette.primary.dark
                  : theme.palette.common.white,
            }}
          >
            {item.title}
          </StyledButton>
        ))}
      </StyledContainer>
      {loading ? (
        <StyledBox>
          <CircularProgress size={30} />
        </StyledBox>
      ) : (
        getPostData(select)
      )}
    </>
  );
};

export default memo(PortfolioJobWork);
