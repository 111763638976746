import { Box, List, styled } from "@mui/material";
import Text from "@component/text/Text";

export const StyledContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  padding: theme.spacing(0, 10),
  width: "100%",
  wordBreak: "break-word",
  marginTop: theme.spacing(10),
  marginRight: theme.spacing(14),
  height: "100vh",
  paddingTop: 20,
  paddingBottom: 20,
}));

export const StyledUserProfile = styled("img")(({ theme }) => ({
  height: 120,
  width: 120,
  borderRadius: theme.spacing(4),
  marginRight: theme.spacing(12),
}));

export const StyledProfileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(8),
}));

export const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const StyledUserNameText = styled(StyledText)(({ theme }) => ({
  fontSize: 18,
  fontWeight: "bold",
}));

export const StyledTextWithBackground = styled(Text)(({ theme }) => ({
  color: theme.palette.common.white,
  background: theme.palette.secondary.main,
  textAlign: "center",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(10),
  marginTop: theme.spacing(4),
}));

export const StyledListContainer = styled(Box)(({ theme }) => ({
  height: "90%",
  overflow: "hidden",
  position: "relative",
}));

export const StyledList = styled(List)(({ theme }) => ({
  overflow: "auto",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: "-15px",
  overflowY: "scroll",
  marginBottom: theme.spacing(18),
}));
