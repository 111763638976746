import React from "react";
import { AddIcon, MasterModal, Spinner, Table, SnackBar } from "@component";
import expertiseController from "./expertise.controller";
import { tableCell } from "./expertise.const";
import {
  StyledBox,
  StyledContainer,
  StyledSearchInput,
  StyledText,
} from "./expertise.style";

const Expertise: React.FC = () => {
  const {
    search,
    loading,
    onView,
    handleClose,
    handleOpen,
    open,
    index,
    setExpertise,
    expertise,
    expertiseData,
    searchList,
    filterData,
    addSpinner,
    error,
    validation,
    updateStatus,
    setOpenSnackbar,
    openSnackbar,
    snackbarMessage,
  } = expertiseController();
  return (
    <StyledContainer>
      <StyledBox>
        <StyledText variant="h4">Expertise</StyledText>
        <StyledSearchInput
          placeholder="Type something..."
          value={search}
          onChange={(val) => {
            searchList(val?.target?.value);
          }}
        />
        <AddIcon onAdd={() => handleOpen()} />
      </StyledBox>
      {loading ? (
        <Spinner />
      ) : (
        <Table
          tableData={
            expertiseData
              ? filterData !== null
                ? filterData
                : expertiseData
              : []
          }
          tableCellData={tableCell}
          isViewButton
          isEditButton
          onView={onView}
          handleEdit={(item) => handleOpen(item)}
          handleSwitch={(id, check) => {
            updateStatus(id, check);
          }}
          search={search}
        />
      )}
      <MasterModal
        open={open}
        handleClose={handleClose}
        title={index ? "Update Expertise" : "Add Expertise"}
        buttonTitle={index ? "Update" : "Add"}
        placeholder="Expertise"
        value={expertise}
        setValue={(val) => {
          setExpertise && setExpertise(val?.target?.value);
        }}
        loading={addSpinner}
        handleButton={validation}
        error={error}
      />
      <SnackBar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        onCloseClick={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message={snackbarMessage}
        autoHideDuration={5000}
      />
    </StyledContainer>
  );
};

export default Expertise;
