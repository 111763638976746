import { Box, Modal, styled } from "@mui/material";
import Button from "@component/button/Button";
import Input from "@component/input/Input";
import Text from "@component/text/Text";

export const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 50,
}));

export const StyledModalContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(10),
  outline: "none",
  width: 400,
  borderRadius: 15,
}));

export const StyledText = styled(Text)(({ theme }) => ({
  fontSize: 18,
  textAlign: "center",
  color: theme.palette.common.black,
  fontWeight: "bold",
  marginBottom: 10,
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  width: "100%",
  input: {
    color: theme.palette.common.black,
    height: 10,
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(10),
  alignSelf: "center",
  justifyContent: "center",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: 100,
}));
