import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { axiosInstance } from "@services/api/api";
import {
  CompanyControllerProps,
  CompanyDetailsProps,
} from "./company.interface";
import { Company } from "@services/redux/userList/interface";
import { Constant } from "@services/constant";
import {
  getCompanyUserList,
  removeCompanyUser,
} from "@services/redux/userList/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import usersController from "../users/users.controller";

const companyController = (): CompanyControllerProps => {
  const { saveDataAsXL } = usersController();
  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.userListReducer);
  const [searchValue, setSearchValue] = useState<string>("");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [index, setIndex] = useState<string | undefined>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(
    users?.companyUserList?.length > 0 ? false : true
  );
  const [filterData, setFilterData] = useState<CompanyDetailsProps[] | null>(
    null
  );

  const companyDetailsData = useMemo(() => {
    return users?.companyUserList.map((item: Company) => ({
      _id: item._id,
      profileImage: item.profileImage,
      name: `${item?.firstName} ${item?.lastName ?? ""}`,
      email: item.email,
      mobileNumber: item.mobileNumber,
      companyName: item.legalFirmName,
      createdAt: moment(item.createdAt).format("DD/MM/YYYY"),
      lastLogin: item?.lastLogin ? moment(item?.lastLogin).format("LLL") : "-",
    }));
  }, [users?.companyUserList]);

  const data = useMemo(() => {
    return users?.companyUserList.map((item: Company) => ({
      Name: `${item?.firstName} ${item?.lastName ?? ""}`,
      Email: item?.email ?? "-",
      "Mobile No.": item?.mobileNumber,
      "Company Name": item?.legalFirmName ?? "-",
      "Company Email": item?.companyEmail ?? "-",
      "Company Mobile No.": item?.companyMobileNumber ?? "-",
      "Company Type": item?.companyType ?? "-",
      "Owner Name": item?.ownerName ?? "-",
      Address: item?.address ?? "-",
      "GST Number": item?.gstNumber ?? "-",
      "No. of Employees": item?.noOfEmpolyees ?? "-",
      "Created On": moment(item.createdAt).format("DD/MM/YYYY"),
      "Last Login": item?.lastLogin
        ? moment(item?.lastLogin).format("LLL")
        : "-",
    }));
  }, [users?.companyUserList]);

  const onExport = (): void => {
    saveDataAsXL && saveDataAsXL(data, `${Date.now()}_CompanyUserList`);
  };

  const handleOpen = (id?: string): void => {
    /* Open add/edit company user slider */
    setOpenDrawer(true);
    setIndex(id);
  };

  const handleClose = (): void => {
    /* Close add/edit company user slider */
    setOpenDrawer(false);
  };

  const handleDialogOpen = (id: string): void => {
    /* Open delete user dialog */
    setOpenDialog(true);
    setIndex(id);
  };

  const handleDialogClose = (): void => {
    /* Close delete user dialog */
    setOpenDialog(false);
  };

  useEffect(() => {
    onGetCompanyUsers();
  }, []);

  const onGetCompanyUsers = async () => {
    /* API call for getting all company users */
    try {
      const { data } = await axiosInstance.post(Constant.allUsers, {
        registerType: 0,
      });
      dispatch(getCompanyUserList(data.data));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const filter = (value: string): void => {
    /* Functionality to search users */
    let filterData = companyDetailsData.filter((item: CompanyDetailsProps) => {
      let search = [
        item?.name,
        item?.email,
        item?.mobileNumber,
        item?.lastLogin,
      ];
      let regex = search.toString().toLowerCase();
      return regex.includes(value.toLowerCase());
    });
    setSearchValue(value);
    setFilterData(filterData);
  };

  const removeUser = async () => {
    /* API call for removing company user */
    try {
      setDeleteLoading(true);
      const { data } = await axiosInstance.post(Constant.removeUser, {
        id: index,
      });
      setDeleteLoading(false);
      handleDialogClose();
      dispatch(removeCompanyUser(index));
    } catch (error) {
      setDeleteLoading(false);
    }
  };

  return {
    loading,
    searchValue,
    filterData,
    filter,
    openDrawer,
    handleOpen,
    handleClose,
    companyDetailsData,
    handleDialogClose,
    handleDialogOpen,
    openDialog,
    index,
    removeUser,
    deleteLoading,
    onExport,
  };
};

export default companyController;
