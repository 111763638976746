export const tableCell = [
  {
    id: 1,
    title: "Sr No.",
  },
  {
    id: 2,
    title: "Machine/Process Image",
  },
  {
    id: 3,
    title: "Machine/Process Name",
  },
  {
    id: 4,
    title: "Company Name",
  },
  {
    id: 5,
    title: "Type",
  },
  {
    id: 6,
    title: "Action",
  },
];
