import React, { memo } from "react";
import { useTheme } from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { SnackBar } from "@component";
import { UserStepOneProps } from "../userStepOne/userStepOne.interface";
import userStepThreeControllerProps from "./userStepThree.controller";
import {
  StyledBox,
  StyledContainer,
  StyledInput,
  StyledButton,
} from "../userStepOne/userStepOne.style";
import {
  StyledChooseButton,
  StyledChooseFileContainer,
  StyledErrorText,
  StyledFileInput,
  StyledFileText,
  StyledLabel,
  StyledPdfIcon,
  StyledSalaryText,
  StyledSelectionButton,
  StyledText,
  StyledTitleText,
  StyledList,
} from "./userStepThree.style";
import { StyledButtonContainer } from "@pages/home/companyDetails/editCompanyModal/companyStepThree/companyStepThree.style";

const UserStepThree: React.FC<UserStepOneProps> = ({ handleNext, userId }) => {
  const theme = useTheme();
  const {
    salary,
    setSalary,
    select,
    setSelect,
    validation,
    error,
    loading,
    resumeError,
    handleResumeChange,
    openSnackBar,
    snackBarMessage,
    handleCloseSnackBar,
    resume,
  } = userStepThreeControllerProps({ handleNext, userId });
  return (
    <>
      <StyledList>
        <StyledContainer>
          <StyledBox>
            <StyledSalaryText>Expected Salary</StyledSalaryText>
            <StyledInput
              variant="outlined"
              placeholder="Expected Salary(Per Month)"
              InputLabelProps={{ style: { color: theme.palette.common.black } }}
              value={salary}
              onChange={(val) => setSalary(val?.target?.value)}
              helperText={error.salary}
            />
            <StyledTitleText variant="h6">Work as</StyledTitleText>
            <StyledSelectionButton
              onClick={() => setSelect("1")}
              startIcon={
                <CheckBox color={select === "1" ? "primary" : "disabled"} />
              }
            >
              <StyledText>Full Time</StyledText>
            </StyledSelectionButton>
            <StyledSelectionButton
              onClick={() => setSelect("0")}
              startIcon={
                <CheckBox color={select === "0" ? "primary" : "disabled"} />
              }
            >
              <StyledText>Part Time</StyledText>
            </StyledSelectionButton>
            <StyledErrorText>{error.select}</StyledErrorText>
            <StyledTitleText variant="h6" sx={{ mt: theme.spacing(12) }}>
              Upload Resume (Optional)
            </StyledTitleText>
            <StyledFileInput
              id="selectResume"
              type="file"
              onChange={handleResumeChange}
            />
            <StyledChooseFileContainer>
              <StyledLabel htmlFor="selectResume">
                <StyledChooseButton>
                  {resume ? "Selected file" : "Choose file"}
                </StyledChooseButton>
              </StyledLabel>
              {resume && (
                <StyledFileText>
                  <StyledPdfIcon />
                  {resume.name ?? resume}
                </StyledFileText>
              )}
            </StyledChooseFileContainer>
            <StyledErrorText>{resumeError}</StyledErrorText>
            <SnackBar
              open={openSnackBar}
              onClose={handleCloseSnackBar}
              onCloseClick={handleCloseSnackBar}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              message={snackBarMessage}
            />
          </StyledBox>
        </StyledContainer>
      </StyledList>
      <StyledButtonContainer>
        <StyledButton onClick={validation} loading={loading}>
          Finish
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
};

export default memo(UserStepThree);
