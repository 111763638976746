import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import type from "./type";

const getZones = (payload: any) => ({
  type: type.getZones,
  payload,
});

const updateZones = (payload: any) => ({
  type: type.updateZones,
  payload,
});

const addZones = (payload: any) => ({
  type: type.addZones,
  payload,
});

const removeZones = (payload: any) => ({
  type: type.removeZones,
  payload,
});

const getZonesData = () => {
  /* API call for getting zone data */
  return async (dispatch: any) => {
    try {
      const { data } = await axiosInstance.get(Constant.getZones);
      dispatch(getZones(data.data.reverse()));
    } catch (error) {}
  };
};

export { getZones, removeZones, getZonesData, updateZones, addZones };
