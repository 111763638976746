import { Box, styled } from "@mui/material";
import Button from "@component/button/Button";
import Input from "@component/input/Input";
import Selection from "@component/selection/Selection";
import { Text } from "@component";

export const StyledContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
  width: "80%",
  alignSelf: "center",
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(18),
}));

export const StyledSelection = styled(Selection)(({ theme }) => ({
  borderWidth: 0,
  marginBottom: theme.spacing(4),
  ".MuiSelect-outlined": {
    color: theme.palette.grey[900],
  },
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  input: {
    color: theme.palette.common.black,
    height: 10,
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));

export const StyledExperienceInput = styled(Input)(({ theme }) => ({
  padding: 0,
  marginLeft: theme.spacing(8),
  width: "9vw",
  input: {
    color: theme.palette.common.black,
    height: 8,
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
  [theme.breakpoints.down("sm")]: {
    width: "25vw",
  },
}));

export const StyledInputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(5),
  alignItems: "flex-start",
}));

export const StyledAddButton = styled(Button)(({ theme }) => ({
  width: "30%",
  height: 40,
  margin: theme.spacing(6, 0),
  alignSelf: "center",
  [theme.breakpoints.down("md")]: {
    width: "40%",
  },
}));

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "fixed",
  bottom: 0,
  width: "31vw",
  alignSelf: "center",
  background: theme.palette.common.white,
  padding: theme.spacing(6, 0),
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "27%",
  height: 45,
  color: theme.palette.secondary.main,
  border: "2px solid",
  margin: theme.spacing(4),
  background: theme.palette.common.white,
  "&:hover": {
    background: theme.palette.common.white,
  },
  [theme.breakpoints.down("lg")]: {
    width: "30%",
  },
}));

export const StyledErrorText = styled(Text)(({ theme }) => ({
  fontSize: 13,
  alignSelf: "flex-start",
  color: "red",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "17vw",
  [theme.breakpoints.down("sm")]: {
    width: "35vw",
  },
}));

export const StyledErrorTextTwo = styled(Text)(({ theme }) => ({
  fontSize: 13,
  alignSelf: "flex-start",
  color: "red",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "10vw",
  [theme.breakpoints.down("sm")]: {
    width: "25vw",
  },
}));
