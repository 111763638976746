import { Box, Modal, styled } from "@mui/material";
import { Button, Input, Text } from "@component";

export const StyledModal = styled(Modal)(({ theme }) => ({
  background: "rgba(0,0,0,0.2)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const StyledModalContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(0, 13),
  paddingTop: theme.spacing(13),
  borderRadius: 15,
  display: "flex",
  flexDirection: "column",
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "70%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

export const StyledTitle = styled(Text)(({ theme }) => ({
  marginBottom: theme.spacing(13),
  fontSize: 22,
  fontWeight: "bold",
  color: theme.palette.common.black,
  textAlign: "center",
}));

export const InputTitle = styled(Text)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.common.black,
  marginLeft: theme.spacing(2),
}));

export const StyledInputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: theme.spacing(13),
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  width: "100%",
  alignSelf: "center",
  padding: theme.spacing(3, 0),
  marginBottom: theme.spacing(5),
  input: {
    color: theme.palette.common.black,
    height: 10,
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(13),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "30%",
  height: 40,
  [theme.breakpoints.down("sm")]: {
    width: "40%",
  },
}));
