import React, { memo } from "react";
import { Box, useTheme } from "@mui/material";
import { CircularProgress } from "@component";
import companyPortfolioController from "./companyPortfolio.controller";
import { CompanyPortfolioProps } from "./companyPortfolio.interface";
import PortfolioJobWork from "./portfolioJobWork/PortfolioJobWork";
import PortfolioProduct from "./portfolioProduct/PortfolioProduct";
import { tabData } from "./CompanyPortfolio.const";
import {
  StyledBox,
  StyledContainer,
  StyledSettingsBar,
  StyledText,
} from "./companyPortfolio.style";

const CompanyPortfolio: React.FC<CompanyPortfolioProps> = ({
  loading,
  userId,
}) => {
  const { select, setSelect } = companyPortfolioController();
  const theme = useTheme();

  const getPostData = (select: number) => {
    switch (select) {
      case 1: {
        return <PortfolioProduct userId={userId} />;
      }
      case 2: {
        return <PortfolioJobWork userId={userId} loading={loading} />;
      }
    }
  };
  return (
    <StyledContainer boxShadow={3}>
      <StyledText variant="h5">Company Portfolio</StyledText>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {tabData.map((item, index) => (
          <StyledSettingsBar
            item={item}
            index={index}
            key={item.id}
            onClick={() => setSelect(item.id)}
            id={`companyPortfolio${item.title}`}
            sx={{
              background:
                select === item.id
                  ? theme.palette.primary.main
                  : theme.palette.primary.light,
            }}
          />
        ))}
      </Box>
      {loading ? (
        <StyledBox>
          <CircularProgress size={30} />
        </StyledBox>
      ) : (
        getPostData(select)
      )}
    </StyledContainer>
  );
};

export default memo(CompanyPortfolio);
