import { TableCellDataProps } from "@component/customTable/table.interface";

export const tableCellData: TableCellDataProps[] = [
  {
    id: 1,
    title: "",
  },
  {
    id: 2,
    title: "Name",
  },
  {
    id: 3,
    title: "User Type",
  },
  {
    id: 4,
    title: "Email",
  },
  {
    id: 5,
    title: "Mobile Number",
  },
  {
    id: 6,
    title: "Company Name",
  },
  {
    id: 7,
    title: "Created On",
  },
  {
    id: 8,
    title: "Last Login",
  },
];
