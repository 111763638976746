import { PortfolioJobWorkDataProps } from "./portfolioJobWork/portfolioJobWork.interface";

export const tabData: PortfolioJobWorkDataProps[] = [
  {
    id: 1,
    title: "Products",
  },
  {
    id: 2,
    title: "JobWork",
  },
];
