import { useEffect, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  JobVacancyControllerProps,
  VacancyDetailsProps,
  VacancyListProps,
} from "./jobVacancy.interface";

const jobVacancyController = (): JobVacancyControllerProps => {
  const [vacancyDetails, setVacancyDetails] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteSpinner, setDeleteSpinner] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [vacancyList, setVacancyList] = useState<VacancyListProps[]>([]);
  const [vacancy, setVacancy] = useState<VacancyListProps[]>([]);
  const [searchList, setSearchList] = useState<VacancyListProps[] | null>(null);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>();
  const [vacancyId, setVacancyId] = useState<string>();

  useEffect(() => {
    getJobVacancy();
  }, [search, currentPage, rowsPerPage]);

  const handleOpenDelete = (id: string, userId?: string): void => {
    setDeleteDialog(true);
    setUserId(userId);
    setVacancyId(id);
  };

  const handleCloseDelete = (): void => {
    setDeleteDialog(false);
  };

  const handleCloseEdit = (): void => {
    setEditModal(false);
  };

  const handleEdit = (item: any): void => {
    const list = vacancy?.filter((value) => value?._id === item?._id)[0];
    setVacancyDetails(list);
    setEditModal(true);
  };

  const handleSearch = (value: string): void => {
    /* Product search functionality */
    setSearch(value);
    // let filterData = vacancyList.filter((item: VacancyListProps) => {
    //   let search = [];
    //   let regex = search.toString().toLowerCase();
    //   return regex?.includes(value?.toLowerCase());
    // });
    // setSearchList(filterData);
  };

  const getJobVacancy = async () => {
    /* API call for getting job vacancy list */
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(Constant.getAllJobVacancie, {
        params: {
          page: currentPage + 1,
          search,
          perPage: rowsPerPage,
        },
      });

      const serialNumber = (data?.data?.currentPage - 1) * rowsPerPage + 1;
      setVacancy(data?.data?.Products);
      let temp: VacancyListProps[] = data.data.Products.map(
        (ele: VacancyListProps, index: number) => {
          return {
            id: serialNumber + index,
            title: ele?.expertise,
            companyName: ele?.createrId?.legalFirmName,
            experience: ele?.experience,
            noOfPosition: ele?.noOfPosition,
            workAs: ele?.workAs,
            _id: ele?._id,
            user_id: ele?.createrId?._id,
          };
        }
      );
      setVacancyList(temp);
      setTotalDataCount(data?.data?.totalCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteJobVacancy = async () => {
    /* API call for deleting job vacancy */
    try {
      setDeleteSpinner(true);
      const formData = new URLSearchParams();
      if (userId) {
        formData.append("userId", userId);
      }
      formData.append("type", "3");
      if (vacancyId) {
        formData.append("jobVacancyId", vacancyId);
      }
      const { data } = await axiosInstance.post(
        Constant.RemoveProductJobworkJobVacancy,
        formData.toString()
      );
      setDeleteSpinner(false);
      handleCloseDelete();
    } catch (error) {
      setDeleteSpinner(false);
    }
  };

  return {
    handleSearch,
    loading,
    vacancyList,
    search,
    searchList,
    deleteSpinner,
    handleCloseDelete,
    handleOpenDelete,
    deleteDialog,
    handleEdit,
    deleteJobVacancy,
    editModal,
    handleCloseEdit,
    vacancyDetails,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    totalDataCount,
  };
};

export default jobVacancyController;
