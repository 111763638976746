import React, { memo } from "react";
import { Box, IconButton, MenuItem, useTheme } from "@mui/material";
import { RemoveCircleOutline } from "@mui/icons-material";
import { expertiseData } from "./userProfileStepOne.const";
import { MultipleSelectionDropDown } from "@component";
import userProfileStepOneController from "./userProfileStepOne.controller";
import { UserProfileStepOneProps } from "./userProfileStepOne.interface";
import {
  StyledAddButton,
  StyledButton,
  StyledButtonContainer,
  StyledContainer,
  StyledErrorText,
  StyledErrorTextTwo,
  StyledExperienceInput,
  StyledInput,
  StyledInputContainer,
  StyledSelection,
} from "./userProfileStepOne.style";

const UserProfileStepOne: React.FC<UserProfileStepOneProps> = ({
  handleNextButton,
  userId,
}) => {
  const theme = useTheme();
  const {
    handleSelectionChange,
    selectQualification,
    companyName,
    setCompanyName,
    expertiseAndExperience,
    addMoreExpertiseHandler,
    expertiseAndExperienceInputHandler,
    validation,
    error,
    loading,
    qualificationsData,
    placeholderText,
    selectExpertise,
    editData,
    onMinusIcon,
  } = userProfileStepOneController({
    handleNextButton,
    userId,
  });
  return (
    <StyledContainer>
      <StyledSelection
        labelname={
          editData[0]?.qualification
            ? editData[0]?.qualification
            : "Qualification"
        }
        inputlabelstyle={{
          "&.Mui-focused": {
            color: theme.palette.grey[900],
          },
        }}
        value={selectQualification}
        onChange={(e: any) => handleSelectionChange(e)}
        size={"small"}
        MenuProps={{
          PaperProps: { sx: { maxHeight: "33%" } },
        }}
      >
        {qualificationsData.map((item) => (
          <MenuItem key={item._id} value={item?.qualification}>
            {item?.qualification}
          </MenuItem>
        ))}
      </StyledSelection>
      {/* <StyledInput
        variant="outlined"
        placeholder="Course Name"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={courseName}
        onChange={(val) => setCourseName(val?.target?.value)}
        helperText={error.courseName}
      /> */}
      {expertiseAndExperience.map((input, index) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            marginTop: 2,
          }}
          key={index}
        >
          <StyledInputContainer>
            <Box
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <MultipleSelectionDropDown
                data={expertiseData}
                placeholder={placeholderText(input)}
                onSelectValue={(
                  details: any,
                  mainCategory: any,
                  subMainCategory: any
                ) => {
                  selectExpertise(
                    index,
                    details,
                    mainCategory,
                    subMainCategory
                  );
                }}
                values={input?.selectedCategory}
              />
              <StyledErrorText>{input.expertiseError}</StyledErrorText>
            </Box>
            <Box>
              <StyledExperienceInput
                variant="outlined"
                placeholder="Experience(years)"
                InputLabelProps={{
                  style: { color: theme.palette.common.black },
                }}
                value={input.experience}
                onChange={(val) =>
                  expertiseAndExperienceInputHandler(
                    val?.target?.value,
                    index,
                    "experience"
                  )
                }
              />
              <StyledErrorTextTwo sx={{ marginLeft: 7 }}>
                {input.experienceError}
              </StyledErrorTextTwo>
            </Box>
          </StyledInputContainer>
          {index !== 0 && expertiseAndExperience?.length > 1 && (
            <IconButton onClick={() => onMinusIcon(input?._id ?? index)}>
              <RemoveCircleOutline />
            </IconButton>
          )}
        </Box>
      ))}
      <StyledAddButton onClick={addMoreExpertiseHandler}>
        Add More
      </StyledAddButton>
      <StyledInput
        variant="outlined"
        placeholder="Currently Working(Company Name)"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={companyName}
        onChange={(val) => setCompanyName(val?.target?.value)}
        helperText={error.companyName}
      />
      <StyledButtonContainer>
        <StyledButton
          onClick={() => {
            validation();
            handleNextButton;
          }}
          loading={loading}
        >
          Next
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default memo(UserProfileStepOne);
