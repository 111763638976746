import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "@services/api/api";
import { CompanyDetailsControllerProps } from "./companyDetails.interface";
import { Constant } from "@services/constant";
import {
  getJobWorkData,
  getProductData,
  getVacancyData,
} from "@services/redux/allCompanyDetails/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import {
  getPortfolioJobWorkData,
  getPortfolioProductData,
} from "@services/redux/companyPortfolio/action";

const companyDetailsController = (): CompanyDetailsControllerProps => {
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [portfolioLoading, setPortfolioLoading] = useState<boolean>(false);

  const { companyId } = useParams();

  useEffect(() => {
    getAllCompanyDetails();
    getCompanyPortfolioDetails();
  }, [companyId]);

  const navigation = useNavigate();

  const userList = useAppSelector((state) => state.userListReducer);

  const companyDetails = userList.companyUserList.filter((item: any) => {
    if (item._id === companyId) {
      return item;
    }
  });

  const dispatch = useAppDispatch();

  const goBack = (): void => {
    navigation(-1);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const getAllCompanyDetails = async () => {
    /* API call for getting all company details */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(Constant.companyAllData, {
        companyId: companyId,
      });
      setLoading(false);
      dispatch(getProductData(data.data?.product));
      dispatch(getJobWorkData(data.data?.jobWork));
      dispatch(getVacancyData(data.data?.jobVacancy));
    } catch (error) {
      setLoading(false);
    }
  };

  const getCompanyPortfolioDetails = async () => {
    try {
      setPortfolioLoading(true);
      const { data } = await axiosInstance.post(Constant.getCompanyPortfolio, {
        companyId: companyId,
      });
      dispatch(getPortfolioJobWorkData(data.data?.jobWork));
      dispatch(getPortfolioProductData(data.data?.product));
      setPortfolioLoading(false);
    } catch (error) {
      setPortfolioLoading(false);
    }
  };

  return {
    goBack,
    companyDetails,
    loading,
    companyId,
    portfolioLoading,
    handleClose,
    handleOpen,
    open,
  };
};

export default companyDetailsController;
