import React, { memo } from "react";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import moment from "moment";
import CompanyDetailsCard from "@component/card/companyDetailsCard/CompanyDetailsCard";
import { CompanyProfileDetailsProps } from "./companyProfileDetails.interface";
import { Constant } from "@services/constant";
import { image } from "@assets";
import { StyledNameContainer } from "@component/userProfileDetails/userProfileDetails.style";
import {
  StyledContainer,
  StyledList,
  StyledListContainer,
  StyledProfileContainer,
  StyledText,
  StyledTextWithBackground,
  StyledUserNameText,
  StyledUserProfile,
} from "./companyProfileDetails.style";

const CompanyProfileDetails: React.FC<CompanyProfileDetailsProps> = ({
  userDetailsData,
  profileImage,
  firstName,
  lastName,
  email,
  lastLogin,
  isAccountVerified,
  onEdit,
}) => {
  return (
    <StyledContainer sx={{ boxShadow: 3 }}>
      <StyledProfileContainer>
        <StyledUserProfile
          src={
            profileImage
              ? `${Constant.imageUrl}files/${profileImage?.split("/")[1]}`
              : image.userProfile
          }
        />
        <StyledNameContainer>
          <StyledUserNameText color="primary">{`${firstName} ${
            lastName ?? ""
          }`}</StyledUserNameText>
          <StyledText>{email}</StyledText>
          <StyledText sx={{ fontSize: 12 }}>
            Last login: {lastLogin ? moment(lastLogin).format("LLL") : "-"}
          </StyledText>
          <StyledTextWithBackground>
            {isAccountVerified ? "Verified" : "Not verified"}
          </StyledTextWithBackground>
        </StyledNameContainer>
        <IconButton
          sx={{ background: "rgba(243,107,16,0.2)", alignSelf: "flex-start" }}
          onClick={onEdit}
          disableRipple
          id="user-edit"
        >
          <Edit color="secondary" />
        </IconButton>
      </StyledProfileContainer>
      <StyledListContainer>
        <StyledList>
          {userDetailsData.map((item) => (
            <CompanyDetailsCard item={item} key={item._id} />
          ))}
        </StyledList>
      </StyledListContainer>
    </StyledContainer>
  );
};

export default memo(CompanyProfileDetails);
