import React, { memo } from "react";
import addSalesUserController from "./addSalesUser.controller";
import {
  StyledButton,
  StyledContainer,
  StyledErrorText,
  StyledInput,
  StyledSelection,
} from "./addSalesUser.style";
import { AddSalesUserProps } from "./addSalesUser.interface";
import { Slider, SnackBar } from "@component";
import { MenuItem, useTheme } from "@mui/material";
import { registerTypeList } from "./addSalesUser.const";

const AddSalesUser: React.FC<AddSalesUserProps> = ({
  handleCloseIcon,
  openDrawer,
  salesUserId,
  handleCloseSlider,
}) => {
  const {
    closeSnackbar,
    loading,
    message,
    snackbar,
    error,
    setEmail,
    setMobileNo,
    setName,
    setPassword,
    setType,
    validation,
    email,
    mobileNo,
    name,
    password,
    type,
    handleRegisterType,
  } = addSalesUserController({
    handleCloseIcon,
    openDrawer,
    salesUserId,
    handleCloseSlider,
  });
  const theme = useTheme();
  return (
    <Slider
      title={salesUserId ? "Edit Sales User" : "Add Sales User"}
      openDrawer={openDrawer}
      handleCloseIcon={handleCloseIcon}
    >
      <StyledContainer>
        <StyledInput
          variant="outlined"
          placeholder="Name"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={name}
          onChange={(val) => setName(val?.target?.value)}
          helperText={error.name}
          inputProps={{ maxLength: 80 }}
        />
        <StyledInput
          variant="outlined"
          placeholder="Email"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={email}
          onChange={(val) => setEmail(val?.target?.value)}
          helperText={error.email}
        />
        <StyledInput
          variant="outlined"
          placeholder="Mobile Number"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={mobileNo}
          onChange={(val) => setMobileNo(val.target.value.slice(0, 10))}
          helperText={error.mobileNo}
        />
        <StyledSelection
          labelname={
            salesUserId && typeof type !== "number" ? type : "Register Type"
          }
          inputlabelstyle={{
            "&.Mui-focused": {
              color: theme.palette.grey[900],
            },
          }}
          value={type}
          onChange={(e: any) => handleRegisterType(e)}
          size={"small"}
          MenuProps={{
            PaperProps: { sx: { maxHeight: "33%" } },
          }}
        >
          {registerTypeList.map((item) => (
            <MenuItem key={item.id} value={item?.number}>
              {item?.name}
            </MenuItem>
          ))}
        </StyledSelection>
        <StyledErrorText>{error.registerType}</StyledErrorText>
        {!salesUserId && (
          <StyledInput
            variant="outlined"
            placeholder="Password"
            InputLabelProps={{ style: { color: theme.palette.common.black } }}
            value={password}
            onChange={(val) => setPassword(val?.target?.value)}
            helperText={error.password}
          />
        )}
        <StyledButton
          loading={loading}
          onClick={validation}
          progressColor="info"
        >
          {salesUserId ? "Edit" : " Add"}
        </StyledButton>
        <SnackBar
          open={snackbar}
          onClose={closeSnackbar}
          onCloseClick={closeSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          message={message}
        />
      </StyledContainer>
    </Slider>
  );
};

export default memo(AddSalesUser);
