import React, { memo } from "react";
import { useTheme } from "@mui/material";
import { MasterModalProps } from "./masterModal.interface";
import {
  StyledButton,
  StyledButtonContainer,
  StyledInput,
  StyledModal,
  StyledModalContainer,
  StyledText,
} from "./masterModal.style";

const MasterModal: React.FC<MasterModalProps> = ({
  open,
  handleClose,
  title,
  buttonTitle,
  value,
  setValue,
  placeholder,
  loading,
  handleButton,
  error,
}) => {
  const theme = useTheme();
  return (
    <StyledModal open={open} onClose={handleClose}>
      <StyledModalContainer>
        <StyledText>{title}</StyledText>
        <StyledInput
          variant="outlined"
          placeholder={placeholder}
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={value}
          onChange={setValue}
          helperText={error}
        />
        <StyledButtonContainer>
          <StyledButton onClick={handleClose} color="primary">
            Cancel
          </StyledButton>
          <StyledButton
            onClick={handleButton}
            color="primary"
            variant="contained"
            sx={{ marginLeft: 5 }}
            loading={loading}
            progressColor="info"
          >
            {buttonTitle}
          </StyledButton>
        </StyledButtonContainer>
      </StyledModalContainer>
    </StyledModal>
  );
};

export default memo(MasterModal);
