import React, { memo } from "react";
import { MenuItem, useTheme } from "@mui/material";
import userStepOneController from "./userStepOne.controller";
import { UserStepOneProps } from "./userStepOne.interface";
import {
  StyledButton,
  StyledButtonContainer,
  StyledContainer,
  StyledErrorText,
  StyledInput,
  StyledSelection,
  StyledBox,
} from "./userStepOne.style";

const UserStepOne: React.FC<UserStepOneProps> = ({
  handleNext,
  userId,
  registerType,
  handleClose,
}) => {
  const {
    email,
    error,
    fullName,
    handleZone,
    mobileNo,
    setEmail,
    setFullName,
    setMobileNo,
    validation,
    selectZone,
    loading,
    editData,
    setOtherZone,
    zones,
    otherZone,
  } = userStepOneController({ handleNext, userId, registerType, handleClose });
  const theme = useTheme();
  return (
    <StyledContainer>
      <StyledBox>
        <StyledInput
          variant="outlined"
          placeholder="Full Name"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={fullName}
          onChange={(val) => setFullName(val?.target?.value)}
          helperText={error.fullName}
        />
        <StyledInput
          variant="outlined"
          placeholder="Email"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={email}
          onChange={(val) => setEmail(val?.target?.value)}
          helperText={error.email}
        />
        <StyledInput
          variant="outlined"
          placeholder="Mobile Number"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={mobileNo}
          onChange={(val) => setMobileNo(val.target.value.slice(0, 10))}
          helperText={error.mobileNo}
          sx={{ mb: 3 }}
        />
        <StyledSelection
          labelname={editData[0]?.zone ? editData[0]?.zone : "Zone"}
          inputlabelstyle={{
            "&.Mui-focused": {
              color: theme.palette.grey[900],
            },
          }}
          value={selectZone}
          onChange={(e: any) => handleZone(e)}
          MenuProps={{
            PaperProps: { sx: { maxHeight: "30%" } },
          }}
        >
          {zones.map((item) => (
            <MenuItem key={item._id} value={item?.zone}>
              {item?.zone}
            </MenuItem>
          ))}
        </StyledSelection>
        <StyledErrorText>{error.zone}</StyledErrorText>
        {selectZone === "Other" && (
          <StyledInput
            variant="outlined"
            placeholder="Other Zone"
            InputLabelProps={{
              style: { color: theme.palette.common.black },
            }}
            value={otherZone}
            onChange={(val) => setOtherZone(val?.target?.value)}
            helperText={error.otherZone}
          />
        )}
      </StyledBox>
      <StyledButtonContainer sx={{ justifyContent: "space-between" }}>
        <StyledButton
          sx={{ color: theme.palette.primary.main }}
          onClick={handleClose}
        >
          Cancel
        </StyledButton>
        <StyledButton onClick={validation} loading={loading}>
          Next
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default memo(UserStepOne);
