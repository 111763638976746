import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AddExpertiseObject,
  ErrorProps,
  QualificationProps,
  UserStepTwoControllerProps,
} from "./userStepTwo.interface";
import { axiosInstance } from "@services/api/api";
import { checkNumeric, checkString } from "@utils/validation/validation";
import { Constant } from "@services/constant";
import { editQualifications } from "@services/redux/addPersonUser/action";
import { SelectChangeEvent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import { User } from "@services/redux/userList/interface";
import { UserStepOneProps } from "../userStepOne/userStepOne.interface";
import validationMessage from "@utils/validation/validationMessage";

const userStepTwoController = ({
  handleNext,
  userId,
}: UserStepOneProps): UserStepTwoControllerProps => {
  const [selectQualification, setSelectQualification] = useState<string>();
  const [qualificationsData, setQualificationsData] = useState<
    QualificationProps[]
  >([]);
  const [expertiseAndExperience, setExpertiseAndExperience] = useState<
    AddExpertiseObject[]
  >([
    {
      selectMainCategory: null,
      selectSubMainCategory: null,
      selectedCategory: null,
      expertise: null,
      experience: undefined,
      experienceError: null,
      expertiseError: null,
    },
  ]);
  const [companyName, setCompanyName] = useState<string>();
  const [error, setError] = useState<ErrorProps>({
    selectQualification: null,
    courseName: null,
    companyName: null,
  });

  const { userList } = useAppSelector((state) => state.userListReducer);
  const { editQualificationsData } = useAppSelector(
    (state) => state.editPersonUserReducer
  );
  const dispatch = useAppDispatch();
  let editData: User[] = userList.filter((item: User) => item._id === userId);

  const placeholderText = (item: any): string => {
    return item?.selectSubMainCategory
      ? `${item?.selectMainCategory?.name}-${item?.selectSubMainCategory?.name}-${item?.selectedCategory?.name}`
      : item?.selectedCategory?.name
      ? `${
          item?.selectMainCategory
            ? item?.selectMainCategory?.name +
              "-" +
              item?.selectedCategory?.name
            : item?.selectedCategory?.name
        }`
      : "Select Expertise";
  };

  useEffect(() => {
    if (userId) {
      setCompanyName(editData[0].currentlyWorkingCompanyName);
      let temp: any = [];
      if (editData[0].qualificationData?.length != 0) {
        const temp = editData[0].qualificationData?.map((item: any) => {
          return {
            selectMainCategory: null,
            selectSubMainCategory: null,
            selectedCategory: { name: item.expertise },
            experience: item.experience,
            expertise: { name: item.expertise },
            expertiseError: null,
            experienceError: null,
            _id: item._id,
          };
        });
        //@ts-ignore
        setExpertiseAndExperience(temp);
      } else {
        temp.push({
          experience: null,
          expertise: null,
        });
        setExpertiseAndExperience(temp);
      }
    }
    getQualificationsData();
  }, []);

  const expertiseAndExperienceData = useMemo(() => {
    return expertiseAndExperience.map((item) => {
      if (item._id) {
        return {
          _id: item._id,
          expertise: placeholderText(item),
          experience: item.experience,
        };
      } else {
        return {
          expertise: placeholderText(item),
          experience: item.experience,
        };
      }
    });
  }, [expertiseAndExperience]);

  const getQualificationsData = async () => {
    /* API call for getting qualifications data */
    try {
      const { data } = await axiosInstance.get(Constant.getQualifications);
      setQualificationsData(data.data);
    } catch (error) {}
  };

  const handleSelectionChange = (event: SelectChangeEvent): void => {
    setSelectQualification(event.target.value);
  };

  const addMoreExpertiseHandler = useCallback(() => {
    expertiseAndExperience.push({
      selectMainCategory: null,
      selectSubMainCategory: null,
      selectedCategory: null,
      expertise: null,
      experience: undefined,
      experienceError: null,
      expertiseError: null,
    });
    setExpertiseAndExperience([...expertiseAndExperience]);
  }, [expertiseAndExperience]);

  const selectExpertise = useCallback(
    (index: number, details: any, mainCategory: any, subMainCategory: any) => {
      expertiseAndExperience[index].selectedCategory = details;
      expertiseAndExperience[index].selectMainCategory = mainCategory;
      expertiseAndExperience[index].selectSubMainCategory = subMainCategory;
      expertiseAndExperience[index].expertise = details;
      setExpertiseAndExperience([...expertiseAndExperience]);
    },
    [expertiseAndExperience]
  );

  const onMinusIcon = (index?: number | string) => {
    let temp = [...expertiseAndExperience];
    const data = temp.filter((item, key) => {
      if (item._id !== index && key !== index) {
        return item;
      }
    });
    setExpertiseAndExperience(data);
  };

  const expertiseAndExperienceInputHandler = useCallback(
    (value: string, index: number) => {
      expertiseAndExperience[index]["experience"] = value;
      setExpertiseAndExperience([...expertiseAndExperience]);
    },
    [expertiseAndExperience]
  );

  const saveEditedData = (): void => {
    let temp = { ...editQualificationsData };
    temp.qualification = selectQualification;
    temp.qualificationData = expertiseAndExperienceData;
    temp.currentlyWorkingCompanyName = companyName;
    dispatch(editQualifications(temp));
    handleNext();
  };

  const validation = (): void => {
    /* Validation for qualification data */
    let isValidate = true;
    expertiseAndExperience.map((item, index) => {
      if (index !== 0) {
        if (!item.selectedCategory) {
          isValidate = false;
          item.expertiseError = validationMessage.validationExpertiseMessage;
        } else {
          item.expertiseError = null;
        }
        if (!item.experience?.trim()) {
          isValidate = false;
          item.experienceError = validationMessage.validationExperienceMessage;
        } else if (!checkNumeric(item.experience)) {
          isValidate = false;
          item.experienceError =
            validationMessage.validationValidExperienceMessage;
        } else {
          item.experienceError = null;
        }
      } else {
        if (item.selectedCategory) {
          if (item.experience) {
            if (!item.experience?.trim()) {
              isValidate = false;
              item.experienceError =
                validationMessage.validationExperienceMessage;
            } else {
              item.experienceError = "";
            }
          }
        }
      }
    });
    if (companyName) {
      if (!checkString(companyName) || !companyName?.trim()) {
        isValidate = false;
        error.companyName = validationMessage.validationValidCompanyName;
      } else {
        error.companyName = null;
      }
    }
    setExpertiseAndExperience([...expertiseAndExperience]);
    setError({ ...error });
    if (isValidate) {
      saveEditedData();
    }
  };

  return {
    addMoreExpertiseHandler,
    editData,
    error,
    expertiseAndExperience,
    expertiseAndExperienceInputHandler,
    handleSelectionChange,
    onMinusIcon,
    placeholderText,
    qualificationsData,
    selectExpertise,
    setCompanyName,
    validation,
    companyName,
    selectQualification,
  };
};

export default userStepTwoController;
