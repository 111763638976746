import { memo } from "react";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import moment from "moment";
import { Constant } from "@services/constant";
import { image } from "@assets";
import UserDetailsCard from "@component/card/userDetailsCard/UserDetailsCard";
import { UserProfileDetailsProps } from "./userProfileDetails.interface";
import {
  StyledContainer,
  StyledNameContainer,
  StyledProfileContainer,
  StyledText,
  StyledTextWithBackground,
  StyledUserNameText,
  StyledUserProfile,
} from "./userProfileDetails.style";

const UserProfileDetails: React.FC<UserProfileDetailsProps> = ({
  userDetailsData,
  profileImage,
  firstName,
  lastName,
  email,
  isAccountVerified,
  onEdit,
  lastLogin,
}) => {
  return (
    <StyledContainer sx={{ boxShadow: 3 }}>
      <StyledProfileContainer>
        <StyledUserProfile
          src={
            profileImage
              ? `${Constant.imageUrl}files/${profileImage?.split("/")[1]}`
              : image.userProfile
          }
        />
        <StyledNameContainer>
          <StyledUserNameText color="primary">{`${firstName} ${
            lastName ?? ""
          }`}</StyledUserNameText>
          <StyledText>{email}</StyledText>
          <StyledText sx={{ fontSize: 12 }}>
            Last login: {lastLogin ? moment(lastLogin).format("LLL") : "-"}
          </StyledText>
          <StyledTextWithBackground>
            {isAccountVerified ? "Verified" : "Not verified"}
          </StyledTextWithBackground>
        </StyledNameContainer>
        <IconButton
          sx={{ background: "rgba(243,107,16,0.2)", alignSelf: "flex-start" }}
          onClick={onEdit}
          disableRipple
          id="user-edit"
        >
          <Edit color="secondary" />
        </IconButton>
      </StyledProfileContainer>
      {userDetailsData.map((item) => (
        <UserDetailsCard item={item} key={item._id} />
      ))}
    </StyledContainer>
  );
};

export default memo(UserProfileDetails);
