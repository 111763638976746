import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "@services/api/api";
import { checkEmail, checkPassword } from "@utils/validation/validation";
import { Constant } from "@services/constant";
import { LoginControllerProps, ValidationProps } from "./login.interface";
import { loginSuccess, setNavigation } from "@services/redux/user/action";
import { useAppDispatch } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const loginController = (): LoginControllerProps => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ValidationProps>({
    email: null,
    password: null,
  });
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>();

  const onForgotPassword = (): void => {
    navigation("forgotPassword");
  };

  const onLoginCall = async () => {
    /** API call for login */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(Constant.login, {
        email: email,
        password: password,
      });
      setLoading(false);
      dispatch(loginSuccess(data.data));

      if (data.data?.adminType === 1) {
        navigation("drawer/dashboard");
      } else {
        dispatch(setNavigation("Users"));
        navigation("drawer/users");
      }
    } catch (error: any) {
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
      setLoading(false);
    }
  };

  const validation = (): void => {
    let isValidate = true;
    if (!email) {
      isValidate = false;
      error.email = validationMessage.validationEmail;
    } else if (!checkEmail(email)) {
      isValidate = false;
      error.email = validationMessage.validationValidEmail;
    } else {
      error.email = null;
    }
    if (!password) {
      isValidate = false;
      error.password = validationMessage.validationPassword;
    } else if (!checkPassword(password)) {
      isValidate = false;
      error.password = validationMessage.validationValidPassword;
    } else {
      error.password = null;
    }
    setError({ ...error });

    if (isValidate) {
      onLoginCall();
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    onForgotPassword,
    onLoginCall,
    validation,
    error,
    loading,
    openSnackBar,
    setOpenSnackBar,
    snackBarMessage,
  };
};

export default loginController;
