import { TableCellDataProps } from "@component/customTable/table.interface";

export const tableCell: TableCellDataProps[] = [
  {
    id: 1,
    title: "",
  },
  {
    id: 2,
    title: "Name",
  },
  {
    id: 3,
    title: "User Type",
  },
  {
    id: 4,
    title: "Email",
  },
  {
    id: 5,
    title: "Mobile Number",
  },
  {
    id: 6,
    title: "Created On",
  },
  {
    id: 7,
    title: "Last Login",
  },
];

export const filterData = [
  {
    id: 1,
    name: "Today",
  },
  {
    id: 2,
    name: "This Week",
  },
  {
    id: 3,
    name: "Monthly",
  },
  {
    id: 4,
    name: "All",
  },
];
