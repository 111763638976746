import { useEffect, useState } from "react";
import {
  AddSalesUserControllerProps,
  AddSalesUserProps,
  ErrorProps,
  FormDataProps,
} from "./addSalesUser.interface";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import validationMessage from "@utils/validation/validationMessage";
import {
  checkEmail,
  checkMobileNumber,
  checkPassword,
  checkString,
} from "@utils/validation/validation";
import { SelectChangeEvent } from "@mui/material";
import { SalesUsersProps } from "../sales.interface";

const addSalesUserController = ({
  salesUserId,
  handleCloseSlider,
}: AddSalesUserProps): AddSalesUserControllerProps => {
  const [snackbar, setSnackbar] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [mobileNo, setMobileNo] = useState<string>();
  const [type, setType] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [error, setError] = useState<ErrorProps>({
    name: undefined,
    email: undefined,
    mobileNo: undefined,
    registerType: undefined,
    password: undefined,
  });
  const [salesUsers, setSalesUsers] = useState<SalesUsersProps[]>([]);

  useEffect(() => {
    getSalesUsers();
  }, []);

  const getSalesUsers = async () => {
    /* API call for getting sales users */
    try {
      const { data } = await axiosInstance.get(Constant.getSalesUsers);
      const list = data?.data?.map((item: any) => ({
        _id: item?._id,
        name: item?.name,
        email: item?.email,
        mobileNo: item?.mobileNumber?.toString(),
        permissionType: item?.permissionType?.toString(),
      }));
      setSalesUsers(list);
    } catch (error: any) {}
  };

  useEffect(() => {
    if (salesUserId) {
      const list = salesUsers?.filter((item) => item._id === salesUserId)[0];
      setName(list?.name);
      setEmail(list?.email);
      setMobileNo(list?.mobileNo);
      setType(
        list?.permissionType === "0" ? "All Permissions" : "Partial Permissions"
      );
    } else {
      setName("");
      setEmail("");
      setMobileNo("");
      setType("");
      setError({
        name: undefined,
        email: undefined,
        mobileNo: undefined,
        registerType: undefined,
        password: undefined,
      });
    }
  }, [salesUserId]);

  const handleRegisterType = (event: SelectChangeEvent): void => {
    setType(event?.target?.value);
  };

  const addEditSalesUser = async () => {
    /* API call for adding/editing sales user */
    try {
      setLoading(true);
      const formData: FormDataProps = {
        name: name,
        email: email,
        mobileNumber: mobileNo,
        permissionType: type,
        password: password,
      };
      const { data } = await axiosInstance.post(
        Constant.addEditSalesUser,
        formData
      );
      setName("");
      setEmail("");
      setMobileNo("");
      setType(undefined);
      setPassword("");
      setLoading(false);
      setSnackbar(true);
      setMessage(data?.message);
      setTimeout(() => handleCloseSlider(), 300);
    } catch (error: any) {
      setLoading(false);
      setSnackbar(true);
      setMessage(error?.data?.message);
    }
  };
  const editSalesUser = async () => {
    /* API call for adding/editing sales user */
    try {
      setLoading(true);
      const formData: FormDataProps = {
        name: name,
        email: email,
        mobileNumber: mobileNo,
        permissionType: type,
        id: salesUserId,
      };
      const { data } = await axiosInstance.post(
        Constant.addEditSalesUser,
        formData
      );
      setLoading(false);
      setSnackbar(true);
      setMessage(data?.message);
      setTimeout(() => handleCloseSlider(), 300);
    } catch (error: any) {
      setLoading(false);
      setSnackbar(true);
      setMessage(error?.data?.message);
    }
  };

  const validation = (): void => {
    let isValid = true;
    if (!name?.trim()) {
      isValid = false;
      error.name = validationMessage.emptyName;
    } else if (!checkString(name)) {
      isValid = false;
      error.name = validationMessage.invalidName;
    } else {
      error.name = undefined;
    }
    if (!email?.trim()) {
      isValid = false;
      error.email = validationMessage.validationEmail;
    } else if (!checkEmail(email)) {
      isValid = false;
      error.email = validationMessage.validationValidEmail;
    } else {
      error.email = undefined;
    }
    if (!mobileNo?.trim()) {
      isValid = false;
      error.mobileNo = validationMessage.validationMobileNo;
    } else if (!checkMobileNumber(mobileNo)) {
      isValid = false;
      error.mobileNo = validationMessage.validationValidMobileNo;
    } else {
      error.mobileNo = undefined;
    }
    if (type === undefined) {
      isValid = false;
      error.registerType = validationMessage.registerType;
    } else {
      error.registerType = undefined;
    }
    if (!salesUserId) {
      if (!password?.trim()) {
        isValid = false;
        error.password = validationMessage.validationPassword;
      } else if (!checkPassword(password)) {
        isValid = false;
        error.password = validationMessage.validationValidPassword;
      } else {
        error.password = undefined;
      }
    }
    setError({ ...error });
    if (isValid) {
      if (salesUserId) {
        editSalesUser();
      } else {
        addEditSalesUser();
      }
    }
  };

  const closeSnackbar = (): void => {
    setSnackbar(false);
  };

  return {
    closeSnackbar,
    loading,
    message,
    snackbar,
    name,
    setName,
    email,
    setEmail,
    mobileNo,
    setMobileNo,
    type,
    setType,
    password,
    setPassword,
    validation,
    error,
    handleRegisterType,
  };
};

export default addSalesUserController;
