import { useMemo, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import { removeVacancyData } from "@services/redux/allCompanyDetails/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import {
  VacancyControllerProps,
  VacancyDataProps,
  VacancyProps,
} from "./vacancy.interface";

const vacancyController = ({
  userId,
}: VacancyProps): VacancyControllerProps => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [index, setIndex] = useState<string>();
  const [vacancyId, setVacancyId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { vacancy } = useAppSelector((state) => state.allCompanyDetailsReducer);
  const dispatch = useAppDispatch();

  const vacancyData: VacancyDataProps[] = useMemo(
    () =>
      vacancy
        ? vacancy
            ?.map((item: VacancyDataProps) => ({
              _id: item._id,
              qualification: item.qualification,
              expertise: item.expertise,
              experience: item.experience,
              description: item.description,
              files: item.files,
            }))
            .reverse()
        : [],
    [vacancy]
  );

  const handleCloseDrawer = (): void => {
    setOpenDrawer(false);
  };

  const handleOpenDrawer = (id?: string): void => {
    setOpenDrawer(true);
    setIndex(id);
  };

  const removeVacancy = async (vacancyId: string) => {
    /* API call for removing vacancy */
    setVacancyId(vacancyId);
    const formData = new URLSearchParams();
    if (typeof userId === "string") {
      formData.append("userId", userId);
    }
    formData.append("type", "3");
    formData.append("jobVacancyId", vacancyId);
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.RemoveProductJobworkJobVacancy,
        formData.toString()
      );
      setLoading(false);
      dispatch(removeVacancyData(data.data));
    } catch (error) {
      setLoading(false);
    }
  };

  return {
    vacancyData,
    handleCloseDrawer,
    openDrawer,
    handleOpenDrawer,
    index,
    removeVacancy,
    loading,
    vacancyId,
  };
};

export default vacancyController;
