import { useState } from "react";
import {
  EditUserModalControllerProps,
  EditUserModalProps,
} from "./editCompanyModal.interface";
import { stepsData } from "@pages/home/users/addUserSlider/addUserSlider.const";

const editUserModalController = ({
  onClose,
}: EditUserModalProps): EditUserModalControllerProps => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? stepsData.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const totalSteps = (): number => {
    return stepsData?.length;
  };

  const completedSteps = (): number => {
    return Object.keys(completed)?.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const onNext = (): void => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    if (allStepsCompleted()) {
      onClose();
      setActiveStep(0);
      setCompleted({});
    }
  };

  const onCloseModal = (): void => {
    onClose();
    setActiveStep(0);
    setCompleted({});
  };

  return { activeStep, completed, onNext, onCloseModal };
};

export default editUserModalController;
