import React from "react";
import { MenuItem } from "@mui/material";
import { CircularProgress, SnackBar, Table } from "@component";
import { filterData, tableCell } from "./salesReport.const";
import salesReportController from "./salesReport.controller";
import {
  StyledContainer,
  StyledBox,
  StyledText,
} from "@pages/home/company/company.style";
import {
  StyledSelection,
  StyledProgressContainer,
  StyledSelectionContainer,
  TotalText,
  StyledLable,
} from "./salesReport.style";

const SalesReport: React.FC = () => {
  const {
    loading,
    salesData,
    setSnackbar,
    snackbar,
    toast,
    handleFilterType,
    filterType,
    salesUsers,
    handleUser,
    salesId,
  } = salesReportController();
  return (
    <StyledContainer>
      <StyledText variant="h4">Sales Report</StyledText>
      <StyledBox>
        <StyledSelectionContainer>
          <StyledLable>Select User</StyledLable>
          <StyledSelection
            value={salesId}
            onChange={(e: any) => handleUser(e)}
            size={"small"}
            defaultValue={"hello"}
            MenuProps={{
              PaperProps: { sx: { maxHeight: "33%" } },
            }}
          >
            {salesUsers.map((item) => (
              <MenuItem key={item._id} value={item?._id}>
                {item?.name}
              </MenuItem>
            ))}
          </StyledSelection>
        </StyledSelectionContainer>
        <StyledSelectionContainer>
          <StyledLable>Select Period</StyledLable>
          <StyledSelection
            value={filterType}
            onChange={(e: any) => handleFilterType(e)}
            size={"small"}
            MenuProps={{
              PaperProps: { sx: { maxHeight: "33%" } },
            }}
          >
            {filterData.map((item) => (
              <MenuItem key={item.id} value={item?.name}>
                {item?.name}
              </MenuItem>
            ))}
          </StyledSelection>
        </StyledSelectionContainer>
      </StyledBox>
      {salesData?.length !== 0 && (
        <TotalText>Total Users {salesData?.length}</TotalText>
      )}
      {loading ? (
        <StyledProgressContainer>
          <CircularProgress size={40} color={"primary"} />
        </StyledProgressContainer>
      ) : (
        <Table
          tableData={salesData}
          tableCellData={tableCell}
          search={salesId}
        />
      )}
      <SnackBar
        open={snackbar}
        onClose={() => setSnackbar(false)}
        onCloseClick={() => setSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        message={toast}
      />
    </StyledContainer>
  );
};

export default SalesReport;
