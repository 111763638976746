import React, { memo } from "react";
import { Avatar, Box, IconButton, useTheme } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { ReportCardProps } from "./reportCard.interface";
import Text from "@component/text/Text";
import {
  StyledBox,
  StyledButton,
  StyledPaper,
  StyledText,
  StyledTitleText,
} from "./reportCard.style";

const ReportCard: React.FC<ReportCardProps> = ({ item }) => {
  const theme = useTheme();
  return (
    <StyledPaper>
      <StyledBox>
        <Avatar src={item.profileImage} />
        <Box ml={theme.spacing(6)} flex={1}>
          <Text variant="h6">{item.userName}</Text>
          <Text fontSize={14}>{item.date}</Text>
        </Box>
        <IconButton id={`details${item.id}`}>
          <MoreHoriz color="info" />
        </IconButton>
      </StyledBox>
      <Box mt={theme.spacing(8)} ml={theme.spacing(2)}>
        <StyledBox>
          <StyledTitleText>Reason : </StyledTitleText>
          <StyledText>{item.reason}</StyledText>
        </StyledBox>
        <StyledBox>
          <StyledTitleText mt={theme.spacing(2)} variant="h6">
            Reported By :
          </StyledTitleText>
          <StyledText>Laura Myers</StyledText>
        </StyledBox>
      </Box>
      <Box mt={theme.spacing(8)}>
        <StyledButton
          fullWidth
          variant={item.resolved ? "text" : "contained"}
          disabled={item.resolved ?? false}
          id={`messageReporter${item.id}`}
        >
          {item.resolved ? "Resolved" : "Message reporter"}
        </StyledButton>
        <StyledButton
          fullWidth
          variant={item.resolved ? "text" : "contained"}
          disabled={item.resolved ?? false}
          id={`messageReportee${item.id}`}
        >
          {item.resolved ? "Resolved" : "Message reportee"}
        </StyledButton>
      </Box>
    </StyledPaper>
  );
};

export default memo(ReportCard);
