import React, { memo } from "react";
import { Box, IconButton, MenuItem, useTheme } from "@mui/material";
import { CheckBox, Delete } from "@mui/icons-material";
import editVacancySliderController from "./editVacancySlider.Controller";
import { expertiseData } from "@pages/home/users/addUserSlider/userProfileStepOne/userProfileStepOne.const";
import { EditVacancySliderProps } from "./editVacancySlider.interface";
import { MultipleSelectionDropDown, Slider, SnackBar } from "@component";
import {
  StyledButton,
  StyledButtonContainer,
  StyledChooseButton,
  StyledChooseFileContainer,
  StyledChooseText,
  StyledContainer,
  StyledErrorText,
  StyledFileInput,
  StyledFileText,
  StyledInput,
  StyledLabel,
  StyledSelection,
  StyledSelectionButton,
  StyledText,
} from "./editVacancySlider.style";

const EditVacancySlider: React.FC<EditVacancySliderProps> = ({
  openDrawer,
  handleCloseIcon,
  vacancyId,
  userId,
  data,
  type,
}) => {
  const theme = useTheme();
  const {
    description,
    expectedSalary,
    experience,
    noOfPosition,
    removeFile,
    selectedVacancy,
    selectedFile,
    setDescription,
    setExpectedSalary,
    setExperience,
    setNoOfPosition,
    setRemoveFile,
    setSelectedFile,
    setWorkAs,
    workAs,
    error,
    selectedFileError,
    handleCloseSnackBar,
    openSnackBar,
    snackBarMessage,
    loading,
    handleSelectFile,
    validation,
    onDeleteIcon,
    selectedCategory,
    setSelectMainCategory,
    setSelectSubMainCategory,
    setSelectedCategory,
    handleSelectionChange,
    selectQualification,
    qualificationsData,
    vacancyData,
  } = editVacancySliderController({
    handleCloseIcon,
    openDrawer,
    userId,
    vacancyId,
    data,
    type,
  });
  return (
    <Slider
      openDrawer={openDrawer}
      handleCloseIcon={() => {
        handleCloseIcon();
      }}
      title={vacancyId ? "Edit Vacancy" : "Add Vacancy"}
    >
      <StyledContainer>
        <MultipleSelectionDropDown
          dropDownButtonStyle={{
            sx: {
              width: "100%",
              [theme.breakpoints.down("sm")]: { width: "100%" },
            },
          }}
          data={expertiseData}
          placeholder={selectedVacancy}
          onSelectValue={(
            details: any,
            mainCategory: any,
            subMainCategory: any
          ) => {
            setSelectMainCategory(mainCategory);
            setSelectSubMainCategory(subMainCategory);
            setSelectedCategory(details);
          }}
          values={selectedCategory}
        />
        <StyledErrorText>{error.selectExpertiseError}</StyledErrorText>
        {/* <StyledText>Qualification</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="Qualification"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={qualification}
          onChange={(val) => setQualification(val?.target?.value)}
          helperText={error.qualificationError}
        /> */}
        <Box sx={{ marginTop: theme.spacing(10) }}>
          <StyledSelection
            labelname={
              vacancyData?.qualification
                ? vacancyData?.qualification
                : "Qualification"
            }
            inputlabelstyle={{
              "&.Mui-focused": {
                color: theme.palette.grey[900],
              },
            }}
            value={selectQualification}
            onChange={(e: any) => handleSelectionChange(e)}
            size={"small"}
            MenuProps={{
              PaperProps: { sx: { maxHeight: "33%" } },
            }}
          >
            {qualificationsData.map((item) => (
              <MenuItem key={item._id} value={item?.qualification}>
                {item?.qualification}
              </MenuItem>
            ))}
          </StyledSelection>
        </Box>
        <StyledErrorText>{error.qualificationError}</StyledErrorText>
        <StyledText>Experience</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="Experience"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={experience}
          onChange={(val) => setExperience(val?.target?.value)}
          helperText={error.experienceError}
        />
        <StyledText>Description</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="Description"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={description}
          multiline
          rows={5}
          onChange={(val) => setDescription(val?.target?.value)}
        />
        <StyledText>No. of Position</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="No. of Position"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={noOfPosition}
          onChange={(val) => setNoOfPosition(val?.target?.value)}
          helperText={error.noOfPositionError}
        />
        <StyledText>Expected Salary</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="Expected Salary"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={expectedSalary}
          onChange={(val) => setExpectedSalary(val?.target?.value)}
          helperText={error.expectedSalaryError}
        />
        <StyledText>Add Attachment</StyledText>
        <StyledFileInput
          id="selectPortfolio"
          type="file"
          onChange={handleSelectFile}
        />
        <StyledChooseFileContainer>
          <StyledLabel htmlFor="selectPortfolio">
            <StyledChooseButton>
              {selectedFile ? "Selected file" : "Choose file"}
            </StyledChooseButton>
          </StyledLabel>
          {selectedFile && (
            <StyledFileText>
              {selectedFile.name ?? selectedFile}
              <IconButton onClick={onDeleteIcon}>
                <Delete color="secondary" />
              </IconButton>
            </StyledFileText>
          )}
        </StyledChooseFileContainer>
        <StyledErrorText>{selectedFileError}</StyledErrorText>
        <StyledText>Work As</StyledText>
        <StyledSelectionButton
          onClick={() => setWorkAs("Full Time")}
          startIcon={
            <CheckBox color={workAs === "Full Time" ? "primary" : "disabled"} />
          }
        >
          <StyledChooseText>Full Time</StyledChooseText>
        </StyledSelectionButton>
        <StyledSelectionButton
          onClick={() => setWorkAs("Part Time")}
          startIcon={
            <CheckBox color={workAs === "Part Time" ? "primary" : "disabled"} />
          }
        >
          <StyledChooseText>Part Time</StyledChooseText>
        </StyledSelectionButton>
        <StyledErrorText>{error.workAsError}</StyledErrorText>
        <SnackBar
          open={openSnackBar}
          onClose={handleCloseSnackBar}
          onCloseClick={handleCloseSnackBar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          message={snackBarMessage}
        />
        <StyledButtonContainer>
          <StyledButton
            loading={loading}
            onClick={validation}
            progressColor="info"
          >
            {vacancyId ? "Edit" : "Add"}
          </StyledButton>
        </StyledButtonContainer>
      </StyledContainer>
    </Slider>
  );
};

export default memo(EditVacancySlider);
