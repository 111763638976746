export default {
  validationEmpty: undefined,
  validationFirstName: "Please enter First Name",
  validationValidFirstName: "Please enter valid First Name",
  validationLastName: "Please enter Last Name",
  validationValidLastName: "Please enter valid Last Name",
  validationEmail: "Please enter your Email",
  validationMobileNo: "Please enter your Mobile Number",
  validationValidMobileNo: "Please enter valid Mobile Number",
  validationPassword: "Please enter your Password",
  validationValidEmail: "Please enter valid Email Address",
  validationValidPassword: "Please enter valid Password",
  validationNewPassword: "Please enter New Password",
  validationValidNewPassword: "Please enter valid New Password",
  validationConfirmPassword:
    "Password length must be greater than 8 characters",
  validationValidConfirmPassword: "Please enter valid Confirm Password",
  validationPassConfirmPassNotMatch:
    "Password and Confirm Password does not match",
  validationQualification: "Please select your Qualification",
  validationCourseName: "Please enter Course Name",
  validationValidCourseName: "Please enter valid Course Name",
  validationCompanyName: "Please enter Company Name",
  validationValidCompanyName: "Please enter valid Company Name",
  validationSalaryMessage: "Please enter your Expected Salary",
  validationValidSalaryMessage: "Please enter valid Expected Salary",
  validationSelectWorkMessage: "Please select your Work Time",
  validationExpertiseMessage: "Please select your Expertise",
  validationValidExpertiseMessage: "Please enter valid Expertise",
  validationExperienceMessage: "Please enter your Experience",
  validationValidExperienceMessage: "Please enter valid Experience",
  validationOwnerName: "Please enter Owner Name",
  validationValidOwnerName: "Please enter valid Owner Name",
  validationAddress: "Please enter your Address",
  validationGSTNumber: "Please enter your GST number",
  validationValidGSTNumber: "Please enter valid GST number",
  validatonAnnualTurnOver: "Please enter your Annual Turnover",
  validatonValidAnnualTurnOver: "Please enter valid Annual Turnover",
  validatonNoOfEmployees: "Please enter number of Employees",
  validatonValidNoOfEmployees: "Please enter valid number of Employees",
  validatonWebsiteLink: "Please enter your Website Link",
  validatonValidWebsiteLink: "Please enter valid Website Link",
  validationSelectCompanyTypeMessage: "Please select your Company Type",
  validationAboutCompany: "Please enter something about your Company",
  validationValidAboutCompany:
    "Please enter something valid about your Company",
  validationLegalFirmName: "Please enter Legal Firm Name",
  validationValidLegalFirmName: "Please enter valid Legal Firm Name",
  validationPasswordOldAndNew: "New and old password should not be same",
  validationValidQualification: "Please enter valid Qualification",
  validationZone: "Please enter your Zone",
  selectZone: "Please select your Zone",
  validationValidZone: "Please enter valid Zone",
  productNameError: "Please enter Product Name",
  validationDescription: "Please enter Description",
  validationNoOfProductError: "Please enter No. of Product",
  validationValidNoOfProductError: "Please enter valid No. of Product",
  validationSelectedFileError: "Please choose valid file",
  validationSelectedExpertise: "Please Select Expertise",
  qualificationValidation: "Please enter your Qualification",
  noOfPositionValidation: "Please enter Number of Positions",
  validNoOfPositionValidation: "Please enter valid Number of Positions",
  approxSalaryValidation: "Please enter Approx Salary",
  validApproxSalaryValidation: "Please enter valid Approx Salary",
  workAsValidation: "Please select Work Type",
  selectMachineValidation: "Please select Machine",
  noOfMachineValidation: "Please enter no. of Machine",
  validNoOfMachineValidation: "Please enter valid no. of Machine",
  selectProcessValidation: "Please select Process",
  noOfProcessValidation: "Please enter no. of Process",
  validNoOfProcessValidation: "Please enter valid no. of Process",
  emptyExpertise: "Please enter Expertise",
  emptyMainSubcategory: "Please enter Main Subcategory",
  emptySubcategory: "Please enter Subcategory",
  emptyMachine: "Please enter Machine",
  emptyProcess: "Please enter Process",
  emptyName: "Please enter your Name",
  invalidName: "Please enter valid Name",
  registerType: "Please select your Register Type",
  emptyTitle: "Please enter Title",
  invalidTitle: "Please enter valid Title",
  emptyDescription: "Please enter Description",
};
