import { UsersFromCountriesDataProps } from "./usersFromCountries.interface";

export const usersFromCountriesData: UsersFromCountriesDataProps[] = [
  {
    id: 1,
    country: "United Kingdom (Kana)",
    numberOfUsers: 1024,
    progressColor: "#034694",
  },
  {
    id: 2,
    country: "United States",
    numberOfUsers: 216,
    progressColor: "#034694",
  },
  {
    id: 3,
    country: "Netherlands",
    numberOfUsers: 117,
    progressColor: "#034694",
  },
  {
    id: 4,
    country: "Australia",
    numberOfUsers: 91,
    progressColor: "#034694",
  },
  {
    id: 5,
    country: "South Africa",
    numberOfUsers: 88,
    progressColor: "#034694",
  },
  {
    id: 6,
    country: "Germany",
    numberOfUsers: 72,
    progressColor: "#034694",
  },
  {
    id: 7,
    country: "India",
    numberOfUsers: 71,
    progressColor: "#034694",
  },
  {
    id: 8,
    country: "Argentina",
    numberOfUsers: 58,
    progressColor: "#034694",
  },
  {
    id: 9,
    country: "New Zealand",
    numberOfUsers: 42,
    progressColor: "#034694",
  },
];
