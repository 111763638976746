import { useEffect, useMemo, useState } from "react";
import {
  addJobWorkData,
  editJobWorkData,
} from "@services/redux/allCompanyDetails/action";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  editProcessSliderControllerProps,
  EditProcessSliderProps,
  ErrorProps,
} from "./editProcessSlider.interface";
import { JobWorkType } from "../../machine/editMachineSlider/editMachineSlider.interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const editProcessSliderController = ({
  handleCloseIcon,
  openDrawer,
  processId,
  userId,
  type,
  data,
}: EditProcessSliderProps): editProcessSliderControllerProps => {
  const { jobWork } = useAppSelector((state) => state.allCompanyDetailsReducer);
  const dispatch = useAppDispatch();

  const processData =
    type === "master"
      ? data
      : useMemo(
          () =>
            jobWork?.filter((item: any) => {
              return item._id === processId;
            }),
          [jobWork]
        )[0];

  const [description, setDescription] = useState<string>();
  // const [noOfProcess, setNoOfProcess] = useState<string>(
  //   processData?.noOfProcesses
  // );
  const [selectedFile, setSelectedFile] = useState<Blob | string | any>();
  const [removeFile, setRemoveFile] = useState<string>();
  const [error, setError] = useState<ErrorProps>({
    selectProcessError: null,
    noOfProcessError: null,
  });
  const [selectedFileError, setSelectedFileError] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");
  const [selectedProcess, setSelectedProcess] = useState<
    JobWorkType | string | any
  >("");
  const [selectProcessMainCategory, setSelectProcessMainCategory] = useState<
    JobWorkType | string | any
  >("");
  const [selectProcessSubMainCategory, setSelectProcessSubMainCategory] =
    useState<JobWorkType | string | any>("");

  useEffect(() => {
    setDescription(
      type === "master"
        ? processData?.description
        : processData?.processesJobDescription
    );
    setSelectedFile(
      type === "master"
        ? processData?.profileImage
        : processData?.processesFiles
    );
  }, [processData]);

  const processName =
    type === "master" ? processData?.product : processData?.processes;

  const selectProcess = selectProcessSubMainCategory
    ? `${selectProcessMainCategory?.name}-${selectProcessSubMainCategory?.name}-${selectedProcess?.name}`
    : selectedProcess
    ? `${
        selectProcessMainCategory
          ? selectProcessMainCategory?.name + "-" + selectedProcess?.name
          : selectedProcess?.name
      }`
    : processName
    ? processName
    : "Select Processes";

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const handleSelectedFile = (e: any): void => {
    /* Functionality for uploading documents */
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 200000000) {
        setSelectedFile("");
        setSelectedFileError(
          "The uploaded file exceeds the maximum allowed size of 200MB"
        );
      } else {
        setSelectedFile(selectedFile);
        setSelectedFileError("");
      }
    }
  };

  const onDeleteIcon = (): void => {
    if (selectedFile) {
      setRemoveFile(
        type === "master"
          ? processData?.profileImage
          : processData?.processesFiles
      );
    }

    setSelectedFile("");
  };

  const validation = (): void => {
    /* Validation for edit process form */
    let isValidate = true;
    if (!processId) {
      if (!selectedProcess) {
        isValidate = false;
        error.selectProcessError = validationMessage.selectProcessValidation;
      } else {
        error.selectProcessError = "";
      }
    }
    // if (!noOfProcess) {
    //   isValidate = false;
    //   error.noOfProcessError = validationMessage.noOfProcessValidation;
    // } else if (!checkNumeric(noOfProcess)) {
    //   isValidate = false;
    //   error.noOfProcessError = validationMessage.validNoOfProcessValidation;
    // } else {
    //   error.noOfProcessError = "";
    // }
    setError({ ...error });
    if (isValidate) {
      if (processId) {
        editProcess();
      } else {
        addProcess();
      }
    }
  };

  const addProcess = async () => {
    /* API call for adding process */
    const formData = new FormData();
    if (typeof userId === "string") {
      formData.append("userId", userId);
    }
    formData.append("processes", selectProcess);
    if (description) {
      formData.append("processesJobDescription", description);
    }
    // formData.append("noOfProcesses", noOfProcess);
    if (selectedFile) {
      formData.append("processesFiles", selectedFile);
    }
    if (removeFile) {
      formData.append("removeProcessesUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(Constant.addJobWork, formData);
      setLoading(false);
      setSelectProcessMainCategory("");
      setSelectProcessSubMainCategory("");
      setSelectedProcess("");
      setDescription("");
      setSelectedFile("");
      dispatch(addJobWorkData(data.data));
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const editProcess = async () => {
    /* API call for editing process */
    const formData = new FormData();
    if (typeof userId === "string") {
      formData.append("userId", userId);
    }
    if (typeof processId === "string") {
      formData.append("jobWorkId", processId);
    }
    formData.append("processes", selectProcess);
    if (description) {
      formData.append("processesJobDescription", description);
    }
    // formData.append("noOfProcesses", noOfProcess);
    if (selectedFile) {
      formData.append("processesFiles", selectedFile);
    }
    if (removeFile) {
      formData.append("removeProcessesUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.editJobWorkAdmin,
        formData
      );
      setLoading(false);
      dispatch(editJobWorkData(data.data));
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  return {
    description,
    error,
    handleSelectedFile,
    selectedFile,
    selectedFileError,
    setDescription,
    validation,
    handleCloseSnackBar,
    loading,
    openSnackBar,
    snackBarMessage,
    selectProcess,
    onDeleteIcon,
    selectedProcess,
    selectProcessMainCategory,
    selectProcessSubMainCategory,
    setSelectedProcess,
    setSelectProcessMainCategory,
    setSelectProcessSubMainCategory,
  };
};

export default editProcessSliderController;
