import React, { memo } from "react";
import { useTheme } from "@mui/material";
import { LogoutOutlined } from "@mui/icons-material";
import drawerItemController from "./drawerItem.controller";
import { DrawerItemProps, MenuItemProps } from "./drawerItem.interface";
import DrawerUserProfile from "@component/drawerUserProfile/DrawerUserProfile";
import MasterDropdown from "@component/masterDropdown/MasterDropdown";
import Text from "@component/text/Text";
import {
  StyledBorder,
  StyledButton,
  StyledExpandLessIcon,
  StyledExpandMoreIcon,
  StyledList,
  StyledListContainer,
  StyledLogout,
} from "./drawerItem.style";

const DrawerItem: React.FC<DrawerItemProps> = ({
  data,
  onItemClick,
  isMobile,
  handleCollapse,
  id,
}) => {
  const theme = useTheme();
  const {
    selectedItem,
    onItemPress,
    onMasterData,
    onLogout,
    masterId,
    openSubDetails,
  } = drawerItemController({
    data,
    onItemClick,
    isMobile,
    handleCollapse,
    id,
  });
  const MenuItem: React.FC<MenuItemProps> = ({ item }) => {
    return (
      <>
        <StyledButton
          key={item.id}
          fullWidth
          onClick={() => {
            onItemPress(item);
            item.title !== "Master" && isMobile && onItemClick && onItemClick();
          }}
          id={item.title}
        >
          <StyledBorder
            sx={{
              background: selectedItem(item),
            }}
          />
          <item.icon
            sx={{
              ml: 9,
              color: theme.palette.common.white,
            }}
          />
          <Text ml={10} color={theme.palette.common.white}>
            {item.title}
          </Text>
          {item.subData &&
            (item.id === id && openSubDetails ? (
              <StyledExpandLessIcon />
            ) : (
              <StyledExpandMoreIcon />
            ))}
        </StyledButton>
        {item.subData &&
          item.id === id &&
          openSubDetails &&
          item.subData?.map((value: any) => (
            <MasterDropdown
              item={value}
              onIcon={() => onMasterData(value)}
              id={masterId}
            />
          ))}
      </>
    );
  };

  return (
    <>
      <DrawerUserProfile />
      <StyledListContainer>
        <StyledList>
          {data.map((item) => (
            <MenuItem key={item.id} item={item} />
          ))}
        </StyledList>
        <StyledLogout
          fullWidth
          onClick={onLogout}
          id="logout"
          sx={{ position: "absolute", bottom: 0 }}
        >
          <LogoutOutlined
            sx={{
              ml: 9,
              color: theme.palette.common.white,
            }}
          />
          <Text ml={10} color={theme.palette.common.white}>
            Logout
          </Text>
        </StyledLogout>
      </StyledListContainer>
    </>
  );
};

export default memo(DrawerItem);
