import { Box, styled } from "@mui/material";
import { Button, Container, Text } from "@component";

export const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(10, 10),
  flexDirection: "column",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 16,
  width: theme.spacing(10),
  padding: 0,
  justifyContent: "flex-start",
}));

export const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(2),
}));

export const StyledDetailsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));
