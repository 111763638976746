import React, { memo } from "react";
import { useTheme } from "@mui/material";
import { CircularProgress } from "@component";
import jobWorkController from "./jobWork.controller";
import Machine from "../machine/Machine";
import Process from "../process/Process";
import { PortfolioJobWorkProps } from "../../companyPortfolio/portfolioJobWork/portfolioJobWork.interface";
import { tabData } from "../../companyPortfolio/portfolioJobWork/portfolioJobWork.const";
import { StyledBox, StyledButton, StyledContainer } from "./jobWork.style";

const JobWork: React.FC<PortfolioJobWorkProps> = ({ loading, userId }) => {
  const { select, setSelect } = jobWorkController();
  const theme = useTheme();

  const getPostData = (select: number) => {
    switch (select) {
      case 1: {
        return <Machine userId={userId} />;
      }
      case 2: {
        return <Process userId={userId} />;
      }
    }
  };
  return (
    <>
      <StyledContainer>
        {tabData.map((item, index) => (
          <StyledButton
            key={index}
            onClick={() => setSelect(item.id)}
            id={`postRequirement${item.title}`}
            sx={{
              borderColor:
                select !== item.id ? theme.palette.primary.dark : undefined,
              borderTopRightRadius: item.title === "Process" ? 10 : 0,
              borderTopLeftRadius: item.title === "Machine" ? 10 : 0,
              borderBottomLeftRadius: item.title === "Machine" ? 10 : 0,
              borderBottomRightRadius: item.title === "Process" ? 10 : 0,
              color:
                select === item.id
                  ? theme.palette.common.white
                  : theme.palette.primary.dark,
              background:
                select === item.id
                  ? theme.palette.primary.dark
                  : theme.palette.common.white,
            }}
          >
            {item.title}
          </StyledButton>
        ))}
      </StyledContainer>
      {loading ? (
        <StyledBox>
          <CircularProgress size={30} />
        </StyledBox>
      ) : (
        getPostData(select)
      )}
    </>
  );
};

export default memo(JobWork);
