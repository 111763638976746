import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import types from "./type";

const getUserList = (payload: any) => ({
  type: types.getUserList,
  payload,
});

const getCompanyUserList = (payload: any) => ({
  type: types.getCompanyUserList,
  payload,
});

// const getAllUsers = (payload: any) => ({
//   type: types.getAllUsers,
//   payload,
// });

const getAdmins = (payload: any) => ({
  type: types.getAdmins,
  payload,
});

const addPersonUser = (payload: any) => ({
  type: types.addPersonUser,
  payload,
});

const addCompanyUser = (payload: any) => ({
  type: types.addCompanyUser,
  payload,
});

const updateUserList = (payload: any) => ({
  type: types.updateUserList,
  payload,
});

const updateCompanyUserList = (payload: any) => ({
  type: types.updateCompanyUserList,
  payload,
});

const removePersonUser = (payload: any) => ({
  type: types.removePersonUser,
  payload,
});

const removeCompanyUser = (payload: any) => ({
  type: types.removeCompanyUser,
  payload,
});

const removeUserList = () => ({
  type: types.removeUserList,
});

const getAllAdmins = (token: string) => {
  return async (dispatch: any) => {
    try {
      const { data } = await axiosInstance.get(Constant.allAdmin);
      dispatch(getAdmins(data.data));
    } catch (error) {}
  };
};

export {
  getUserList,
  getCompanyUserList,
  getAdmins,
  getAllAdmins,
  removeUserList,
  addPersonUser,
  addCompanyUser,
  updateUserList,
  updateCompanyUserList,
  removePersonUser,
  removeCompanyUser,
};
