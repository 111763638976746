import { useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import {
  checkEmail,
  checkMobileNumber,
  checkString,
} from "@utils/validation/validation";
import { Company, User } from "@services/redux/userList/interface";
import { editBasicDetails } from "@services/redux/addPersonUser/action";
import {
  ErrorProps,
  UserStepOneControllerProps,
  UserStepOneProps,
} from "./userStepOne.interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const userStepOneController = ({
  handleNext,
  userId,
  registerType,
}: UserStepOneProps): UserStepOneControllerProps => {
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [selectZone, setSelectZone] = useState<string>();
  const [otherZone, setOtherZone] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorProps>({
    fullName: null,
    email: null,
    mobileNo: null,
    zone: null,
    otherZone: null,
  });

  const { zones } = useAppSelector((state) => state.zoneReducer);
  const { userList } = useAppSelector((state) => state.userListReducer);
  const { companyUserList } = useAppSelector((state) => state.userListReducer);

  const { editBasicDetailsData } = useAppSelector((state) =>
    registerType === 1
      ? state.editPersonUserReducer
      : state.editCompanyUserReducer
  );

  let editData: User[] | Company[] =
    registerType === 1
      ? userList.filter((item: User) => item._id === userId)
      : companyUserList.filter((item: Company) => item._id === userId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setFullName(editData[0]?.firstName);
    setEmail(editData[0]?.email);
    setMobileNo(editData[0]?.mobileNumber.toString());
    setSelectZone(editData[0]?.zone);
  }, [userId]);

  const saveEditedData = (): void => {
    let temp = { ...editBasicDetailsData };
    temp.firstName = fullName;
    temp.email = email;
    temp.mobileNumber = mobileNo;
    temp.zone = selectZone === "Other" ? otherZone : selectZone;
    dispatch(editBasicDetails(temp));
    handleNext();
  };

  const handleZone = (event: SelectChangeEvent): void => {
    setSelectZone(event.target.value);
    if (event.target.value === "Other") {
      setOtherZone("");
      error.otherZone = null;
      setError({ ...error });
    }
  };

  const validation = (): void => {
    /* Validation for basic user details */
    let isValidate = true;
    if (!fullName?.trim()) {
      isValidate = false;
      error.fullName = validationMessage.validationFirstName;
    } else if (!checkString(fullName)) {
      isValidate = false;
      error.fullName = validationMessage.validationValidFirstName;
    } else {
      error.fullName = null;
    }
    if (!email?.trim()) {
      isValidate = false;
      error.email = validationMessage.validationEmail;
    } else if (!checkEmail(email)) {
      isValidate = false;
      error.email = validationMessage.validationValidEmail;
    } else {
      error.email = null;
    }
    if (!mobileNo?.trim()) {
      isValidate = false;
      error.mobileNo = validationMessage.validationMobileNo;
    } else if (!checkMobileNumber(mobileNo.toString())) {
      isValidate = false;
      error.mobileNo = validationMessage.validationValidMobileNo;
    } else {
      error.mobileNo = null;
    }
    if (!selectZone) {
      isValidate = false;
      error.zone = validationMessage.selectZone;
    } else {
      error.zone = null;
    }
    if (selectZone === "Other")
      if (!otherZone?.trim()) {
        isValidate = false;
        error.otherZone = validationMessage.validationZone;
      } else {
        error.otherZone = null;
      }
    setError({ ...error });
    if (isValidate) {
      saveEditedData();
    }
  };

  return {
    email,
    error,
    fullName,
    handleZone,
    mobileNo,
    setEmail,
    setFullName,
    setMobileNo,
    validation,
    selectZone,
    loading,
    editData,
    setOtherZone,
    zones,
    otherZone,
  };
};

export default userStepOneController;
