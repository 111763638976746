import React from "react";
import { Add } from "@mui/icons-material";
import AddUserSlider from "./addUserSlider/AddUserSlider";
import { Box, useTheme } from "@mui/material";
import { CircularProgress, Download, Table, Text } from "@component";
import { tableCellData } from "./users.const";
import { useAppSelector } from "@services/redux/controller";
import usersController from "./users.controller";
import {
  StyledBox,
  StyledButton,
  StyledContainer,
  StyledProgressContainer,
  StyledSearchInput,
} from "./users.style";

const Users: React.FC = () => {
  const theme = useTheme();

  const user = useAppSelector((state) => state.userReducer);

  const cellData =
    user?.userData?.adminType === 1
      ? tableCellData
      : tableCellData.filter((item) => item.id !== 9);

  const {
    loading,
    openModal,
    handleOpen,
    handleClose,
    filter,
    filterData,
    searchValue,
    userData,
    openDialog,
    handleDialogOpen,
    handleDialogClose,
    index,
    removeUser,
    deleteLoading,
    onExport,
  } = usersController();
  return (
    <StyledContainer>
      <StyledBox>
        <Box flex={1} m={theme.spacing(4)}>
          <Text variant="h4" color={theme.palette.primary.main}>
            Users
          </Text>
        </Box>
        <StyledSearchInput
          placeholder="Type something..."
          value={searchValue}
          onChange={(val) => filter(val?.target.value)}
        />
        <StyledButton
          onClick={() => handleOpen()}
          startIcon={<Add fontSize="large" />}
          id="addUserClick"
        >
          Add User
        </StyledButton>
        {user?.userData?.adminType === 1 && (
          <Download onIconClick={onExport} id="userDownload" />
        )}
        <AddUserSlider
          openDrawer={openModal}
          handleCloseIcon={handleClose}
          userId={index}
        />
      </StyledBox>
      {loading ? (
        <StyledProgressContainer>
          <CircularProgress size={40} color={"primary"} />
        </StyledProgressContainer>
      ) : (
        <Table
          tableCellData={cellData}
          tableData={filterData === null ? userData : filterData}
          navigationTo={"userDetails"}
          isButton
          buttonText="View"
          isDeleteButton={user?.userData?.adminType === 1}
          handleOpenDialog={(id) => {
            handleDialogOpen(id);
          }}
          openDialog={openDialog}
          handleCloseDialog={handleDialogClose}
          handleDeleteDialog={removeUser}
          handleDeleteDialogLoading={deleteLoading}
          search={searchValue}
        />
      )}
    </StyledContainer>
  );
};

export default Users;
