import React, { memo } from "react";
import { CompanyProductsCard } from "@component";
import { Add } from "@mui/icons-material";
import EditProductSlider from "./editProductSlider/EditProductSlider";
import productsController from "./products.controller";
import { ProductsProps } from "./products.interface";
import { StyledContainer, StyledText } from "../process/process.style";
import {
  StyledList,
  StyledListContainer,
} from "@component/usersFromCountries/usersFromCountries.style";
import { StyledFabButton, StyledFabButtonContainer } from "./products.style";

const Products: React.FC<ProductsProps> = ({ userId }) => {
  const {
    productData,
    handleCloseDrawer,
    handleOpenDrawer,
    openDrawer,
    index,
    removeProduct,
    loading,
    productId,
  } = productsController({ userId });
  return (
    <>
      {productData?.length !== 0 ? (
        <StyledListContainer>
          <StyledList>
            {productData?.map((item, index) => (
              <CompanyProductsCard
                item={item}
                key={index}
                onEditIcon={(id) => handleOpenDrawer(id)}
                onDeleteIcon={(id) => removeProduct(id)}
                loading={loading}
                id={productId}
              />
            ))}
          </StyledList>
        </StyledListContainer>
      ) : (
        <StyledContainer>
          <StyledText>Nothing to show here</StyledText>
        </StyledContainer>
      )}
      {openDrawer && (
        <EditProductSlider
          openDrawer={openDrawer}
          handleCloseIcon={handleCloseDrawer}
          productId={index}
          userId={userId}
        />
      )}
      <StyledFabButtonContainer>
        <StyledFabButton
          color="primary"
          onClick={() => handleOpenDrawer()}
          id="addPostRequirementProduct"
        >
          <Add />
        </StyledFabButton>
      </StyledFabButtonContainer>
    </>
  );
};

export default memo(Products);
