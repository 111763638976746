import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import { FormDataProps, SubCategoryProps } from "../expertise.interface";
import { SubcategoryControllerProps } from "./subcategory.interface";
import validationMessage from "@utils/validation/validationMessage";

const subcategoryController = (): SubcategoryControllerProps => {
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [subcategory, setSubcategory] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<string>();
  const [addSpinner, setAddSpinner] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [subcategoryData, setSubcategoryData] = useState<SubCategoryProps[]>(
    []
  );
  const [filterData, setFilterData] = useState<SubCategoryProps[] | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const { state } = useLocation();

  const { expertise, _id } = state?.mainCategory;

  const title: string = `${expertise} - ${state?.list?.expertise}`;

  useEffect(() => {
    getExpertise();
  }, []);

  const getExpertise = async () => {
    /* API call for getting Expertise data */
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(Constant.getExpertise);
      setLoading(false);
      const list = data?.data
        ?.filter((item: any) => item?._id === _id)[0]
        ?.mainSubcategory?.filter(
          (item: any) => item?._id === state?.list?._id
        )[0]
        ?.subcategory?.map((item: SubCategoryProps, index: number) => ({
          _id: item?._id,
          serialNo: index + 1,
          expertise: item?.expertise,
          status: item?.status,
        }));
      setSubcategoryData(list);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleOpen = (item?: any): void => {
    setOpen(true);
    if (item?._id) {
      setIndex(item?._id);
      setSubcategory(item?.expertise);
    }
  };

  const handleClose = (): void => {
    setOpen(false);
    setIndex("");
    setSubcategory("");
    setError("");
  };

  const searchList = (value: string): void => {
    /* Functionality to search expertise */
    let filterData = subcategoryData?.filter((item: SubCategoryProps) => {
      let search = [item?.expertise];
      let regex = search.toString().toLowerCase();
      return regex?.includes(value?.toLowerCase());
    });
    setSearch(value);
    setFilterData(filterData);
  };

  const addSubcategory = async () => {
    /* API call for adding/updating Subcategory */
    try {
      setAddSpinner(true);
      const formData: FormDataProps = {
        type: 3,
        expertiseId: _id,
        mainSubcategoryId: state?.list?._id,
        subcategoryExpertise: subcategory,
      };
      if (index) {
        formData.subcategoryId = index;
      }
      const url = index ? Constant.editExpertise : Constant.addExpertise;
      const { data } = await axiosInstance.post(url, formData);
      setAddSpinner(false);
      getExpertise();
      handleClose();
      setOpenSnackbar(true);
      setSnackbarMessage(data?.message);
    } catch (error: any) {
      setAddSpinner(false);
      setOpenSnackbar(true);
      setSnackbarMessage(error?.data?.message);
    }
  };

  const validation = (): void => {
    let isValid = true;
    if (!subcategory?.trim()) {
      isValid = false;
      setError(validationMessage.emptySubcategory);
    } else {
      setError("");
    }
    if (isValid) {
      addSubcategory();
    }
  };

  const updateStatus = async (id: string, check: boolean) => {
    /* API call for updating expertise status */
    try {
      const formData = {
        type: 3,
        expertiseId: _id,
        mainSubcategoryId: state?.list?._id,
        subcategoryId: id,
        status: check ? 1 : 0,
      };
      const { data } = await axiosInstance.post(
        Constant.updateExpertiseStatus,
        formData
      );
      getExpertise();
      setOpenSnackbar(true);
      setSnackbarMessage(data?.message);
    } catch (error: any) {
      setOpenSnackbar(true);
      setSnackbarMessage(error?.data?.message);
    }
  };

  return {
    handleClose,
    handleOpen,
    open,
    setSubcategory,
    index,
    search,
    subcategory,
    subcategoryData,
    title,
    searchList,
    filterData,
    addSpinner,
    error,
    validation,
    updateStatus,
    loading,
    setOpenSnackbar,
    openSnackbar,
    snackbarMessage,
  };
};

export default subcategoryController;
