import { useEffect, useState } from "react";
import {
  checkEmail,
  checkMobileNumber,
  checkString,
} from "@utils/validation/validation";
import { Company } from "@services/redux/userList/interface";
import {
  CompanyStepTwoControllerProps,
  ErrorProps,
} from "./companyStepTwo.interface";
import { editCompanyDetails } from "@services/redux/addCompanyUser/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import { UserStepOneProps } from "@pages/home/userDetails/editUserModal/userStepOne/userStepOne.interface";
import validationMessage from "@utils/validation/validationMessage";

const companyStepTwoController = ({
  handleNext,
  userId,
}: UserStepOneProps): CompanyStepTwoControllerProps => {
  const [legalFirmName, setLegalFirmName] = useState<string>();
  const [ownerName, setOwnerName] = useState<string>();
  const [companyEmail, setCompanyEmail] = useState<string>();
  const [mobileNo, setMobileNo] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [error, setError] = useState<ErrorProps>({
    legalFirmName: null,
    ownerName: null,
    companyEmail: null,
    mobileNo: null,
    address: null,
  });

  const { companyUserList } = useAppSelector((state) => state.userListReducer);

  const { editCompanyDetailsData } = useAppSelector(
    (state) => state.editCompanyUserReducer
  );

  let editData: Company[] = companyUserList.filter(
    (item: Company) => item._id === userId
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId) {
      setLegalFirmName(editData[0].legalFirmName);
      setOwnerName(editData[0].ownerName);
      setCompanyEmail(editData[0].companyEmail);
      setMobileNo(editData[0].companyMobileNumber?.toString());
      setAddress(editData[0].address);
    }
  }, []);

  const saveEditedData = (): void => {
    let temp = { ...editCompanyDetailsData };
    temp.legalFirmName = legalFirmName;
    temp.ownerName = ownerName;
    temp.companyEmail = companyEmail;
    temp.companyMobileNo = mobileNo;
    temp.address = address;
    dispatch(editCompanyDetails(temp));
    handleNext();
  };

  const validation = (): void => {
    /* Validation for company details */
    let isValidate = true;
    if (legalFirmName) {
      if (!legalFirmName?.trim()) {
        isValidate = false;
        error.legalFirmName = validationMessage.validationLegalFirmName;
      } else {
        error.legalFirmName = null;
      }
    }
    if (ownerName) {
      if (!checkString(ownerName) || !ownerName?.trim()) {
        isValidate = false;
        error.ownerName = validationMessage.validationValidOwnerName;
      } else {
        error.ownerName = null;
      }
    }
    if (companyEmail) {
      if (!checkEmail(companyEmail) || !companyEmail?.trim()) {
        isValidate = false;
        error.companyEmail = validationMessage.validationValidEmail;
      } else {
        error.companyEmail = null;
      }
    }
    if (mobileNo) {
      if (!checkMobileNumber(mobileNo.toString()) || !mobileNo?.trim()) {
        isValidate = false;
        error.mobileNo = validationMessage.validationValidMobileNo;
      } else {
        error.mobileNo = null;
      }
    }
    if (address) {
      if (!address.trim()) {
        isValidate = false;
        error.address = validationMessage.validationAddress;
      } else {
        error.address = null;
      }
    }
    setError({ ...error });
    if (isValidate) {
      saveEditedData();
    }
  };

  return {
    legalFirmName,
    setLegalFirmName,
    ownerName,
    setOwnerName,
    companyEmail,
    setCompanyEmail,
    mobileNo,
    setMobileNo,
    address,
    setAddress,
    validation,
    error,
  };
};

export default companyStepTwoController;
