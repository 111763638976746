import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import { getUserList, removePersonUser } from "@services/redux/userList/action";
import { User } from "@services/redux/userList/interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import { UserDataProps, UsersControllerProps } from "./users.interface";

const usersController = (): UsersControllerProps => {
  const users = useAppSelector((state) => state.userListReducer);
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [index, setIndex] = useState<string>();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<UserDataProps[] | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(
    users.userList?.length > 0 ? false : true
  );

  useEffect(() => {
    onGetAllUsers();
  }, []);

  const userData = useMemo(() => {
    return users?.userList.map((item: User) => ({
      _id: item._id,
      profileImage: item?.profileImage,
      name: `${item?.firstName} ${item?.lastName ?? ""}`,
      email: item?.email,
      mobileNumber: item?.mobileNumber,
      companyName: item?.currentlyWorkingCompanyName,
      createdAt: moment(item?.createdAt).format("DD/MM/YYYY"),
      lastLogin: item?.lastLogin ? moment(item?.lastLogin).format("LLL") : "-",
    }));
  }, [users?.userList]);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";

  const data = useMemo(() => {
    return users?.userList.map((item: User) => ({
      Name: `${item?.firstName} ${item?.lastName ?? ""}`,
      Email: item?.email ?? "-",
      "Mobile Number": item?.mobileNumber,
      "Company Name": item?.currentlyWorkingCompanyName ?? "-",
      Qualification: item?.qualification ?? "-",
      Degree: item?.degree ?? "-",
      "Work As": item?.workAs === 0 ? "Full Time" : "Part Time",
      "Expertise and Experience": item?.qualificationData
        .map((obj) => {
          return `${obj?.expertise ? `${obj?.expertise}: ` : ""}${
            obj?.experience ?? null
          }`;
        })
        .join("\n"),
      "Created On": moment(item?.createdAt).format("DD/MM/YYYY"),
      "Last Login": item?.lastLogin
        ? moment(item?.lastLogin).format("LLL")
        : "-",
    }));
  }, [users?.userList]);

  const onExport = (): void => {
    saveDataAsXL(data, `${Date.now()}_UserList`);
  };

  const saveDataAsXL = (csvData: any, fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    delete ws["A"];
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const filter = (value: string): void => {
    /* Functionality to search users */
    let filterData = userData.filter((item: UserDataProps) => {
      let search = [
        item?.name,
        item?.email,
        item?.mobileNumber,
        item?.lastLogin,
      ];
      let regex = search.toString().toLowerCase();
      return regex?.includes(value?.toLowerCase());
    });
    setSearchValue(value);
    setFilterData(filterData);
  };

  const handleOpen = (item?: any): void => {
    /* Open add/edit user slider */
    setOpenModal(true);
    setIndex(item?._id);
  };

  const handleClose = (): void => {
    /* Close add/edit user slider */
    setOpenModal(false);
  };

  const handleDialogOpen = (id: string): void => {
    /* Open delete user dialog */
    setOpenDialog(true);
    setIndex(id);
  };

  const handleDialogClose = (): void => {
    /* Close delete user dialog */
    setOpenDialog(false);
  };

  const onGetAllUsers = async () => {
    /* API call for getting all users */
    try {
      const { data } = await axiosInstance.post(Constant.allUsers, {
        registerType: 1,
      });
      dispatch(getUserList(data.data));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const removeUser = async () => {
    /* API call for removing person user */
    try {
      setDeleteLoading(true);
      const { data } = await axiosInstance.post(Constant.removeUser, {
        id: index,
      });
      setDeleteLoading(false);
      handleDialogClose();
      dispatch(removePersonUser(index));
    } catch (error) {
      setDeleteLoading(false);
    }
  };

  return {
    loading,
    openModal,
    handleOpen,
    handleClose,
    filter,
    filterData,
    searchValue,
    userData,
    openDialog,
    handleDialogOpen,
    handleDialogClose,
    index,
    removeUser,
    deleteLoading,
    onExport,
    saveDataAsXL,
  };
};

export default usersController;
