import { useEffect, useMemo, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import { getZonesData, updateZones } from "@services/redux/zone/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import { ZoneControllerProps, ZoneDataProps } from "./zone.interface";

const zoneController = (): ZoneControllerProps => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [index, setIndex] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<ZoneDataProps[] | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [zoneData, setZoneData] = useState<ZoneDataProps[]>([]);
  const { zones } = useAppSelector((state) => state.zoneReducer);
  const dispatch = useAppDispatch();

  const zone = useMemo(() => {
    return zoneData
      .map((item) => ({
        _id: item._id,
        zone: item.zone,
        status: item.status,
      }))
      .reverse();
  }, [zoneData]);

  useEffect(() => {
    setLoading(false);
  }, [zones]);

  useEffect(() => {
    if (zones?.length === 0) {
      setLoading(true);
    }
    dispatch(getZonesData() as any);
  }, []);

  useEffect(() => {
    setZoneData(zones);
  }, [zones]);

  const filter = (value: string): void => {
    /* Functionality to search from data */
    let filterData = zone?.filter((item: ZoneDataProps) => {
      let search = [item?.zone];
      let regex = search.toString().toLowerCase();
      return regex.includes(value.toLowerCase());
    });
    setSearchValue(value);
    setFilterData(filterData);
  };

  const editZoneStatus = async (zoneId: string | undefined, check: boolean) => {
    /* API call for editing zone status */
    try {
      const formData = {
        status: check ? "1" : "0",
        id: zoneId,
      };
      const { data } = await axiosInstance.post(
        Constant.zoneStatusUpdate,
        formData
      );
      dispatch(updateZones(data.data));
    } catch (error: any) {}
  };

  const handleOpen = (item?: any): void => {
    setOpenDrawer(true);
    setIndex(item?._id);
  };

  const handleClose = (): void => {
    setOpenDrawer(false);
  };

  return {
    openDrawer,
    handleClose,
    handleOpen,
    index,
    loading,
    zone,
    editZoneStatus,
    filter,
    filterData,
    searchValue,
  };
};

export default zoneController;
