import { Box, styled } from "@mui/material";
import { Button, Selection, Text } from "@component";
import Input from "@component/input/Input";

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  alignSelf: "center",
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(18),
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  width: "100%",
  alignSelf: "center",
  padding: theme.spacing(3, 0),
  input: {
    color: theme.palette.common.black,
    height: 10,
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "fixed",
  bottom: 0,
  width: "31vw",
  alignSelf: "center",
  background: theme.palette.common.white,
  padding: theme.spacing(6, 0),
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(12),
  width: "30%",
  height: 40,
  alignSelf: "center",
  [theme.breakpoints.down("sm")]: {
    width: "45%",
  },
}));

export const StyledErrorText = styled(Text)(({ theme }) => ({
  fontSize: 12,
  alignSelf: "flex-start",
  color: "red",
  marginLeft: theme.spacing(8),
  marginTop: -7,
  marginBottom: 5,
}));

export const StyledSelection = styled(Selection)(({ theme }) => ({
  borderWidth: 0,
  marginBottom: theme.spacing(4),
  ".MuiSelect-outlined": {
    color: theme.palette.grey[900],
  },
}));
