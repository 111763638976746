import { useEffect, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  PortfolioProductDataProps,
  PortfolioProductsControllerProps,
  PortfolioProductsProps,
} from "./portfolioProduct.interface";
import { removePortfolioProductData } from "@services/redux/companyPortfolio/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";

const portfolioProductController = ({
  userId,
}: PortfolioProductsProps): PortfolioProductsControllerProps => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [index, setIndex] = useState<string | undefined>(undefined);
  const [productId, setProductId] = useState<string>();
  const { portfolioProduct } = useAppSelector(
    (state) => state.companyPortfolioReducer
  );
  const [productData, setProductData] = useState<PortfolioProductDataProps[]>([
    {
      _id: undefined,
      productName: null,
      description: null,
      fileUrl: null,
      tags: null,
    },
  ]);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setProductData(
      portfolioProduct.map((item: PortfolioProductDataProps) => ({
        _id: item._id,
        productName: item.productName,
        description: item.description,
        fileUrl: item.fileUrl,
        tags: item.tags,
      }))
    );
  }, [portfolioProduct]);

  const handleCloseDrawer = (): void => {
    setOpenDrawer(false);
  };

  const handleOpenDrawer = (id?: string): void => {
    setOpenDrawer(true);
    setIndex(id);
  };

  const removeProduct = async (productId?: string) => {
    /* API call for removing product */
    setProductId(productId);
    const formData = {
      userId: userId,
      type: "0",
      productId: productId,
    };
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.removePortfolioProductJobWork,
        formData
      );
      setLoading(false);
      dispatch(removePortfolioProductData(data.data));
    } catch (error) {
      setLoading(false);
    }
  };

  return {
    productData,
    handleCloseDrawer,
    openDrawer,
    handleOpenDrawer,
    index,
    removeProduct,
    loading,
    productId,
  };
};

export default portfolioProductController;
