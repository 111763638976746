import { Box, styled } from "@mui/material";
import Text from "@component/text/Text";

export const StyledContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  padding: theme.spacing(10, 10),
  width: "50%",
  wordBreak: "break-word",
  marginTop: theme.spacing(10),
  marginRight: theme.spacing(14),
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

export const StyledUserProfile = styled("img")(({ theme }) => ({
  height: 120,
  width: 120,
  borderRadius: theme.spacing(4),
  marginRight: theme.spacing(10),
}));

export const StyledProfileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginBottom: theme.spacing(8),
}));

export const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const StyledUserNameText = styled(StyledText)(({ theme }) => ({
  fontSize: 18,
  fontWeight: "bold",
}));

export const StyledTextWithBackground = styled(Text)(({ theme }) => ({
  color: theme.palette.common.white,
  background: theme.palette.secondary.main,
  textAlign: "center",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(10),
  marginTop: theme.spacing(4),
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "60%",
  },
}));

export const StyledDetailsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  margin: theme.spacing(4, 0),
}));

export const StyledTitleText = styled(StyledText)(({ theme }) => ({
  fontSize: 15,
  fontWeight: "bold",
  width: "45%",
  marginRight: theme.spacing(14),
}));

export const StyledNameContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));
