import React, { memo } from "react";
import { Box, IconButton, MenuItem, useTheme } from "@mui/material";
import { RemoveCircleOutline } from "@mui/icons-material";
import { expertiseData } from "@pages/home/users/addUserSlider/userProfileStepOne/userProfileStepOne.const";
import { MultipleSelectionDropDown } from "@component";
import { UserStepOneProps } from "../userStepOne/userStepOne.interface";
import userStepTwoController from "./userStepTwo.controller";
import {
  StyledAddButton,
  StyledErrorText,
  StyledErrorTextTwo,
  StyledExperienceInput,
  StyledInputContainer,
  StyledList,
} from "./userStepTwo.style";
import {
  StyledBox,
  StyledButton,
  StyledContainer,
  StyledInput,
  StyledSelection,
} from "../userStepOne/userStepOne.style";
import { StyledButtonContainer } from "@pages/home/companyDetails/editCompanyModal/companyStepThree/companyStepThree.style";

const UserStepTwo: React.FC<UserStepOneProps> = ({ handleNext, userId }) => {
  const {
    addMoreExpertiseHandler,
    editData,
    error,
    expertiseAndExperience,
    expertiseAndExperienceInputHandler,
    handleSelectionChange,
    onMinusIcon,
    placeholderText,
    qualificationsData,
    selectExpertise,
    setCompanyName,
    validation,
    companyName,
    selectQualification,
  } = userStepTwoController({ handleNext, userId });
  const theme = useTheme();
  return (
    <>
      <StyledList>
        <StyledContainer>
          <StyledBox>
            <StyledSelection
              labelname={
                editData[0]?.qualification
                  ? editData[0]?.qualification
                  : "Qualification"
              }
              inputlabelstyle={{
                "&.Mui-focused": {
                  color: theme.palette.grey[900],
                },
              }}
              value={selectQualification}
              onChange={(e: any) => handleSelectionChange(e)}
              size={"small"}
              MenuProps={{
                PaperProps: { sx: { maxHeight: "33%" } },
              }}
            >
              {qualificationsData.map((item) => (
                <MenuItem key={item._id} value={item?.qualification}>
                  {item?.qualification}
                </MenuItem>
              ))}
            </StyledSelection>
            {expertiseAndExperience.map((input, index) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: 2,
                }}
                key={index}
              >
                <StyledInputContainer>
                  <Box
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <MultipleSelectionDropDown
                      data={expertiseData}
                      placeholder={placeholderText(input)}
                      onSelectValue={(
                        details: any,
                        mainCategory: any,
                        subMainCategory: any
                      ) => {
                        selectExpertise(
                          index,
                          details,
                          mainCategory,
                          subMainCategory
                        );
                      }}
                      values={input?.selectedCategory}
                    />
                    <StyledErrorText>{input.expertiseError}</StyledErrorText>
                  </Box>
                  <Box>
                    <StyledExperienceInput
                      variant="outlined"
                      placeholder="Experience(years)"
                      InputLabelProps={{
                        style: { color: theme.palette.common.black },
                      }}
                      value={input.experience}
                      onChange={(val) =>
                        expertiseAndExperienceInputHandler(
                          val?.target?.value,
                          index,
                          "experience"
                        )
                      }
                    />
                    <StyledErrorTextTwo sx={{ marginLeft: 7 }}>
                      {input.experienceError}
                    </StyledErrorTextTwo>
                  </Box>
                </StyledInputContainer>
                {index !== 0 && expertiseAndExperience?.length > 1 && (
                  <IconButton onClick={() => onMinusIcon(input?._id ?? index)}>
                    <RemoveCircleOutline />
                  </IconButton>
                )}
              </Box>
            ))}
            <StyledAddButton onClick={addMoreExpertiseHandler}>
              Add More
            </StyledAddButton>
            <StyledInput
              variant="outlined"
              placeholder="Currently Working(Company Name)"
              InputLabelProps={{ style: { color: theme.palette.common.black } }}
              value={companyName}
              onChange={(val) => setCompanyName(val?.target?.value)}
              helperText={error.companyName}
            />
          </StyledBox>
        </StyledContainer>
      </StyledList>
      <StyledButtonContainer>
        <StyledButton onClick={validation}>Next</StyledButton>
      </StyledButtonContainer>
    </>
  );
};

export default memo(UserStepTwo);
