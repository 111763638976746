import { useEffect, useState } from "react";
import moment from "moment";
import {
  AllUsersControllerProps,
  AllUsersDataProps,
} from "./allUsers.interface";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import usersController from "../users/users.controller";

const allUsersController = (): AllUsersControllerProps => {
  const { saveDataAsXL } = usersController();
  const [allUser, setAllUsers] = useState<AllUsersDataProps[]>([]);
  const [loading, setLoading] = useState(allUser?.length > 0 ? false : true);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filterData, setFilterData] = useState<AllUsersDataProps[]>();

  useEffect(() => {
    getAllUsersData();
  }, []);

  // const allUsersData: allUsersDataProps[] = useMemo(() => {
  //   return allUser?.map((item: any) => ({
  //     _id: item._id,
  //     profileImage: item.profileImage,
  //     name: `${item?.firstName} ${item?.lastName ?? ""}`,
  //     userType: item.registerType === 0 ? "Company" : "Person",
  //     email: item.email,
  //     mobileNumber: item.mobileNumber,
  //     companyName: item.currentlyWorkingCompanyName ?? item.legalFirmName,
  //   }));
  // }, [allUser]);

  const data = allUser?.map((item: any) => ({
    Name: item?.name,
    Email: item?.email?.length ? item?.email : "-",
    "Mobile No.": item?.mobileNumber,
    "Company Name": item?.companyName ?? "-",
    "User Type": item?.userType,
    "Created On": item?.createdAt ?? "-",
    "Last Login": item?.lastLogin ?? "-",
  }));

  const onExport = (): void => {
    saveDataAsXL && saveDataAsXL(data, `${Date.now()}_AllUserList`);
  };

  const filter = (value: string): void => {
    /* Functionality to search users */
    let filterData = allUser.filter((item: AllUsersDataProps) => {
      let search = [item?.name, item?.email, item?.mobileNumber];
      let regex = search.toString().toLowerCase();
      return regex.includes(value.toLowerCase());
    });
    setSearchValue(value);
    setFilterData(filterData);
  };

  const getAllUsersData = async () => {
    /* API call for getting all Users data */
    try {
      const { data } = await axiosInstance.post(Constant.allUsers, {
        registerType: 2,
      });
      setAllUsers(
        data?.data?.map((item: any) => ({
          _id: item?._id,
          profileImage: item?.profileImage,
          name: `${item?.firstName} ${item?.lastName ?? ""}`,
          userType: item?.registerType === 0 ? "Company" : "Person",
          email: item?.email,
          mobileNumber: item?.mobileNumber,
          companyName: item?.currentlyWorkingCompanyName ?? item?.legalFirmName,
          createdAt: moment(item?.createdAt).format("DD/MM/YYYY"),
          lastLogin: item?.lastLogin
            ? moment(item?.lastLogin).format("LLL")
            : "-",
        }))
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return { loading, searchValue, allUser, filterData, filter, onExport };
};

export default allUsersController;
