import { memo } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { image } from "@assets";
import { Text } from "@component";
import { useAppSelector } from "@services/redux/controller";
import {
  StyledButton,
  StyledContainer,
  StyledUserProfile,
} from "./drawerUserProfile.style";

const DrawerUserProfile: React.FC = () => {
  const { userData } = useAppSelector((state) => state.userReducer);
  const navigation = useNavigate();
  return (
    <StyledContainer>
      <StyledUserProfile src={image.userProfile} />
      <Box ml={12} width="60%">
        <Text variant="h6" sx={{ wordBreak: "break-word", width: "80%" }}>
          {`${userData?.firstName} ${userData?.lastName}`}
        </Text>
        <StyledButton
          variant="text"
          onClick={() => navigation("/drawer/appBar/account")}
          id="viewProfile"
        >
          View Profile
        </StyledButton>
      </Box>
    </StyledContainer>
  );
};

export default memo(DrawerUserProfile);
