import React, { memo } from "react";
import { IconButton, useTheme } from "@mui/material";
import { Delete } from "@mui/icons-material";
import editPortfolioProductController from "./editPortfolioProduct.controller";
import { EditPortfolioProductProps } from "./editPortfolioProduct.interface";
import { Slider, SnackBar } from "@component";
import {
  StyledButton,
  StyledButtonContainer,
  StyledChooseFileContainer,
  StyledContainer,
  StyledErrorText,
  StyledFileInput,
  StyledFileText,
  StyledInput,
  StyledLabel,
  StyledText,
  StyledChooseButton,
} from "./editPortfolioProduct.style";

const EditPortfolioProduct: React.FC<EditPortfolioProductProps> = ({
  openDrawer,
  handleCloseIcon,
  productId,
  userId,
  type,
  data,
}) => {
  const theme = useTheme();
  const {
    setDescription,
    setProductName,
    description,
    productName,
    removeFile,
    selectedFile,
    error,
    selectedFileError,
    validation,
    handleProfileImage,
    loading,
    openSnackBar,
    snackBarMessage,
    handleCloseSnackBar,
    onDeleteIcon,
  } = editPortfolioProductController({
    handleCloseIcon,
    openDrawer,
    productId,
    userId,
    type,
    data,
  });
  return (
    <Slider
      openDrawer={openDrawer}
      handleCloseIcon={() => {
        handleCloseIcon();
      }}
      title={productId ? "Edit Product" : "Add Product"}
    >
      <StyledContainer>
        <StyledText>Product Name</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="Product Name"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={productName}
          onChange={(val) => setProductName(val?.target?.value)}
          helperText={error.productNameError}
        />
        <StyledText>Tags</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="Tags"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={productName}
        />
        <StyledText>Description</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="Description"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={description}
          onChange={(val) => setDescription(val?.target?.value)}
        />
        {/* <StyledText>No. of Product</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="No. of Product"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={noOfProduct}
          onChange={(val) => setNoOfProduct(val?.target?.value)}
          helperText={error.noOfProductError}
        /> */}
        <StyledText>Upload (Photos, videos, catalogue)</StyledText>
        <StyledFileInput
          id="selectPortfolio"
          type="file"
          onChange={handleProfileImage}
        />
        <StyledChooseFileContainer>
          <StyledLabel htmlFor="selectPortfolio">
            <StyledChooseButton>
              {selectedFile ? "Selected file" : "Choose file"}
            </StyledChooseButton>
          </StyledLabel>
          {selectedFile && (
            <StyledFileText>
              {selectedFile.name ?? selectedFile}
              <IconButton onClick={onDeleteIcon}>
                <Delete color="secondary" />
              </IconButton>
            </StyledFileText>
          )}
        </StyledChooseFileContainer>
        <StyledErrorText>{selectedFileError}</StyledErrorText>
        <SnackBar
          open={openSnackBar}
          onClose={handleCloseSnackBar}
          onCloseClick={handleCloseSnackBar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          message={snackBarMessage}
        />
        <StyledButtonContainer>
          <StyledButton
            loading={loading}
            onClick={validation}
            progressColor="info"
          >
            {productId ? "Edit" : "Add"}
          </StyledButton>
        </StyledButtonContainer>
      </StyledContainer>
    </Slider>
  );
};

export default memo(EditPortfolioProduct);
