import { memo } from "react";
import { MenuItem, useTheme } from "@mui/material";
import { AddCompanyUserProps } from "./addCompanyUser.interface";
import addUserController from "./addCompanyUser.controller";
import {
  StyledButton,
  StyledButtonContainer,
  StyledContainer,
  StyledErrorText,
  StyledInput,
} from "./addCompanyUser.style";
import { StyledSelection } from "@pages/home/users/addUserSlider/userProfileStepOne/userProfileStepOne.style";

const AddCompanyUser: React.FC<AddCompanyUserProps> = ({
  handleNextButton,
  companyUserId,
}) => {
  const theme = useTheme();
  const {
    firstName,
    setFirstName,
    email,
    setEmail,
    mobileNo,
    setMobileNo,
    password,
    setPassword,
    error,
    validation,
    loading,
    selectZone,
    handleZone,
    zones,
    editData,
    setOtherZone,
    otherZone,
  } = addUserController({ handleNextButton, companyUserId });
  return (
    <StyledContainer sx={{ mt: companyUserId && theme.spacing(10) }}>
      <StyledInput
        variant="outlined"
        placeholder="Full Name"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={firstName}
        onChange={(val) => setFirstName(val?.target?.value)}
        helperText={error.firstName}
      />
      <StyledInput
        variant="outlined"
        placeholder="Email"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={email}
        onChange={(val) => setEmail(val?.target?.value)}
        helperText={error.email}
      />
      <StyledInput
        variant="outlined"
        placeholder="Mobile Number"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={mobileNo}
        onChange={(val) => setMobileNo(val.target.value.slice(0, 10))}
        helperText={error.mobileNo}
        sx={{ mb: 3 }}
      />
      <StyledSelection
        labelname={editData[0]?.zone ? editData[0]?.zone : "Zone"}
        inputlabelstyle={{
          "&.Mui-focused": {
            color: theme.palette.grey[900],
          },
        }}
        value={selectZone}
        onChange={(e: any) => handleZone(e)}
        MenuProps={{
          PaperProps: { sx: { maxHeight: "33%" } },
        }}
      >
        {zones.map((item) => (
          <MenuItem key={item._id} value={item?.zone}>
            {item?.zone}
          </MenuItem>
        ))}
      </StyledSelection>
      <StyledErrorText>{error.zone}</StyledErrorText>
      {selectZone === "Other" && (
        <StyledInput
          variant="outlined"
          placeholder="Other Zone"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={otherZone}
          onChange={(val) => setOtherZone(val?.target?.value)}
          helperText={error.otherZone}
        />
      )}
      {!companyUserId && (
        <StyledInput
          variant="outlined"
          placeholder="Password"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={password}
          onChange={(val) => setPassword(val?.target?.value)}
          helperText={error.password}
          showPasswordIcon
        />
      )}
      <StyledButtonContainer>
        <StyledButton
          onClick={() => {
            validation();
            handleNextButton;
          }}
          loading={loading}
        >
          Next
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default memo(AddCompanyUser);
