import { Button, Input, Text } from "@component";
import { Box, List, styled } from "@mui/material";

export const StyledAddButton = styled(Button)(({ theme }) => ({
  width: "30%",
  height: 40,
  margin: theme.spacing(6, 0),
  alignSelf: "center",
  [theme.breakpoints.down("md")]: {
    width: "40%",
  },
}));

export const StyledInputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(5),
  alignItems: "flex-start",
}));

export const StyledErrorText = styled(Text)(({ theme }) => ({
  fontSize: 13,
  alignSelf: "flex-start",
  color: "red",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "17vw",
  [theme.breakpoints.down("sm")]: {
    width: "35vw",
  },
}));

export const StyledExperienceInput = styled(Input)(({ theme }) => ({
  padding: 0,
  marginLeft: theme.spacing(8),
  width: "9vw",
  input: {
    color: theme.palette.common.black,
    height: 8,
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
  [theme.breakpoints.down("sm")]: {
    width: "25vw",
  },
}));

export const StyledErrorTextTwo = styled(Text)(({ theme }) => ({
  fontSize: 13,
  alignSelf: "flex-start",
  color: "red",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "10vw",
  [theme.breakpoints.down("sm")]: {
    width: "25vw",
  },
}));

export const StyledList = styled(List)(({ theme }) => ({
  height: "68%",
  overflowY: "auto",
}));
