import React, { memo } from "react";
import { Grid, useTheme } from "@mui/material";
import allCompanyDetailsController from "./allCompanyDetails.controller";
import { AllCompanyDetailsProps } from "./allCompanyDetails.interface";
import { CircularProgress } from "@component";
import JobWork from "./jobWork/JobWork";
import Products from "./products/Products";
import SettingsBar from "@component/settingsBar/SettingsBar";
import { tabData } from "./allCompanyDetails.const";
import Vacancy from "./vacancy/Vacancy";
import { StyledBox, StyledContainer } from "./allCompanyDetails.style";

const AllCompanyDetails: React.FC<AllCompanyDetailsProps> = ({
  loading,
  userId,
}) => {
  const { select, setSelect } = allCompanyDetailsController();
  const theme = useTheme();

  const getPostData = (select: number) => {
    switch (select) {
      case 1: {
        return <Products userId={userId} />;
      }
      case 2: {
        return <JobWork userId={userId} loading={loading} />;
      }
      case 3: {
        return <Vacancy userId={userId} />;
      }
    }
  };
  return (
    <StyledContainer boxShadow={3}>
      <Grid container spacing={4}>
        {tabData.map((item, index) => (
          <Grid item xs={6} sm={6} lg={4} key={item.id}>
            <SettingsBar
              item={item}
              index={index}
              onClick={() => setSelect(item.id)}
              id={item.title}
              sx={{
                background:
                  select === item.id
                    ? theme.palette.primary.main
                    : theme.palette.primary.light,
              }}
            />
          </Grid>
        ))}
      </Grid>
      {loading ? (
        <StyledBox>
          <CircularProgress size={30} />
        </StyledBox>
      ) : (
        getPostData(select)
      )}
    </StyledContainer>
  );
};

export default memo(AllCompanyDetails);
