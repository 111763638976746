import React, { memo } from "react";
import { CircularProgress } from "@mui/material";
import { StyledProgressContainer } from "./spinner.style";

const Spinner: React.FC = () => {
  return (
    <StyledProgressContainer>
      <CircularProgress size={40} color={"primary"} />
    </StyledProgressContainer>
  );
};

export default memo(Spinner);
