import React, { memo } from "react";
import { useTheme } from "@mui/material";
import addNotificationController from "./addNotification.controller";
import { AddNotificationProps } from "./addNotification.interface";
import { SnackBar } from "@component";
import {
  StyledInput,
  StyledButtonContainer,
  StyledButton,
  StyledModal,
  StyledModalContainer,
  StyledTitle,
  InputTitle,
  StyledInputContainer,
} from "./addNotification.style";

const AddNotification: React.FC<AddNotificationProps> = ({
  open,
  onClose,
  getUpdate,
}) => {
  const {
    loading,
    title,
    description,
    error,
    validation,
    onHandleClose,
    handleSnackbar,
    snackbar,
    message,
    handleTitle,
    handleDescription,
  } = addNotificationController({ open, onClose, getUpdate });

  const theme = useTheme();
  return (
    <StyledModal
      open={open}
      onClose={onHandleClose}
      aria-labelledby="user-edit-modal"
      aria-describedby="user-edit-modal-description"
    >
      <StyledModalContainer>
        <StyledTitle>Add Notification</StyledTitle>
        <StyledInputContainer>
          <InputTitle>Title</InputTitle>
          <StyledInput
            variant="outlined"
            placeholder="Title"
            InputLabelProps={{ style: { color: theme.palette.common.black } }}
            value={title}
            onChange={handleTitle}
            helperText={error.title}
            sx={{ marginBottom: error.title ? 0 : theme.spacing(5) }}
          />
          <InputTitle>Description</InputTitle>
          <StyledInput
            variant="outlined"
            placeholder="Description"
            multiline
            minRows={4}
            maxRows={4}
            InputLabelProps={{ style: { color: theme.palette.common.black } }}
            value={description}
            onChange={handleDescription}
            helperText={error.description}
          />
        </StyledInputContainer>
        <StyledButtonContainer>
          <StyledButton
            id="cancelNotification"
            variant="contained"
            onClick={onHandleClose}
          >
            Cancel
          </StyledButton>
          <StyledButton
            id="addNotification"
            variant="contained"
            onClick={validation}
            loading={loading}
            progressColor="info"
          >
            Add
          </StyledButton>
        </StyledButtonContainer>
        <SnackBar
          open={snackbar}
          onClose={handleSnackbar}
          onCloseClick={handleSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          message={message}
          autoHideDuration={6000}
        />
      </StyledModalContainer>
    </StyledModal>
  );
};

export default memo(AddNotification);
