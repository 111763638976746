import { useEffect, useMemo, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  addProductData,
  editProductData,
} from "@services/redux/allCompanyDetails/action";
import {
  EditProductSliderControllerProps,
  EditProductSliderProps,
  ErrorProps,
} from "./editProductSlider.interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const editProductSliderController = ({
  handleCloseIcon,
  productId,
  userId,
  type,
  data,
}: EditProductSliderProps): EditProductSliderControllerProps => {
  const dispatch = useAppDispatch();
  const { product } = useAppSelector((state) => state.allCompanyDetailsReducer);

  const productData =
    type === "master"
      ? data
      : useMemo(
          () =>
            product?.filter((item: any) => {
              return item._id === productId;
            })[0],
          [product]
        );

  const [productName, setProductName] = useState<string>();
  const [description, setDescription] = useState<string>();
  // const [noOfProduct, setNoOfProduct] = useState<string>(
  //   productData[0]?.noOfProducts
  // );
  const [selectedFile, setSelectedFile] = useState<Blob | string | any>();
  const [removeFile, setRemoveFile] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorProps>({
    productNameError: null,
    noOfProductError: null,
  });
  const [selectedFileError, setSelectedFileError] = useState<string | null>(
    null
  );
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  useEffect(() => {
    if (productId) {
      setProductName(
        type === "master" ? productData?.product : productData?.productName
      );
      setDescription(
        productData?.description?.length ? productData?.description : ""
      );
      setSelectedFile(
        type === "master" ? productData?.profileImage : productData?.fileUrl
      );
    }
  }, [productData]);

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const handleProfileImage = (e: any): void => {
    /* Functionality for uploading documents */
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 200000000) {
        setSelectedFile("");
        setSelectedFileError(
          "The uploaded file exceeds the maximum allowed size of 200MB"
        );
      } else {
        setSelectedFile(selectedFile);
        setSelectedFileError("");
      }
    }
  };

  const validation = (): void => {
    /* Validation for edit product form */
    let isValidate = true;
    if (!productName) {
      isValidate = false;
      error.productNameError = validationMessage.productNameError;
    } else {
      error.productNameError = "";
    }
    // if (!noOfProduct) {
    //   isValidate = false;
    //   error.noOfProductError = validationMessage.validationNoOfProductError;
    // } else if (!checkNumeric(noOfProduct)) {
    //   isValidate = false;
    //   error.noOfProductError =
    //     validationMessage.validationValidNoOfProductError;
    // } else {
    //   error.noOfProductError = "";
    // }
    setError({ ...error });
    if (isValidate) {
      if (productId) {
        editProduct();
      } else {
        addProduct();
      }
    }
  };

  const editProduct = async () => {
    /* API call for editing product */
    const formData = new FormData();
    if (typeof userId === "string") {
      formData.append("userId", userId);
    }
    if (typeof productId === "string") {
      formData.append("productId", productId);
    }
    if (productName) {
      formData.append("productName", productName);
    }
    // formData.append("noOfProducts", noOfProduct);
    if (description) {
      formData.append("description", description);
    }
    if (selectedFile) {
      formData.append("files", selectedFile);
    }
    if (removeFile) {
      formData.append("removeUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.editProductAdmin,
        formData
      );
      setLoading(false);
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
      dispatch(editProductData(data?.data));
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const addProduct = async () => {
    /* API call for adding product */
    const formData = new FormData();
    if (typeof userId === "string") {
      formData.append("userId", userId);
    }
    if (productName) {
      formData.append("productName", productName);
    }
    // formData.append("noOfProducts", noOfProduct);
    if (description) {
      formData.append("description", description);
    }
    formData.append("files", selectedFile);
    if (removeFile) {
      formData.append("removeUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(Constant.addProduct, formData);
      setLoading(false);
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
      setProductName("");
      setDescription("");
      setSelectedFile("");
      dispatch(addProductData(data?.data));
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const onDeleteIcon = (): void => {
    if (selectedFile) {
      setRemoveFile(productData[0]?.fileUrl);
    }
    setSelectedFile("");
  };

  return {
    description,
    productName,
    removeFile,
    selectedFile,
    setDescription,
    setProductName,
    error,
    validation,
    handleProfileImage,
    selectedFileError,
    loading,
    openSnackBar,
    snackBarMessage,
    handleCloseSnackBar,
    onDeleteIcon,
  };
};

export default editProductSliderController;
