import { useEffect, useState, useMemo } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  ProductControllerProps,
  ProductDetailProps,
  ProductListProps,
} from "./product.interface";

const productController = (): ProductControllerProps => {
  const [productDetail, setProductDetail] = useState<ProductDetailProps>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteSpinner, setDeleteSpinner] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [productList, setProductList] = useState<any>();
  const [searchList, setSearchList] = useState<ProductListProps[] | null>(null);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [productId, setProductId] = useState<string>();
  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    getProducts();
  }, [search, currentPage, rowsPerPage]);

  const handleOpenDelete = (id: string, userId?: string): void => {
    setProductId(id);
    setUserId(userId);
    setDeleteDialog(true);
  };

  const handleCloseDelete = (): void => {
    setDeleteDialog(false);
  };

  const handleCloseEdit = (): void => {
    setEditModal(false);
  };

  const handleEdit = (item: any): void => {
    setEditModal(true);
    setProductDetail(item);
  };

  const handleSearch = (value: string): void => {
    /* Product search functionality */
    setSearch(value);
    // let filterData = productList.filter((item: ProductListProps) => {
    //   let search = [item?.product];
    //   let regex = search.toString().toLowerCase();
    //   return regex?.includes(value?.toLowerCase());
    // });
    // setSearchList(filterData);
  };

  const getProducts = async () => {
    /* API call for getting product list */
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(Constant.getPortoflioProduct, {
        params: {
          page: currentPage + 1,
          // search,
          perPage: rowsPerPage,
        },
      });

      const serialNumber = (data?.data?.currentPage - 1) * rowsPerPage + 1;
      let temp: ProductListProps[] = data?.data?.Products?.map(
        (item: ProductListProps, index: number) => {
          return {
            id: serialNumber + index,
            _id: item?._id,
            profileImage: item?.fileUrl,
            product: item?.productName,
            company: item?.createrId?.legalFirmName,
            user_id: item?.createrId?._id,
            tags: item?.tags,
            description: item?.description,
          };
        }
      );
      setProductList(temp);
      setTotalDataCount(data?.data?.totalCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteProduct = async () => {
    /* API call for removing product */
    setDeleteSpinner(true);
    const formData = {
      userId: userId,
      type: "0",
      productId: productId,
    };
    try {
      const { data } = await axiosInstance.post(
        Constant.removePortfolioProductJobWork,
        formData
      );
      setDeleteSpinner(false);
      handleCloseDelete();
    } catch (error) {
      setDeleteSpinner(false);
    }
  };

  return {
    handleSearch,
    loading,
    productList,
    search,
    searchList,
    deleteSpinner,
    handleCloseDelete,
    handleOpenDelete,
    deleteDialog,
    handleEdit,
    deleteProduct,
    editModal,
    handleCloseEdit,
    productDetail,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    totalDataCount,
  };
};

export default productController;
