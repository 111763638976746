import React, { memo } from "react";
import { Box } from "@mui/material";
import { CompanyVacancyCardProps } from "./companyVacancyCard.interface";
import { Constant } from "@services/constant";
import { Delete, Edit } from "@mui/icons-material";
import { image } from "@assets";
import {
  StyledCircularProgress,
  StyledContainer,
  StyledIconButton,
  StyledIconContainer,
  StyledPlaceholder,
  StyledText,
} from "../companyMachineProcessCard/companyMachineProcessCard.style";

const CompanyVacancyCard: React.FC<CompanyVacancyCardProps> = ({
  item,
  onEditIcon,
  onDeleteIcon,
  loading,
  id,
}) => {
  return (
    <StyledContainer>
      <StyledPlaceholder src={image.bsetuLogo} />
      <Box flex={0.9}>
        <StyledText variant="h6">{item.qualification}</StyledText>
        <StyledText>
          {item.expertise}, {item.experience}
        </StyledText>
        <StyledText>{item.description}</StyledText>
      </Box>
      <StyledIconContainer>
        <StyledIconButton
          onClick={() => onEditIcon(item._id)}
          id={`editVacancy${item._id}`}
        >
          <Edit color="secondary" />
        </StyledIconButton>
        {loading && item._id === id ? (
          <StyledCircularProgress size={18} color="secondary" />
        ) : (
          <StyledIconButton
            onClick={() => onDeleteIcon(item._id)}
            id={`deleteVacancy${item._id}`}
          >
            <Delete color="secondary" />
          </StyledIconButton>
        )}
      </StyledIconContainer>
    </StyledContainer>
  );
};

export default memo(CompanyVacancyCard);
