import React from "react";
import { CircularProgress, useTheme } from "@mui/material";
import jobworkController from "./jobwork.controller";
import EditMachineSlider from "@pages/home/companyDetails/allCompanyDetails/machine/editMachineSlider/EditMachineSlider";
import EditProcessSlider from "@pages/home/companyDetails/allCompanyDetails/process/editProcessSlider/EditProcessSlider";
import { tableCell } from "./jobwork.const";
import { Table } from "@component";
import {
  StyledContainer,
  StyledBox,
  StyledSearchInput,
  StyledProgressContainer,
} from "@pages/home/users/users.style";
import { StyledText } from "./jobwork.style";

const Jobwork: React.FC = () => {
  const {
    handleSearch,
    loading,
    search,
    searchList,
    deleteSpinner,
    handleCloseDelete,
    handleOpenDelete,
    deleteDialog,
    handleEdit,
    editModal,
    handleCloseEdit,
    productDetails,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    totalDataCount,
    machineList,
    editProcessModal,
    handleCloseProcessEdit,
    deleteProduct,
  } = jobworkController();

  const theme = useTheme();
  return (
    <StyledContainer>
      <StyledBox>
        <StyledText variant="h4">Machine/Process</StyledText>
        <StyledSearchInput
          placeholder="Type something..."
          value={search}
          onChange={(val) => handleSearch(val?.target.value)}
        />
      </StyledBox>
      {loading ? (
        <StyledProgressContainer>
          <CircularProgress size={40} color={"primary"} />
        </StyledProgressContainer>
      ) : (
        <Table
          tableCellData={tableCell}
          tableData={searchList === null ? machineList : searchList}
          isEditButton
          isDeleteButton
          handleOpenDialog={handleOpenDelete}
          openDialog={deleteDialog}
          handleCloseDialog={handleCloseDelete}
          handleDeleteDialog={deleteProduct}
          handleDeleteDialogLoading={deleteSpinner}
          handleEdit={handleEdit}
          search={search}
          backendPagination={true}
          backendPageNumber={currentPage}
          backendRowPerPage={rowsPerPage}
          setBackendCurrentPage={setCurrentPage}
          setBackendRowsPerPage={setRowsPerPage}
          totalDataCount={totalDataCount}
        />
      )}
      <EditMachineSlider
        openDrawer={editModal}
        handleCloseIcon={handleCloseEdit}
        userId={productDetails.user_id}
        machineId={productDetails._id}
        type="master"
        data={productDetails}
      />
      <EditProcessSlider
        openDrawer={editProcessModal}
        handleCloseIcon={handleCloseProcessEdit}
        userId={productDetails.user_id}
        processId={productDetails._id}
        type="process"
        data={productDetails}
      />
    </StyledContainer>
  );
};

export default Jobwork;
