import React from "react";
import { Add } from "@mui/icons-material";
import AddNotification from "./addNotification/AddNotification";
import { cellData } from "./massNotification.const";
import { CircularProgress, SnackBar, Table } from "@component";
import massNotificationController from "./massNotification.controller";
import {
  StyledBox,
  StyledButton,
  StyledContainer,
  StyledProgressContainer,
  StyledSearchInput,
  StyledText,
} from "../company/company.style";

const MassNotification: React.FC = () => {
  const {
    search,
    handleSearch,
    loading,
    filteredList,
    notifications,
    handleClose,
    handleOpen,
    open,
    getMassNotifications,
  } = massNotificationController();
  return (
    <StyledContainer>
      <StyledBox>
        <StyledText variant="h4">Mass Notifications</StyledText>
        <StyledSearchInput
          placeholder="Type something..."
          value={search}
          onChange={(val) => handleSearch(val?.target.value)}
        />
        <StyledButton
          onClick={handleOpen}
          startIcon={<Add fontSize="medium" />}
          id="addNotification"
        >
          Add Notification
        </StyledButton>
      </StyledBox>
      {loading ? (
        <StyledProgressContainer>
          <CircularProgress size={40} color={"primary"} />
        </StyledProgressContainer>
      ) : (
        <Table
          tableData={filteredList == null ? notifications : filteredList}
          tableCellData={cellData}
          search={search}
        />
      )}
      <AddNotification
        open={open}
        onClose={handleClose}
        getUpdate={getMassNotifications}
      />
    </StyledContainer>
  );
};

export default MassNotification;
