import { useEffect, useMemo, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  getQualificationsData,
  updateQualifications,
} from "@services/redux/qualifications/action";
import {
  QualificationDataProps,
  QualificationsControllerProps,
} from "./qualifications.interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";

const qualificationsController = (): QualificationsControllerProps => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [index, setIndex] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<QualificationDataProps[] | null>(
    null
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [qualificationData, setQualificationData] = useState<
    QualificationDataProps[]
  >([]);
  const { qualifications } = useAppSelector(
    (state) => state.qualificationReducer
  );
  const dispatch = useAppDispatch();

  const qualification = useMemo(() => {
    return qualificationData.map((item) => ({
      _id: item._id,
      qualification: item.qualification,
      status: item.status,
    }));
  }, [qualificationData]);

  useEffect(() => {
    setLoading(false);
  }, [qualifications]);

  useEffect(() => {
    if (qualifications?.length === 0) {
      setLoading(true);
    }
    dispatch(getQualificationsData() as any);
  }, []);

  useEffect(() => {
    setQualificationData(qualifications);
  }, [qualifications]);

  const filter = (value: string): void => {
    /* Functionality to filter data */
    let filterData = qualification.filter((item: QualificationDataProps) => {
      let search = [item?.qualification];
      let regex = search.toString().toLowerCase();
      return regex.includes(value.toLowerCase());
    });
    setSearchValue(value);
    setFilterData(filterData);
  };

  const editQualificationStatus = async (
    qualificationId: string | undefined,
    check: boolean
  ) => {
    /* API call for editing qualification status */
    try {
      const formData = {
        status: check ? "1" : "0",
        id: qualificationId,
      };
      const { data } = await axiosInstance.post(
        Constant.updateQualificationStatus,
        formData
      );
      dispatch(updateQualifications(data.data));
    } catch (error: any) {}
  };

  const handleOpen = (item?: any): void => {
    setOpenDrawer(true);
    setIndex(item?._id);
  };

  const handleClose = (): void => {
    setOpenDrawer(false);
  };

  return {
    openDrawer,
    handleClose,
    handleOpen,
    index,
    loading,
    qualification,
    editQualificationStatus,
    filter,
    filterData,
    searchValue,
  };
};

export default qualificationsController;
