import { Box, Modal, styled } from "@mui/material";

export const StyledModal = styled(Modal)(({ theme }) => ({
  background: "rgba(0,0,0,0.2)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(0, 13),
  paddingTop: theme.spacing(13),
  borderRadius: 15,
  height: "85%",
}));
