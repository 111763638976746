import { Button, Input, Selection, Text } from "@component";
import { Box, styled } from "@mui/material";

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "82%",
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  width: "100%",
  alignSelf: "center",
  padding: theme.spacing(3, 0),
  input: {
    color: theme.palette.common.black,
    height: 10,
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "32vw",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(10),
  },
  justifyContent: "flex-end",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "27%",
  height: 45,
  color: theme.palette.secondary.main,
  border: "2px solid",
  background: theme.palette.common.white,
  "&:hover": {
    background: theme.palette.common.white,
  },
  [theme.breakpoints.down("lg")]: {
    width: "30%",
  },
  alignSelf: "flex-end",
}));

export const StyledSelection = styled(Selection)(({ theme }) => ({
  borderWidth: 0,
  marginBottom: theme.spacing(4),
  ".MuiSelect-outlined": {
    color: theme.palette.grey[900],
  },
}));

export const StyledErrorText = styled(Text)(({ theme }) => ({
  fontSize: 12,
  alignSelf: "flex-start",
  color: "red",
  marginLeft: theme.spacing(8),
  marginTop: -7,
  marginBottom: 5,
}));
