import { TableCellDataProps } from "@component/customTable/table.interface";

export const tableCell: TableCellDataProps[] = [
  {
    id: 1,
    title: "Sr No.",
  },
  {
    id: 2,
    title: "Name",
  },
  {
    id: 3,
    title: "Subcategory",
  },
  {
    id: 4,
    title: "Total Subcategory",
  },
  {
    id: 5,
    title: "Status",
  },
  {
    id: 6,
    title: "Action",
  },
];
