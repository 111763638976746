import { useEffect, useState } from "react";
import { axiosInstance } from "@services/api/api";
import {
  checkAnnualIncome,
  checkGST,
  checkNumeric,
  checkWebsiteLink,
} from "@utils/validation/validation";
import { Company } from "@services/redux/userList/interface";
import { Constant } from "@services/constant";
import {
  ErrorProps,
  CompanyStepThreeControllerProps,
  CompanyTypeDataProps,
} from "./companyStepThree.interface";
import { removeEditData } from "@services/redux/addCompanyUser/action";
import { updateCompanyUserList } from "@services/redux/userList/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import { UserStepOneProps } from "@pages/home/userDetails/editUserModal/userStepOne/userStepOne.interface";
import validationMessage from "@utils/validation/validationMessage";

const companyStepThreeController = ({
  handleNext,
  userId,
}: UserStepOneProps): CompanyStepThreeControllerProps => {
  const dispatch = useAppDispatch();
  const { companyUserList } = useAppSelector((state) => state.userListReducer);
  const [gstNumber, setGstNumber] = useState<string>();
  const [annualTurnOver, setAnnualTurnOver] = useState<string>();
  const [noOfEmployees, setNoOfEmployees] = useState<string>();
  const [aboutCompany, setAboutCompany] = useState<string>();
  const [websiteLink, setWebsiteLink] = useState<string>("");
  const [select, setSelect] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");
  const [error, setError] = useState<ErrorProps>({
    gstNumber: null,
    annualTurnOver: null,
    noOfEmployees: null,
    aboutCompany: null,
    websiteLink: null,
    select: null,
  });

  useEffect(() => {
    if (userId) {
      setGstNumber(editData[0].gstNumber);
      setAnnualTurnOver(editData[0].annualTurnOver);
      setNoOfEmployees(editData[0].noOfEmpolyees?.toString());
      setAboutCompany(editData[0].companyAboutUs);
      setWebsiteLink(editData[0].websiteLink);
      setSelect(
        editData[0].companyType ? editData[0].companyType.split(",") : []
      );
    }
  }, []);

  let editData: Company[] = companyUserList.filter(
    (item: Company) => item._id === userId
  );

  const { editBasicDetailsData, editCompanyDetailsData } = useAppSelector(
    (state) => state.editCompanyUserReducer
  );

  const { firstName, lastName, email, mobileNumber, zone } =
    editBasicDetailsData;

  const { legalFirmName, ownerName, companyEmail, companyMobileNo, address } =
    editCompanyDetailsData;

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const multipleSelect = (item: CompanyTypeDataProps): void => {
    /* Multiple selection */
    if (select.includes(item.type)) {
      setSelect(select.filter((element) => element !== item.type));
    } else {
      setSelect((e) => [...e, item.type]);
    }
  };

  const formData = {
    id: userId,
    firstName: firstName,
    lastName: lastName,
    email: email,
    mobileNumber: mobileNumber,
    registerType: "0",
    legalFirmName: legalFirmName,
    ownerName: ownerName,
    companyMobileNumber: companyMobileNo,
    companyEmail: companyEmail,
    address: address,
    gstNumber: gstNumber,
    annualTurnOver: annualTurnOver,
    noOfEmpolyees: noOfEmployees,
    companyType: select.toString(),
    companyAboutUs: aboutCompany,
    websiteLink: websiteLink,
    zone: zone,
  };

  const editCompanyUserData = async () => {
    /* API call for editing company user data */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.editCompanyUserData,
        formData
      );
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
      dispatch(updateCompanyUserList(data.data));
      setTimeout(() => handleNext(), 3000);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const validation = (): void => {
    /* Validation for company type and other company details */
    let isValidate = true;
    if (gstNumber) {
      if (
        gstNumber?.length < 15 ||
        gstNumber?.length > 23 ||
        !checkGST(gstNumber) ||
        !gstNumber?.trim()
      ) {
        isValidate = false;
        error.gstNumber = validationMessage.validationValidGSTNumber;
      } else {
        error.gstNumber = null;
      }
    }
    if (annualTurnOver) {
      if (!checkAnnualIncome(annualTurnOver) || !annualTurnOver?.trim()) {
        isValidate = false;
        error.annualTurnOver = validationMessage.validatonValidAnnualTurnOver;
      } else {
        error.annualTurnOver = null;
      }
    }
    if (noOfEmployees) {
      if (!checkNumeric(noOfEmployees.toString()) || !noOfEmployees?.trim()) {
        isValidate = false;
        error.noOfEmployees = validationMessage.validatonValidNoOfEmployees;
      } else {
        error.noOfEmployees = null;
      }
    }
    if (aboutCompany) {
      if (!aboutCompany?.trim()) {
        isValidate = false;
        error.aboutCompany = validationMessage.validationAboutCompany;
      } else {
        error.aboutCompany = null;
      }
    }
    if (websiteLink) {
      if (!checkWebsiteLink(websiteLink) || !websiteLink?.trim()) {
        isValidate = false;
        error.websiteLink = validationMessage.validatonValidWebsiteLink;
      } else {
        error.websiteLink = null;
      }
    }
    setError({ ...error });
    if (isValidate) {
      editCompanyUserData();
      dispatch(removeEditData());
    }
  };

  return {
    gstNumber,
    setGstNumber,
    annualTurnOver,
    setAnnualTurnOver,
    noOfEmployees,
    setNoOfEmployees,
    aboutCompany,
    setAboutCompany,
    websiteLink,
    setWebsiteLink,
    select,
    validation,
    error,
    multipleSelect,
    loading,
    snackBarMessage,
    openSnackBar,
    handleCloseSnackBar,
  };
};

export default companyStepThreeController;
