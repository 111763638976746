import React, { memo } from "react";
import { MasterDropdownProps } from "./masterDropdown.interface";
import { StyledDropDownButton } from "./masterDropdown.style";

const MasterDropdown: React.FC<MasterDropdownProps> = ({
  item,
  onIcon,
  id,
}) => {
  return (
    <StyledDropDownButton
      variant="text"
      startIcon={<item.icon color={item.id === id ? "secondary" : "info"} />}
      onClick={onIcon}
      id={item.screen}
    >
      {item.name}
    </StyledDropDownButton>
  );
};

export default memo(MasterDropdown);
