import { memo } from "react";
import { Box } from "@mui/material";
import { Constant } from "@services/constant";
import Switch from "@component/switch/Switch";
import { UserDetailsCardProps } from "./userDetailsCard.interface";
import {
  StyledDetailsContainer,
  StyledExperienceTitle,
  StyledText,
  StyledTitleText,
} from "./userDetailsCard.style";

const UserDetailsCard: React.FC<UserDetailsCardProps> = ({ item }) => {
  let resume: string = item?.resume?.substring(6);
  return (
    <Box>
      <StyledDetailsContainer>
        <StyledTitleText>Activity Status</StyledTitleText>
        <Switch checked={item.activeStatus === 0 ? false : true} />
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Company Name</StyledTitleText>
        <StyledText>{item.currentlyWorkingCompanyName ?? "N/A"}</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Degree</StyledTitleText>
        <StyledText>{item.degree ?? "N/A"}</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Qualifications</StyledTitleText>
        <StyledText>{item.qualification ?? "N/A"}</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Mobile No.</StyledTitleText>
        <StyledText>{item.mobileNumber}</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Expected Salary</StyledTitleText>
        <StyledText>{item.expectedSalary ?? "N/A"}</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Resume</StyledTitleText>
        <StyledTitleText>
          {resume ? (
            <a
              href={Constant.imageUrl + item?.resume}
              target="_blank"
              rel="noreferrer"
            >
              {resume}
            </a>
          ) : (
            <StyledText>N/A</StyledText>
          )}
        </StyledTitleText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Job Inquiries</StyledTitleText>
        <StyledText>0</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Expertise</StyledTitleText>
        <StyledTitleText>Experience</StyledTitleText>
      </StyledDetailsContainer>
      {item.qualificationData?.length !== 0 ? (
        item.qualificationData.map((item) => (
          <Box key={item._id} sx={{ display: "flex" }}>
            <StyledExperienceTitle>{item.expertise}</StyledExperienceTitle>
            <StyledText>
              {item.experience ? `${item.experience} years` : "N/A"}
            </StyledText>
          </Box>
        ))
      ) : (
        <Box sx={{ display: "flex" }}>
          <StyledText>N/A</StyledText>
          <StyledText>N/A</StyledText>
        </Box>
      )}
    </Box>
  );
};

export default memo(UserDetailsCard);
