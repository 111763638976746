import { useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import {
  AddCompanyUserControllerProps,
  AddCompanyUserProps,
  ValidationProps,
} from "./addCompanyUser.interface";
import { axiosInstance } from "@services/api/api";
import {
  checkEmail,
  checkMobileNumber,
  checkPswd,
  checkString,
} from "@utils/validation/validation";
import { Company } from "@services/redux/userList/interface";
import { Constant } from "@services/constant";
import { editBasicDetails } from "@services/redux/addCompanyUser/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const addUserController = ({
  handleNextButton,
  companyUserId,
}: AddCompanyUserProps): AddCompanyUserControllerProps => {
  const { zones } = useAppSelector((state) => state.zoneReducer);
  const { companyUserList } = useAppSelector((state) => state.userListReducer);
  const { editBasicDetailsData } = useAppSelector(
    (state) => state.editCompanyUserReducer
  );
  const dispatch = useAppDispatch();
  let editData: Company[] = companyUserList.filter(
    (item: Company) => item._id === companyUserId
  );
  useEffect(() => {
    if (companyUserId) {
      setFirstName(editData[0]?.firstName);
      setEmail(editData[0]?.email);
      setMobileNo(editData[0]?.mobileNumber?.toString());
      setSelectZone(editData[0]?.zone);
    }
  }, []);
  const [firstName, setFirstName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [selectZone, setSelectZone] = useState<string>();
  const [otherZone, setOtherZone] = useState<string>();
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ValidationProps>({
    firstName: null,
    email: null,
    mobileNo: null,
    zone: null,
    otherZone: null,
    password: null,
  });

  const handleZone = (event: SelectChangeEvent): void => {
    setSelectZone(event.target.value);
    if (event.target.value === "Other") {
      setOtherZone("");
      error.otherZone = null;
      setError({ ...error });
    }
  };

  const saveEditedData = (): void => {
    let temp = { ...editBasicDetailsData };
    temp.firstName = firstName;
    temp.email = email;
    temp.mobileNo = mobileNo;
    temp.zone = selectZone === "Other" ? otherZone : selectZone;
    dispatch(editBasicDetails(temp));
  };

  const formData = {
    firstName: firstName,
    email: email,
    mobileNumber: mobileNo,
    password: password,
    registerType: "0",
    zone: selectZone === "Other" ? otherZone : selectZone,
  };

  const registerCompanyUserStepOne = async () => {
    /* API call for Company user register step one */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.registerStepOne,
        formData
      );
      setLoading(false);
      localStorage.setItem("addCompanyUserId", data.data[0]._id);
      handleNextButton();
    } catch (error) {
      setLoading(false);
    }
  };

  const validation = (): void => {
    let isValidate = true;
    if (!firstName?.trim()) {
      isValidate = false;
      error.firstName = validationMessage.validationFirstName;
    } else if (!checkString(firstName)) {
      isValidate = false;
      error.firstName = validationMessage.validationValidFirstName;
    } else {
      error.firstName = null;
    }
    if (!email?.trim()) {
      isValidate = false;
      error.email = validationMessage.validationEmail;
    } else if (!checkEmail(email)) {
      isValidate = false;
      error.email = validationMessage.validationValidEmail;
    } else {
      error.email = null;
    }
    if (!mobileNo?.trim()) {
      isValidate = false;
      error.mobileNo = validationMessage.validationMobileNo;
    } else if (!checkMobileNumber(mobileNo.toString())) {
      isValidate = false;
      error.mobileNo = validationMessage.validationValidMobileNo;
    } else {
      error.mobileNo = null;
    }
    if (!selectZone) {
      isValidate = false;
      error.zone = validationMessage.selectZone;
    } else {
      error.zone = null;
    }
    if (selectZone === "Other") {
      if (!otherZone?.trim()) {
        isValidate = false;
        error.otherZone = validationMessage.validationZone;
      } else {
        error.otherZone = null;
      }
    }
    if (!companyUserId) {
      if (!password?.trim()) {
        isValidate = false;
        error.password = validationMessage.validationPassword;
      } else if (!checkPswd(password)) {
        isValidate = false;
        error.password = validationMessage.validationValidPassword;
      } else {
        error.password = null;
      }
    }
    setError({ ...error });
    if (isValidate) {
      if (!companyUserId) {
        registerCompanyUserStepOne();
      } else {
        saveEditedData();
        handleNextButton();
      }
    }
  };

  return {
    firstName,
    setFirstName,
    email,
    setEmail,
    mobileNo,
    setMobileNo,
    password,
    setPassword,
    error,
    validation,
    loading,
    selectZone,
    handleZone,
    zones,
    editData,
    setOtherZone,
    otherZone,
  };
};

export default addUserController;
