import React from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import EditVacancySlider from "@pages/home/companyDetails/allCompanyDetails/vacancy/editVacancySlider/EditVacancySlider";
import { jobVacancyCell } from "./jobVacancy.const";
import jobVacancyController from "./jobVacancy.controller";
import { Table, Text } from "@component";
import {
  StyledContainer,
  StyledBox,
  StyledSearchInput,
  StyledProgressContainer,
} from "@pages/home/users/users.style";

const JobVacancy: React.FC = () => {
  const {
    handleSearch,
    loading,
    vacancyList,
    search,
    searchList,
    deleteSpinner,
    handleCloseDelete,
    handleOpenDelete,
    deleteDialog,
    handleEdit,
    deleteJobVacancy,
    editModal,
    handleCloseEdit,
    vacancyDetails,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    totalDataCount,
  } = jobVacancyController();

  const theme = useTheme();
  return (
    <StyledContainer>
      <StyledBox>
        <Box flex={1} m={theme.spacing(4)}>
          <Text variant="h4" color={theme.palette.primary.main}>
            Job Vacancy
          </Text>
        </Box>
        <StyledSearchInput
          placeholder="Type something..."
          value={search}
          onChange={(val) => handleSearch(val?.target.value)}
        />
      </StyledBox>
      {loading ? (
        <StyledProgressContainer>
          <CircularProgress size={40} color={"primary"} />
        </StyledProgressContainer>
      ) : (
        <Table
          tableCellData={jobVacancyCell}
          tableData={searchList === null ? vacancyList : searchList}
          isEditButton
          isDeleteButton
          handleOpenDialog={handleOpenDelete}
          openDialog={deleteDialog}
          handleCloseDialog={handleCloseDelete}
          handleDeleteDialog={deleteJobVacancy}
          handleDeleteDialogLoading={deleteSpinner}
          handleEdit={handleEdit}
          search={search}
          backendPagination={true}
          backendPageNumber={currentPage}
          backendRowPerPage={rowsPerPage}
          setBackendCurrentPage={setCurrentPage}
          setBackendRowsPerPage={setRowsPerPage}
          totalDataCount={totalDataCount}
        />
      )}
      <EditVacancySlider
        openDrawer={editModal}
        handleCloseIcon={handleCloseEdit}
        userId={vacancyDetails?.createrId?._id}
        vacancyId={vacancyDetails?._id}
        type="master"
        data={vacancyDetails}
      />
    </StyledContainer>
  );
};

export default JobVacancy;
