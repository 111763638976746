import React from "react";
import { Add } from "@mui/icons-material";
import AddCompanyUserSlider from "./addCompanyUserSlider/AddCompanyUserSlider";
import { CircularProgress, Download, Table } from "@component";
import companyController from "./company.controller";
import { tableCellData } from "./company.const";
import { useAppSelector } from "@services/redux/controller";
import {
  StyledBox,
  StyledButton,
  StyledContainer,
  StyledProgressContainer,
  StyledSearchInput,
  StyledText,
} from "./company.style";

const Company: React.FC = () => {
  const user = useAppSelector((state) => state.userReducer);

  const cellData =
    user?.userData?.adminType === 1
      ? tableCellData
      : tableCellData.filter((item) => item.id !== 9);

  const {
    loading,
    searchValue,
    filter,
    filterData,
    openDrawer,
    handleOpen,
    handleClose,
    companyDetailsData,
    handleDialogClose,
    handleDialogOpen,
    openDialog,
    index,
    removeUser,
    deleteLoading,
    onExport,
  } = companyController();
  return (
    <StyledContainer>
      <StyledBox>
        <StyledText variant="h4">Company</StyledText>
        <StyledSearchInput
          placeholder="Type something..."
          value={searchValue}
          onChange={(val) => filter(val?.target.value)}
        />
        <StyledButton
          onClick={() => handleOpen()}
          startIcon={<Add fontSize="medium" />}
          id="addCompany"
        >
          Add Company
        </StyledButton>
        {user?.userData?.adminType === 1 && (
          <Download onIconClick={onExport} id="companyDownload" />
        )}
        <AddCompanyUserSlider
          openDrawer={openDrawer}
          handleCloseIcon={handleClose}
          companyUserId={index}
        />
      </StyledBox>
      {loading ? (
        <StyledProgressContainer>
          <CircularProgress size={40} color={"primary"} />
        </StyledProgressContainer>
      ) : (
        <Table
          tableData={filterData == null ? companyDetailsData : filterData}
          tableCellData={cellData}
          navigationTo={"companyDetails"}
          isButton
          buttonText="View"
          isDeleteButton={user?.userData?.adminType === 1}
          handleOpenDialog={(id) => handleDialogOpen(id)}
          openDialog={openDialog}
          handleCloseDialog={handleDialogClose}
          handleDeleteDialog={removeUser}
          handleDeleteDialogLoading={deleteLoading}
          search={searchValue}
        />
      )}
    </StyledContainer>
  );
};

export default Company;
