export enum Constant {
  // Live server
  baseUrl = "https://app.bsetu.com/api/",
  // Demo server
  //  baseUrl= 'https://api.bsetu.com/api/',
  imageUrl = "https://app.bsetu.com/",
  login = "admin/login",
  addAdmin = "addAdmin",
  allAdmin = "allAdmin",
  changePassword = "changePasswordWithOldPassword",
  allUsers = "allUser",
  registerStepOne = "admin/register-step1",
  companyRegisterStep2 = "admin/compnay-register-step2",
  companyRegisterStep3 = "admin/compnay-register-step3",
  personalProfile = "admin/person-register-step2",
  personalProfileStep2 = "admin/person-register-step3",
  getQualifications = "admin/qualifications",
  addEditQualifications = "admin/addedit-qualification",
  removeQualification = "admin/remove-qualification",
  userCount = "admin/count",
  addEditAboutUs = "admin/addedit-aboutus",
  getAboutUs = "admin/aboutus",
  editPersonUser = "admin/edit-personuser",
  editCompanyUserData = "admin/edit-companyuser",
  updateQualificationStatus = "admin/qualifications/status",
  removeUser = "admin/remove-user",
  getZones = "admin/zone",
  addEditZone = "admin/addedit-zone",
  zoneStatusUpdate = "admin/zone-status",
  companyAllData = "admin/company-all-data",
  editProductAdmin = "admin/product-edit",
  editJobWorkAdmin = "admin/jobwork-edit",
  editJobVacancyAdmin = "admin/jobvacancy/edit",
  editCompanyJobWorkAdmin = "admin/companyjobwork/edit",
  RemoveProductJobworkJobVacancy = "admin/product-jobwork-jobvacancy/remove",
  getCompanyPortfolio = "admin/companyportfolio",
  removePortfolioProductJobWork = "admin/company-product-jobwork/remove",
  editPortfolioProduct = "admin/companyproduct/edit",
  editPortfolioJobWork = "admin/companyjobwork/edit",
  addPortfolioProduct = "admin/companyproduct-add",
  addPortfolioJobWork = "admin/companyjobwork-add",
  addProduct = "admin/product-add",
  addJobVacancy = "admin/jobvacancy-add",
  addJobWork = "admin/jobwork-add",
  getExpertise = "admin/expertise-details",
  addExpertise = "admin/add-expertise-details",
  editExpertise = "admin/edit-expertise-details",
  updateExpertiseStatus = "admin/update-expertise-status",
  getProcess = "admin/processes-details",
  addProcess = "admin/add-processes-details",
  editProcess = "admin/edit-processes-details",
  updateProcessStatus = "admin/update-processes-status",
  getMachine = "admin/machine-details",
  addMachine = "admin/add-machine-details",
  editMachine = "admin/edit-machine-details",
  updateMachineStatus = "admin/update-machine-status",
  getSalesUsers = "admin/sales-user-list",
  addEditSalesUser = "admin/add-edit/sales-user",
  removeSalesUser = "admin/remove/sales-user",
  salesUserReport = "admin/salesperson-user-report",
  getMassNotifications = "admin/mass-notifications",
  addMassNotification = "admin/send-mass-notification",
  getAllProducts = "admin/get-all-product",
  getAllJobwork = "admin/get-all-jobwork",
  getAllJobVacancie = "admin/get-all-job-vacancie",
  getPortoflioJobwork = "admin/get-all-company-jobwork",
  getPortoflioProduct = "admin/get-all-company-product",
}
