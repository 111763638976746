import { memo } from "react";
import { Box } from "@mui/material";
import { CompanyDetailsCardProps } from "./companyDetailsCard.interface";
import Switch from "@component/switch/Switch";
import {
  StyledDetailsContainer,
  StyledText,
  StyledTitleText,
} from "./companyDetailsCard.style";

const CompanyDetailsCard: React.FC<CompanyDetailsCardProps> = ({ item }) => {
  return (
    <Box>
      <StyledDetailsContainer>
        <StyledTitleText>Activity Status</StyledTitleText>
        <Switch checked={item?.activeStatus === 0 ? false : true} />
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Company Name</StyledTitleText>
        <StyledText>{item?.legalFirmName ?? "N/A"}</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>About</StyledTitleText>
        <StyledText>{item?.companyAboutUs ?? "N/A"}</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Email</StyledTitleText>
        <StyledText>{item?.companyEmail ?? "N/A"}</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Mobile No.</StyledTitleText>
        <StyledText>{item?.companyMobileNumber ?? "N/A"}</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Address</StyledTitleText>
        <StyledText>{item?.address ?? "N/A"}</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Owner Name</StyledTitleText>
        <StyledText>{item?.ownerName ?? "N/A"}</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Company Type</StyledTitleText>
        <StyledText>
          {item?.companyType?.length === 0 ? "N/A" : item?.companyType}
        </StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Number of Employees</StyledTitleText>
        <StyledText>{item?.noOfEmpolyees ?? "N/A"}</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Zone</StyledTitleText>
        <StyledText>{item?.zone ?? "N/A"}</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>GST No.</StyledTitleText>
        <StyledText>{item?.gstNumber ?? "N/A"}</StyledText>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledTitleText>Website</StyledTitleText>
        <StyledText>{item?.websiteLink ? item?.websiteLink : "N/A"}</StyledText>
      </StyledDetailsContainer>
    </Box>
  );
};

export default memo(CompanyDetailsCard);
