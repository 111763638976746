import { useEffect, useState } from "react";
import moment from "moment";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  MassNotificationControllerProps,
  NotificationProps,
} from "./massNotification.interface";

const massNotificationController = (): MassNotificationControllerProps => {
  const [search, setSearch] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  const [filteredList, setFilteredList] = useState<NotificationProps[] | null>(
    null
  );

  useEffect(() => {
    getMassNotifications();
  }, []);

  const getMassNotifications = async () => {
    /* API call for getting mass notifications */
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(Constant.getMassNotifications);
      const list = data?.data?.map((item: any) => ({
        _id: item?._id,
        title: item?.title,
        description: item?.description,
        createdOn: moment(item?.createdAt).format("DD/MM/YYYY"),
      }));
      setNotifications(list);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearch = (value: string): void => {
    let filterData = notifications?.filter((item: any) => {
      let search = [item?.title, item?.description];
      let regex = search.toString().toLowerCase();
      return regex.includes(value.toLowerCase());
    });
    setSearch(value);
    setFilteredList(filterData);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return {
    search,
    handleSearch,
    loading,
    filteredList,
    notifications,
    handleClose,
    handleOpen,
    open,
    getMassNotifications,
  };
};

export default massNotificationController;
