import { useEffect, useMemo, useState } from "react";
import {
  addVacancyData,
  editVacancyData,
} from "@services/redux/allCompanyDetails/action";
import { axiosInstance } from "@services/api/api";
import { checkNumeric } from "@utils/validation/validation";
import { Constant } from "@services/constant";
import {
  EditVacancySliderControllerProps,
  EditVacancySliderProps,
  ErrorProps,
} from "./editVacancySlider.interface";
import { JobWorkType } from "../../machine/editMachineSlider/editMachineSlider.interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";
import { SelectChangeEvent } from "@mui/material";
import { QualificationProps } from "@pages/home/users/addUserSlider/userProfileStepOne/userProfileStepOne.interface";

const editVacancySliderController = ({
  handleCloseIcon,
  openDrawer,
  userId,
  vacancyId,
  data,
  type,
}: EditVacancySliderProps): EditVacancySliderControllerProps => {
  const { vacancy } = useAppSelector((state) => state.allCompanyDetailsReducer);
  const dispatch = useAppDispatch();

  const vacancyData: any =
    type === "master"
      ? data
      : useMemo(
          () =>
            vacancy?.filter((item: any) => {
              return item._id === vacancyId;
            })[0],
          [vacancy]
        );

  const [selectMainCategory, setSelectMainCategory] = useState<
    JobWorkType | undefined
  >(undefined);
  const [selectSubMainCategory, setSelectSubMainCategory] = useState<
    JobWorkType | undefined
  >(undefined);
  const [selectedCategory, setSelectedCategory] = useState<
    JobWorkType | undefined | any
  >(undefined);
  // const [qualification, setQualification] = useState<string>(
  //   vacancyData[0]?.qualification
  // );
  const [selectQualification, setSelectQualification] = useState<string>();
  const [qualificationsData, setQualificationsData] = useState<
    QualificationProps[]
  >([]);
  const [experience, setExperience] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [noOfPosition, setNoOfPosition] = useState<string>();
  const [expectedSalary, setExpectedSalary] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<Blob | string | any>();
  const [removeFile, setRemoveFile] = useState<string>("");
  const [workAs, setWorkAs] = useState<string>();
  const [error, setError] = useState<ErrorProps>({
    selectExpertiseError: null,
    qualificationError: null,
    experienceError: null,
    noOfPositionError: null,
    currentSalaryError: null,
    expectedSalaryError: null,
    workAsError: null,
  });
  const [selectedFileError, setSelectedFileError] = useState<string | null>(
    null
  );
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getQualificationsData();
  }, []);

  useEffect(() => {
    setSelectQualification(vacancyData?.qualification);
    setExperience(vacancyData?.experience);
    setWorkAs(vacancyData?.workAs);
    setDescription(vacancyData?.description);
    setNoOfPosition(vacancyData?.noOfPosition);
    setExpectedSalary(vacancyData?.approxSalary);
    setSelectedFile(vacancyData?.files);
  }, [vacancyData]);

  const selectedVacancy: string = selectSubMainCategory
    ? `${selectMainCategory?.name}-${selectSubMainCategory?.name}-${selectedCategory?.name}`
    : selectedCategory
    ? `${
        selectMainCategory
          ? selectMainCategory.name + "-" + selectedCategory?.name
          : selectedCategory?.name
      }`
    : vacancyData?.expertise
    ? vacancyData?.expertise
    : "Select Expertise";

  const getQualificationsData = async () => {
    /* API call for getting qualifications data */
    try {
      const { data } = await axiosInstance.get(Constant.getQualifications);
      setQualificationsData(data.data);
    } catch (error) {}
  };

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const handleSelectionChange = (event: SelectChangeEvent): void => {
    setSelectQualification(event.target.value);
  };

  const handleSelectFile = (e: any): void => {
    /* Functionality for uploading documents */
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 200000000) {
        setSelectedFile("");
        setSelectedFileError(
          "The uploaded file exceeds the maximum allowed size of 200MB"
        );
      } else {
        setSelectedFile(selectedFile);
        setSelectedFileError("");
      }
    }
  };

  const validation = (): void => {
    /* Validation for edit vacancy form */
    let isValidate = true;
    if (!vacancyId) {
      if (!selectedCategory) {
        isValidate = false;
        error.selectExpertiseError =
          validationMessage.validationSelectedExpertise;
      } else {
        error.selectExpertiseError = "";
      }
    }
    if (!selectQualification) {
      isValidate = false;
      error.qualificationError = validationMessage.validationQualification;
    } else {
      error.qualificationError = "";
    }
    if (!experience) {
      isValidate = false;
      error.experienceError = validationMessage.validationExperienceMessage;
    } else if (!checkNumeric(experience)) {
      isValidate = false;
      error.experienceError =
        validationMessage.validationValidExperienceMessage;
    } else {
      error.experienceError = "";
    }
    if (!noOfPosition) {
      isValidate = false;
      error.noOfPositionError = validationMessage.noOfPositionValidation;
    } else if (!checkNumeric(noOfPosition)) {
      isValidate = false;
      error.noOfPositionError = validationMessage.validNoOfPositionValidation;
    } else {
      error.noOfPositionError = "";
    }
    if (!expectedSalary) {
      isValidate = false;
      error.expectedSalaryError = validationMessage.approxSalaryValidation;
    } else if (!checkNumeric(expectedSalary)) {
      isValidate = false;
      error.expectedSalaryError = validationMessage.validApproxSalaryValidation;
    } else {
      error.expectedSalaryError = "";
    }
    if (!workAs) {
      isValidate = false;
      error.workAsError = validationMessage.workAsValidation;
    } else {
      error.workAsError = "";
    }
    setError({ ...error });
    if (isValidate) {
      if (vacancyId) {
        editVacancy();
      } else {
        addVacancy();
      }
    }
  };

  const onDeleteIcon = (): void => {
    if (selectedFile) {
      setRemoveFile(vacancyData?.files);
    }
    setSelectedFile("");
  };

  const addVacancy = async () => {
    /* API call for adding vacancy */
    const formData = new FormData();
    if (typeof userId === "string") {
      formData.append("userId", userId);
    }
    formData.append("expertise", selectedVacancy);
    if (selectQualification) {
      formData.append("qualification", selectQualification);
    }
    if (experience) {
      formData.append("experience", experience);
    }
    if (description) {
      formData.append("description", description);
    }
    if (expectedSalary) {
      formData.append("approxSalary", expectedSalary);
    }
    if (noOfPosition) {
      formData.append("noOfPosition", noOfPosition);
    }
    formData.append("files", selectedFile);
    if (workAs) {
      formData.append("workAs", workAs);
    }
    if (removeFile) {
      formData.append("removeUrl", removeFile);
    }
    setSelectMainCategory(undefined);
    setSelectSubMainCategory(undefined);
    setSelectedCategory("");
    setSelectQualification("");
    setExperience("");
    setDescription("");
    setExpectedSalary("");
    setNoOfPosition("");
    setSelectedFile("");
    setWorkAs("");
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.addJobVacancy,
        formData
      );
      setLoading(false);
      dispatch(addVacancyData(data.data[0]));
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const editVacancy = async () => {
    /* API call for editing vacancy */
    const formData = new FormData();
    if (typeof userId === "string") {
      formData.append("userId", userId);
    }
    formData.append("expertise", selectedVacancy);
    if (selectQualification) {
      formData.append("qualification", selectQualification);
    }
    if (experience) {
      formData.append("experience", experience);
    }
    if (description) {
      formData.append("description", description);
    }
    if (expectedSalary) {
      formData.append("approxSalary", expectedSalary);
    }
    if (noOfPosition) {
      formData.append("noOfPosition", noOfPosition);
    }
    if (typeof vacancyId === "string") {
      formData.append("jobVacancyId", vacancyId);
    }
    if (selectedFile) {
      formData.append("files", selectedFile);
    }
    if (workAs) {
      formData.append("workAs", workAs);
    }
    if (removeFile) {
      formData.append("removeUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.editJobVacancyAdmin,
        formData
      );
      setLoading(false);
      dispatch(editVacancyData(data.data));
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  return {
    description,
    expectedSalary,
    experience,
    noOfPosition,
    removeFile,
    selectedFile,
    selectedVacancy,
    setDescription,
    setExpectedSalary,
    setExperience,
    setNoOfPosition,
    setRemoveFile,
    setSelectedFile,
    setWorkAs,
    workAs,
    error,
    selectedFileError,
    handleCloseSnackBar,
    openSnackBar,
    snackBarMessage,
    loading,
    validation,
    handleSelectFile,
    onDeleteIcon,
    selectedCategory,
    selectMainCategory,
    selectSubMainCategory,
    setSelectedCategory,
    setSelectMainCategory,
    setSelectSubMainCategory,
    handleSelectionChange,
    selectQualification,
    qualificationsData,
    vacancyData,
  };
};

export default editVacancySliderController;
