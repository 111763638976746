import React, { memo } from "react";
import { Add } from "@mui/icons-material";
import { AddIconProps } from "./addIcon.interface";
import { StyledIconButton } from "./addIcon.style";

const AddIcon: React.FC<AddIconProps> = ({ onAdd }) => {
  return (
    <StyledIconButton disableRipple onClick={onAdd} id="addExpertise">
      <Add sx={{ color: "#ED753F" }} />
    </StyledIconButton>
  );
};

export default memo(AddIcon);
