import React, { memo } from "react";
import { Step, StepButton, Stepper, useTheme } from "@mui/material";
import CompanyStepTwo from "./companyStepTwo/CompanyStepTwo";
import editUserModalController from "./editCompanyModal.controller";
import { EditUserModalProps } from "./editCompanyModal.interface";
import { stepsData } from "@pages/home/company/addCompanyUserSlider/addCompanyUserSlider.const";
import UserStepOne from "@pages/home/userDetails/editUserModal/userStepOne/UserStepOne";
import UserStepThree from "./companyStepThree/CompanyStepThree";
import { StyledContainer, StyledModal } from "./editCompanyModal.style";

const EditUserModal: React.FC<EditUserModalProps> = ({
  open,
  onClose,
  companyId,
}) => {
  const { activeStep, completed, onNext, onCloseModal } =
    editUserModalController({
      onClose,
      open,
      companyId,
    });
  const theme = useTheme();

  const getStepContent = (step: number) => {
    switch (step) {
      case 0: {
        return (
          <UserStepOne
            handleNext={onNext}
            userId={companyId}
            registerType={0}
            handleClose={onClose}
          />
        );
      }
      case 1: {
        return <CompanyStepTwo handleNext={onNext} userId={companyId} />;
      }
      case 2: {
        return <UserStepThree handleNext={onNext} userId={companyId} />;
      }
    }
  };
  return (
    <StyledModal
      open={open}
      onClose={onCloseModal}
      aria-labelledby="user-edit-modal"
      aria-describedby="user-edit-modal-description"
    >
      <StyledContainer>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ mb: theme.spacing(4) }}
        >
          {stepsData.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {getStepContent(activeStep)}
      </StyledContainer>
    </StyledModal>
  );
};

export default memo(EditUserModal);
