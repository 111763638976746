import { useEffect, useMemo, useState } from "react";
import { addPersonUser, updateUserList } from "@services/redux/userList/action";
import { axiosInstance } from "@services/api/api";
import { checkAnnualIncome } from "@utils/validation/validation";
import { Constant } from "@services/constant";
import { removeUserData } from "@services/redux/addPersonUser/action";
import { User } from "@services/redux/userList/interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import {
  UserProfileStepTwoControllerProps,
  UserProfileStepTwoProps,
  ValidationProps,
} from "./userProfileStepTwo.interface";
import validationMessage from "@utils/validation/validationMessage";

const userProfileStepTwoController = ({
  handleFinish,
  userId,
}: UserProfileStepTwoProps): UserProfileStepTwoControllerProps => {
  const [salary, setSalary] = useState<string>("");
  const [select, setSelect] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ValidationProps>({
    salary: null,
    select: null,
  });
  const [resume, setResume] = useState<any>();
  const [resumeError, setResumeError] = useState<string>("");
  const [portfolio, setPortfolio] = useState<any>();
  const [portfolioError, setPortfolioError] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  const dispatch = useAppDispatch();
  const { userList } = useAppSelector((state) => state.userListReducer);
  const { editBasicDetailsData, editQualificationsData } = useAppSelector(
    (state) => state.editPersonUserReducer
  );
  let editData: User[] = userList.filter((item: User) => item._id === userId);
  const { firstName, lastName, email, mobileNumber, zone } =
    editBasicDetailsData;
  const {
    qualification,
    degree,
    qualificationData,
    currentlyWorkingCompanyName,
  } = editQualificationsData;

  useEffect(() => {
    if (userId) {
      setSalary(editData[0].expectedSalary);
      if (editData[0].workAs !== undefined) {
        setSelect(editData[0]?.workAs.toString() ?? "");
      }
      setResume(editData[0].resume);
      setPortfolio(editData[0].portfolio);
    }
  }, []);
  const fileType = ["application/pdf"];

  const userQualifications = useMemo(() => {
    return qualificationData.map((item: any) => ({
      _id: item._id,
      expertise: item.expertise,
      experience: item.experience,
    }));
  }, [qualificationData]);

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const handleResumeChange = (e: any): void => {
    /* Functionality for uploading documents */
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 200000000) {
        setResume("");
        setResumeError(
          "The uploaded file exceeds the maximum allowed size of 200MB"
        );
      } else {
        setResume(selectedFile);
        setResumeError("");
      }
    }
  };

  const handlePortfolioChange = (e: any): void => {
    /* Functionality to choose pdf file for portfolio */
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        setPortfolio(selectedFile);
        setPortfolioError("");
      } else {
        setPortfolio("");
        setPortfolioError("Please select valid pdf file");
      }
    } else {
      alert("select your file");
    }
  };

  const addedUserId: any = localStorage.getItem("addUserId");

  const formData = new FormData();
  formData.append("id", addedUserId);
  if (salary) {
    formData.append("expectedSalary", salary);
  }
  if (select) {
    formData.append("workAs", select);
  }
  if (resume) {
    formData.append("resume", resume);
  }

  const editForm = new FormData();
  editForm.append("id", userId);
  editForm.append("firstName", firstName);
  editForm.append("email", email);
  editForm.append("mobileNumber", mobileNumber);
  if (qualification) {
    editForm.append("qualification", qualification);
  }
  if (userQualifications) {
    editForm.append("qualificationData", JSON.stringify(userQualifications));
  }
  if (currentlyWorkingCompanyName) {
    editForm.append("currentlyWorkingCompanyName", currentlyWorkingCompanyName);
  }
  if (degree) {
    editForm.append("degree", degree);
  }
  if (salary) {
    editForm.append("expectedSalary", salary);
  }
  if (select) {
    editForm.append("workAs", select);
  }
  if (resume) {
    editForm.append("resume", resume);
  }
  if (zone) {
    editForm.append("zone", zone);
  }

  const editPersonUserData = async () => {
    /* API call for editing person user data */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.editPersonUser,
        editForm
      );
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
      setTimeout(() => handleFinish(), 2000);
      dispatch(removeUserData());
      dispatch(updateUserList(data.data));
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const userRegistrationStepThree = async () => {
    /* API call for user registration step three */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.personalProfileStep2,
        formData
      );
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
      setTimeout(() => handleFinish(), 2000);
      dispatch(addPersonUser(data.data[0]));
    } catch (error) {
      setLoading(false);
    }
  };

  const validation = (): void => {
    /* Validation for salary and selection for work */
    let isValidate = true;
    if (salary) {
      if (!checkAnnualIncome(salary) || !salary?.trim()) {
        isValidate = false;
        error.salary = validationMessage.validationValidSalaryMessage;
      } else {
        error.salary = null;
      }
    }
    setError({ ...error });
    if (isValidate) {
      if (!userId) {
        userRegistrationStepThree();
      } else {
        editPersonUserData();
      }
    }
  };

  return {
    salary,
    setSalary,
    select,
    setSelect,
    validation,
    error,
    loading,
    resumeError,
    handleResumeChange,
    handlePortfolioChange,
    portfolioError,
    openSnackBar,
    snackBarMessage,
    handleCloseSnackBar,
    resume,
    portfolio,
  };
};

export default userProfileStepTwoController;
