import Button from "@component/button/Button";
import { lighten, styled } from "@mui/material";

export const StyledDropDownButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: "none",
  justifyContent: "flex-start",
  fontSize: 16,
  marginLeft: theme.spacing(17),
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
  paddingLeft: theme.spacing(8),
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "72%",
  },
  "&:hover": {
    background: lighten(theme.palette.primary.main, 0.3),
  },
}));
