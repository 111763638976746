import { UserListReducerProps } from "./interface";
import types from "./type";

const initialState: UserListReducerProps = {
  // allUsers: [],
  userList: [],
  companyUserList: [],
  getAdmins: [],
};

const userListReducer = (
  state: UserListReducerProps = initialState,
  action: any
) => {
  switch (action.type) {
    case types.getUserList:
      return {
        ...state,
        userList: action.payload,
      };
    case types.getCompanyUserList:
      return {
        ...state,
        companyUserList: action.payload,
      };
    // case types.getAllUsers:
    //   return {
    //     ...state,
    //     allUsers: action.payload,
    //   };
    case types.getAdmins:
      return {
        ...state,
        getAdmins: action.payload,
      };
    case types.addPersonUser: {
      const data = [action.payload, ...state.userList];
      return {
        ...state,
        userList: data,
      };
    }
    case types.addCompanyUser: {
      const data = [action.payload, ...state.companyUserList];
      return {
        ...state,
        companyUserList: data,
      };
    }
    case types.updateUserList: {
      const data = state.userList.map((item) =>
        item._id === action.payload._id ? action.payload : item
      );
      return {
        ...state,
        userList: data,
      };
    }
    case types.updateCompanyUserList: {
      const data = state.companyUserList.map((item) =>
        item._id === action.payload._id ? action.payload : item
      );
      return {
        ...state,
        companyUserList: data,
      };
    }
    case types.removePersonUser: {
      const data = state.userList.filter((item) => item._id !== action.payload);
      return {
        ...state,
        userList: data,
      };
    }
    case types.removeCompanyUser: {
      const data = state.companyUserList.filter(
        (item) => item._id !== action.payload
      );
      return {
        ...state,
        companyUserList: data,
      };
    }
    case types.removeUserList:
      return initialState;
    default:
      return state;
  }
};

export default userListReducer;
