import { useEffect, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import { SalesControllerProps, SalesUsersProps } from "./sales.interface";

const salesController = (): SalesControllerProps => {
  const [search, setSearch] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteSpinner, setDeleteSpinner] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [removeUser, setRemoveUser] = useState<boolean>(false);
  const [index, setIndex] = useState<string>();
  const [salesUsers, setSalesUsers] = useState<SalesUsersProps[]>([]);
  const [filterData, setFilterData] = useState<SalesUsersProps[] | null>(null);
  const [snackbar, setSnackbar] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    getSalesUsers();
  }, []);

  const handleOpen = (id?: string): void => {
    setOpen(true);
    setIndex(id);
  };

  const handleClose = (): void => {
    setOpen(false);
    setIndex("");
  };

  const onAddEdit = (): void => {
    handleClose();
    getSalesUsers();
  };

  const closeSnackbar = (): void => {
    setSnackbar(false);
  };

  const handleCloseDelete = (): void => {
    setRemoveUser(false);
  };

  const handleDialogOpen = (id: string): void => {
    setRemoveUser(true);
    setIndex(id);
  };

  const filter = (value: string): void => {
    /* Functionality to search data */
    let filterData = salesUsers.filter((item: SalesUsersProps) => {
      let search = [item?.name, item?.email, item?.mobileNo];
      let regex = search.toString().toLowerCase();
      return regex?.includes(value?.toLowerCase());
    });
    setSearch(value);
    setFilterData(filterData);
  };

  const getSalesUsers = async () => {
    /* API call for getting sales users */
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(Constant.getSalesUsers);
      setLoading(false);
      const list = data?.data?.map((item: any) => ({
        _id: item?._id,
        name: item?.name,
        email: item?.email,
        mobileNo: item?.mobileNumber,
        permissionType: item?.permissionType === 0 ? "All" : "Partial",
      }));
      setSalesUsers(list);
    } catch (error: any) {
      setLoading(false);
      setSnackbar(true);
      setMessage(error?.data?.message);
    }
  };

  const deleteSalesUser = async () => {
    /* API call for deleting sales users */
    try {
      setDeleteSpinner(true);
      const formData = {
        id: index,
      };
      const { data } = await axiosInstance.post(
        Constant.removeSalesUser,
        formData
      );
      setDeleteSpinner(false);
      handleCloseDelete();
      setSnackbar(true);
      setMessage(data?.message);
      getSalesUsers();
    } catch (error: any) {
      setDeleteSpinner(false);
      handleCloseDelete();
      setSnackbar(true);
      setMessage(error?.data?.message);
    }
  };

  return {
    loading,
    search,
    open,
    handleOpen,
    handleClose,
    salesUsers,
    closeSnackbar,
    snackbar,
    message,
    deleteSpinner,
    filter,
    index,
    handleCloseDelete,
    removeUser,
    handleDialogOpen,
    deleteSalesUser,
    onAddEdit,
    filterData,
  };
};

export default salesController;
