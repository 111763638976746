import React from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import EditProductSlider from "@pages/home/companyDetails/allCompanyDetails/products/editProductSlider/EditProductSlider";
import { productCell } from "./product.const";
import productController from "./product.controller";
import { Table, Text } from "@component";
import {
  StyledContainer,
  StyledBox,
  StyledSearchInput,
  StyledProgressContainer,
} from "@pages/home/users/users.style";

const Product: React.FC = () => {
  const {
    handleSearch,
    loading,
    productList,
    search,
    searchList,
    deleteSpinner,
    handleCloseDelete,
    handleOpenDelete,
    deleteDialog,
    handleEdit,
    deleteProduct,
    editModal,
    handleCloseEdit,
    productDetail,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    totalDataCount,
  } = productController();
  const theme = useTheme();
  return (
    <StyledContainer>
      <StyledBox>
        <Box flex={1} m={theme.spacing(4)}>
          <Text variant="h4" color={theme.palette.primary.main}>
            Products
          </Text>
        </Box>
        <StyledSearchInput
          placeholder="Type something..."
          value={search}
          onChange={(val) => handleSearch(val?.target.value)}
        />
      </StyledBox>
      {loading ? (
        <StyledProgressContainer>
          <CircularProgress size={40} color={"primary"} />
        </StyledProgressContainer>
      ) : (
        <Table
          tableCellData={productCell}
          tableData={searchList === null ? productList : searchList}
          isEditButton
          isDeleteButton
          handleOpenDialog={handleOpenDelete}
          openDialog={deleteDialog}
          handleCloseDialog={handleCloseDelete}
          handleDeleteDialog={deleteProduct}
          handleDeleteDialogLoading={deleteSpinner}
          handleEdit={handleEdit}
          search={search}
          backendPagination={true}
          backendPageNumber={currentPage}
          backendRowPerPage={rowsPerPage}
          setBackendCurrentPage={setCurrentPage}
          setBackendRowsPerPage={setRowsPerPage}
          totalDataCount={totalDataCount}
        />
      )}
      <EditProductSlider
        openDrawer={editModal}
        handleCloseIcon={handleCloseEdit}
        userId={productDetail?.user_id}
        productId={productDetail?._id}
        type="master"
        data={productDetail}
      />
    </StyledContainer>
  );
};

export default Product;
