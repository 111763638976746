export const machiningData: any = [
  {
    name: "None",
    id: 121,
  },
  {
    name: "Hexo",
    id: 122,
  },
  {
    name: "Circular Band saw",
    id: 1,
    children: [
      {
        name: "Angular",
        id: 2,
      },
      {
        name: "Pillar",
        id: 3,
      },
    ],
  },
  {
    name: "Lathe",
    id: 4,
    children: [
      {
        name: "Light duty",
        id: 5,
      },
      {
        name: "Medium duty",
        id: 6,
      },
      {
        name: "Heavy duty",
        id: 7,
      },
    ],
  },
  {
    name: "VTL",
    id: 8,
    children: [
      {
        name: "Conventional",
        id: 9,
      },
      {
        name: "CNC",
        id: 10,
      },
    ],
  },
  {
    name: "Drilling",
    id: 11,
    children: [
      {
        name: "Pillar",
        id: 12,
      },
      {
        name: "Radial",
        id: 13,
      },
      {
        name: "CNC Drill",
        id: 14,
      },
    ],
  },
  {
    name: "Shaping",
    id: 15,
  },
  {
    name: "Planer",
    id: 16,
  },
  {
    name: "Milling",
    id: 17,
    children: [
      {
        name: "DRO",
        id: 18,
      },
      {
        name: "Conventional",
        id: 19,
      },
    ],
  },
  {
    name: "Grinding machine",
    id: 20,
    children: [
      {
        name: "Cylindrical",
        id: 21,
        children: [
          {
            id: 22,
            name: "Centerless",
          },
          {
            id: 23,
            name: "Internal",
            children: [
              {
                id: 24,
                name: "CNC",
              },
              {
                id: 25,
                name: "Conventional",
              },
            ],
          },
          {
            id: 26,
            name: "External",
            children: [
              {
                id: 27,
                name: "CNC",
              },
              {
                id: 28,
                name: "Conventional",
              },
            ],
          },
        ],
      },
      {
        id: 29,
        name: "Surface Grinding",
        children: [
          {
            id: 30,
            name: "Rotary table",
          },
          {
            id: 31,
            name: "Linear table",
          },
        ],
      },
    ],
  },
  {
    name: "Gear Shaper",
    id: 32,
    children: [
      {
        name: "CNC",
        id: 33,
      },
      {
        name: "Conventional",
        id: 34,
      },
    ],
  },
  {
    name: "Hobbing",
    id: 35,
    children: [
      {
        name: "CNC",
        id: 36,
      },
      {
        name: "Conventional",
        id: 37,
      },
    ],
  },
  {
    name: "Slotting",
    id: 38,
    children: [
      {
        name: "CNC",
        id: 39,
      },
      {
        name: "Conventional",
        id: 40,
      },
    ],
  },
  {
    name: "Boring",
    id: 41,
  },
  {
    name: "Plano miller",
    id: 42,
    children: [
      {
        name: "CNC",
        id: 43,
      },
      {
        name: "Conventional",
        id: 44,
      },
    ],
  },
  {
    name: "Honing",
    id: 45,
  },
  {
    name: "Gun drilling/Deep hole drilling",
    id: 46,
  },
  {
    name: "Boraching",
    id: 111,
  },
  {
    name: "CNC Turning",
    id: 47,
    children: [
      {
        name: "Small (Below 600mm)",
        id: 112,
      },
      {
        name: "Medium (Below 1200mm)",
        id: 113,
      },
      {
        name: "Large (Above 1500mm))",
        id: 114,
      },
    ],
  },
  {
    name: "HMC",
    id: 48,
    children: [
      {
        name: "Fixed Table",
        id: 49,
      },
      {
        name: "Rotating Table",
        id: 50,
      },
    ],
  },
  {
    name: "VMC",
    id: 51,
    children: [
      {
        name: "3 axis",
        id: 52,
        children: [
          {
            name: "Small (Below 600mm)",
            id: 53,
          },
          {
            name: "Medium (Below 1200mm)",
            id: 55,
          },
          {
            name: "Large (Above 1500mm)",
            id: 56,
          },
        ],
      },
      {
        name: "4 axis",
        id: 57,
        children: [
          {
            name: "Small (Below 600mm)",
            id: 58,
          },
          {
            name: "Medium (Below 1200mm)",
            id: 59,
          },
          {
            name: "Large (Above 1500mm)",
            id: 60,
          },
        ],
      },
      {
        name: "5 axis",
        id: 61,
      },
      {
        name: "7 axis",
        id: 62,
      },
    ],
  },
  {
    name: "Tool and Cutter Grinder",
    id: 63,
  },
  {
    name: "EDM/Spark",
    id: 64,
  },
  {
    name: "EDM Drill",
    id: 65,
  },
  {
    name: "Wire cut",
    id: 66,
    children: [
      {
        name: "Single use",
        id: 97,
      },
      {
        name: "Reuse",
        id: 98,
      },
    ],
  },
  {
    name: "Traub",
    id: 99,
  },
  {
    name: "Thread milling",
    id: 100,
  },
  {
    name: "Water jet",
    id: 115,
  },
  {
    name: "Laser cutting",
    id: 101,
    children: [
      {
        name: "Bending",
        id: 116,
      },
    ],
  },
  {
    name: "Plasma cutting",
    id: 102,
    children: [
      {
        name: "Punching",
        id: 117,
        children: [
          {
            name: "CNC",
            id: 118,
          },
          {
            name: "Conventional",
            id: 119,
          },
        ],
      },
    ],
  },
  {
    name: "Threading",
    id: 103,
  },
  {
    name: "Laser Marking",
    id: 104,
  },
  {
    name: "Sheet cutting",
    id: 105,
  },
  {
    name: "Others",
    id: 110,
  },
];
