import { createBrowserRouter } from "react-router-dom";
import ForgotPassword from "@pages/auth/forgotPassword/ForgotPassword";
import Login from "@pages/auth/login/Login";
import Drawer from "@pages/wrapper/drawer/Drawer";
import Dashboard from "@pages/home/dashboard/Dashboard";
import Users from "@pages/home/users/Users";
import Company from "@pages/home/company/Company";
import AppBar from "@pages/wrapper/appBar/AppBar";
import ManageAdmins from "@pages/home/manageAdmins/ManageAdmins";
import Support from "@pages/home/support/Support";
import Reports from "@pages/home/reports/Reports";
import Account from "@pages/home/account/Account";
import UserDetails from "@pages/home/userDetails/UserDetails";
import CompanyDetails from "@pages/home/companyDetails/CompanyDetails";
import AboutUs from "@pages/home/aboutUs/AboutUs";
import Qualifications from "@pages/home/qualifications/Qualifications";
import Zone from "@pages/home/zone/Zone";
import AllUsers from "@pages/home/allUsers/AllUsers";
import Expertise from "@pages/home/expertise/Expertise";
import Machining from "@pages/home/machining/Machining";
import Processes from "@pages/home/processes/Processes";
import MainSubcategory from "@pages/home/expertise/mainSubcategory/MainSubcategory";
import Subcategory from "@pages/home/expertise/subcategory/Subcategory";
import MachineMainSubcategory from "@pages/home/machining/machineMainSubcategory/MachineMainSubcategory";
import MachineSubcategory from "@pages/home/machining/machineSubcategory/MachineSubcategory";
import ProcessMainSubcategory from "@pages/home/processes/processMainSubcategory/ProcessMainSubcategory";
import ProcessSubcategory from "@pages/home/processes/processSubcategory/ProcessSubcategory";
import Sales from "@pages/home/sales/Sales";
import SalesReport from "@pages/home/sales/salesReport/salesReport";
import MassNotification from "@pages/home/massNotification/MassNotification";
import Product from "@pages/home/postRequirements/product/Product";
import Jobwork from "@pages/home/postRequirements/jobwork/Jobwork";
import JobVacancy from "@pages/home/postRequirements/jobVacancy/JobVacancy";
import PortfolioProduct from "@pages/home/portfolio/product/Product";
import PortfolioJobwork from "@pages/home/portfolio/jobwork/Jobwork";

export const routes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "/drawer",
    element: <Drawer />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "users/:userId/userDetails",
        element: <UserDetails />,
      },
      {
        path: "company",
        element: <Company />,
      },
      {
        path: "company/:companyId/companyDetails",
        element: <CompanyDetails />,
      },
      {
        path: "allUsers",
        element: <AllUsers />,
      },
      {
        path: "qualifications",
        element: <Qualifications />,
      },
      {
        path: "zone",
        element: <Zone />,
      },
      {
        path: "product",
        element: <Product />,
      },
      {
        path: "jobwork",
        element: <Jobwork />,
      },
      {
        path: "jobVacancy",
        element: <JobVacancy />,
      },
      {
        path: "expertise",
        element: <Expertise />,
      },
      {
        path: "expertise/mainSubcategory",
        element: <MainSubcategory />,
      },
      {
        path: "expertise/mainSubcategory/subcategory",
        element: <Subcategory />,
      },
      {
        path: "machining",
        element: <Machining />,
      },
      {
        path: "machining/machineMainSubcategory",
        element: <MachineMainSubcategory />,
      },
      {
        path: "machining/machineMainSubcategory/machineSubcategory",
        element: <MachineSubcategory />,
      },
      {
        path: "processes",
        element: <Processes />,
      },
      {
        path: "processes/processMainSubcategory",
        element: <ProcessMainSubcategory />,
      },
      {
        path: "processes/processMainSubcategory/processSubcategory",
        element: <ProcessSubcategory />,
      },
      {
        path: "sales",
        element: <Sales />,
      },
      {
        path: "salesReport",
        element: <SalesReport />,
      },
      {
        path: "massNotification",
        element: <MassNotification />,
      },
      {
        path: "aboutUs",
        element: <AboutUs />,
      },
      {
        path: "portfolio/product",
        element: <PortfolioProduct />,
      },
      {
        path: "portfolio/jobwork",
        element: <PortfolioJobwork />,
      },
      {
        path: "appBar",
        element: <AppBar />,
        children: [
          {
            path: "account",
            element: <Account />,
          },
          {
            path: "manageAdmins",
            element: <ManageAdmins />,
          },
          {
            path: "support",
            element: <Support />,
          },
          {
            path: "reports",
            element: <Reports />,
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export default router;
