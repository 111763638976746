import { useEffect, useState } from "react";
import moment from "moment";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  FormDataProps,
  SalesDataProps,
  SalesReportControllerProps,
} from "./salesReport.interface";
import { SelectChangeEvent } from "@mui/material";
import { SalesUsersProps } from "../sales.interface";

const salesReportController = (): SalesReportControllerProps => {
  const [salesData, setSalesData] = useState<SalesDataProps[]>([]);
  const [salesUsers, setSalesUsers] = useState<SalesUsersProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<boolean>(false);
  const [toast, setToast] = useState<string>();
  const [filterType, setFilterType] = useState<string>("All");
  const [salesId, setSalesId] = useState<string>("");

  useEffect(() => {
    getSalesUsers();
  }, []);

  useEffect(() => {
    if (salesUsers?.length !== 0 && salesId.length !== 0) {
      getSalesData();
    }
  }, [filterType, salesId]);

  const getSalesUsers = async () => {
    /* API call for getting sales users */
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(Constant.getSalesUsers);
      setLoading(false);
      const list = data?.data?.map((item: any) => ({
        _id: item?._id,
        name: item?.name,
      }));
      setSalesUsers(list);
    } catch (error: any) {
      setLoading(false);
      setSnackbar(true);
      setToast(error?.data?.message);
    }
  };

  const getSalesData = async () => {
    /* API call for getting sales data */
    try {
      setLoading(true);
      const formData: FormDataProps = {
        salesPersonId: salesId,
      };
      if (filterType === "Today" ? 1 : null) {
        formData.today = 1;
      }
      if (filterType === "This Week") {
        formData.lastSevenDay = 1;
      }
      if (filterType === "Monthly" ? 1 : null) {
        formData.monthly = 1;
      }
      const { data } = await axiosInstance.post(
        Constant.salesUserReport,
        formData
      );
      const list = data?.data?.map((item: SalesDataProps) => ({
        _id: item?._id,
        profileImage: item?.profileImage,
        name: item?.firstName,
        userType: item?.registerType === 0 ? "Company" : "Person",
        email: item?.email,
        mobileNo: item?.mobileNumber,
        createdAt: moment(item?.createdAt).format("DD/MM/YYYY"),
        lastLogin: item?.lastLogin
          ? moment(item?.lastLogin).format("LLL")
          : "-",
      }));
      setSalesData(list);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setSnackbar(true);
      setToast(error?.data?.message);
    }
  };

  const handleFilterType = (event: SelectChangeEvent): void => {
    setFilterType(event.target.value);
  };

  const handleUser = (event: SelectChangeEvent): void => {
    setSalesId(event.target.value);
  };

  return {
    loading,
    salesData,
    setSnackbar,
    snackbar,
    toast,
    handleFilterType,
    filterType,
    salesUsers,
    handleUser,
    salesId,
  };
};

export default salesReportController;
