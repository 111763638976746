import React, { memo } from "react";
import { FileDownload } from "@mui/icons-material";
import { DownloadProps } from "./download.interface";
import { StyledIconButton } from "./download.style";

const Download: React.FC<DownloadProps> = ({ onIconClick, id }) => {
  return (
    <StyledIconButton onClick={onIconClick} id={id}>
      <FileDownload fontSize="medium" color="info" />
    </StyledIconButton>
  );
};

export default memo(Download);
