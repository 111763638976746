import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  ExpertiseControllerProps,
  ExpertiseDataprops,
  FormDataProps,
} from "./expertise.interface";
import validationMessage from "@utils/validation/validationMessage";

const expertiseController = (): ExpertiseControllerProps => {
  const navigation = useNavigate();
  const [search, setSearch] = useState<string>();
  const [expertise, setExpertise] = useState<string>();
  const [expertiseData, setExpertiseData] = useState<ExpertiseDataprops[]>([]);
  const [filterData, setFilterData] = useState<ExpertiseDataprops[] | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [addSpinner, setAddSpinner] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<string>();
  const [error, setError] = useState<string>();
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  useEffect(() => {
    getExpertise();
  }, []);

  const onView = (id: string): void => {
    if (id) {
      const list = expertiseData?.filter((item) => item?._id === id)[0];
      navigation("mainSubcategory", { state: list });
    }
  };

  const handleOpen = (item?: any): void => {
    setOpen(true);
    if (item?._id) {
      setIndex(item?._id);
      setExpertise(item?.expertise);
    }
  };

  const handleClose = (): void => {
    setOpen(false);
    setIndex("");
    setExpertise("");
    setError("");
  };

  const searchList = (value: string): void => {
    /* Functionality to search expertise */
    let filterData = expertiseData.filter((item: ExpertiseDataprops) => {
      let search = [item?.expertise];
      let regex = search.toString().toLowerCase();
      return regex?.includes(value?.toLowerCase());
    });
    setSearch(value);
    setFilterData(filterData);
  };

  const getExpertise = async () => {
    /* API call for getting Expertise data */
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(Constant.getExpertise);
      setLoading(false);
      const list = data?.data?.map((item: any, index: number) => ({
        _id: item?._id,
        serialNo: index + 1,
        expertise: item?.expertise,
        children: item?.mainSubcategory,
        isSubcategory: item?.mainSubcategory?.length ? "Yes" : "No",
        subcategoryLength: item?.mainSubcategory?.length,
        status: item?.status,
      }));
      setExpertiseData(list);
    } catch (error) {
      setLoading(false);
    }
  };

  const addExpertise = async () => {
    /* API call for adding/updating Expertise */
    try {
      setAddSpinner(true);
      const formData: FormDataProps = {
        type: 1,
        expertise: expertise,
      };
      if (index) {
        formData.expertiseId = index;
      }
      const url = index ? Constant.editExpertise : Constant.addExpertise;
      const { data } = await axiosInstance.post(url, formData);
      setAddSpinner(false);
      getExpertise();
      handleClose();
      setOpenSnackbar(true);
      setSnackbarMessage(data?.message);
    } catch (error: any) {
      setAddSpinner(false);
      setOpenSnackbar(true);
      setSnackbarMessage(error?.data?.message);
    }
  };

  const validation = (): void => {
    let isValid = true;
    if (!expertise?.trim()) {
      isValid = false;
      setError(validationMessage.emptyExpertise);
    } else {
      setError("");
    }
    if (isValid) {
      addExpertise();
    }
  };

  const updateStatus = async (id: string, check: boolean) => {
    /* API call for updating expertise status */
    try {
      const formData = {
        type: 1,
        expertiseId: id,
        status: check ? 1 : 0,
      };
      const { data } = await axiosInstance.post(
        Constant.updateExpertiseStatus,
        formData
      );
      setOpenSnackbar(true);
      setSnackbarMessage(data?.message);
      getExpertise();
    } catch (error: any) {
      setOpenSnackbar(true);
      setSnackbarMessage(error?.data?.message);
    }
  };

  return {
    search,
    loading,
    onView,
    handleClose,
    handleOpen,
    open,
    index,
    setExpertise,
    expertise,
    expertiseData,
    searchList,
    filterData,
    addSpinner,
    error,
    validation,
    updateStatus,
    setOpenSnackbar,
    openSnackbar,
    snackbarMessage,
  };
};

export default expertiseController;
