import React from "react";
import {
  AddIcon,
  BackButton,
  MasterModal,
  Spinner,
  Table,
  SnackBar,
} from "@component";
import mainSubcategoryController from "./mainSubcategory.controller";
import { tableCell } from "../expertise.const";
import {
  StyledBox,
  StyledContainer,
  StyledSearchInput,
  StyledText,
} from "../expertise.style";

const MainSubcategory: React.FC = () => {
  const {
    search,
    onView,
    handleClose,
    handleOpen,
    open,
    setMainSubcategory,
    index,
    mainSubcategory,
    mainSubcategoryData,
    expertise,
    searchList,
    filterData,
    addSpinner,
    error,
    validation,
    updateStatus,
    loading,
    setOpenSnackbar,
    openSnackbar,
    snackbarMessage,
  } = mainSubcategoryController();
  return (
    <StyledContainer>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <StyledBox>
            <BackButton />
            <StyledText variant="h4">{expertise}</StyledText>
            <StyledSearchInput
              placeholder="Type something..."
              value={search}
              onChange={(val) => {
                searchList(val?.target?.value);
              }}
            />
            <AddIcon onAdd={() => handleOpen()} />
          </StyledBox>
          <Table
            tableData={filterData !== null ? filterData : mainSubcategoryData}
            tableCellData={tableCell}
            isViewButton
            onView={onView}
            isEditButton
            handleEdit={(item) => handleOpen(item)}
            handleSwitch={(id, check) => {
              updateStatus(id, check);
            }}
            search={search}
          />
        </>
      )}
      <MasterModal
        open={open}
        handleClose={handleClose}
        title={index ? "Update Main Subcategory" : "Add Main Subcategory"}
        buttonTitle={index ? "Update" : "Add"}
        placeholder="Main Subcategory"
        value={mainSubcategory}
        setValue={(val) => setMainSubcategory(val?.target?.value)}
        loading={addSpinner}
        handleButton={validation}
        error={error}
      />
      <SnackBar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        onCloseClick={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message={snackbarMessage}
        autoHideDuration={5000}
      />
    </StyledContainer>
  );
};

export default MainSubcategory;
