import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "@services/api/api";
import { ChildrenProps, FormDataProps } from "../expertise.interface";
import { Constant } from "@services/constant";
import { MainSubcategoryControllerProps } from "./mainSubcategory.interface";
import validationMessage from "@utils/validation/validationMessage";

const mainSubcategoryController = (): MainSubcategoryControllerProps => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [mainSubcategory, setMainSubcategory] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<string>();
  const [addSpinner, setAddSpinner] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [mainSubcategoryData, setMainSubcategoryData] = useState<
    ChildrenProps[]
  >([]);
  const [filterData, setFilterData] = useState<ChildrenProps[] | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const data = useLocation();
  const { expertise, _id } = data?.state;

  useEffect(() => {
    getExpertise();
  }, []);

  const getExpertise = async () => {
    /* API call for getting Expertise data */
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(Constant.getExpertise);
      setLoading(false);
      const list = data?.data
        ?.filter((item: any) => item?._id === _id)[0]
        ?.mainSubcategory?.map((item: ChildrenProps, index: number) => ({
          _id: item?._id,
          serialNo: index + 1,
          expertise: item?.expertise,
          children: item?.subcategory,
          isSubcategory: item?.subcategory?.length ? "Yes" : "No",
          subcategoryLength: item?.subcategory?.length,
          status: item?.status,
        }));
      setMainSubcategoryData(list);
    } catch (error) {
      setLoading(false);
    }
  };

  const onView = (id: string): void => {
    if (id) {
      const list = mainSubcategoryData?.filter(
        (item: ChildrenProps) => item?._id === id
      )[0];
      navigation("subcategory", {
        state: { list: list, mainCategory: { expertise, _id } },
      });
    }
  };

  const handleOpen = (item?: any): void => {
    setOpen(true);
    if (item?._id) {
      setIndex(item?._id);
      setMainSubcategory(item?.expertise);
    }
  };

  const handleClose = (): void => {
    setOpen(false);
    setIndex("");
    setMainSubcategory("");
    setError("");
  };

  const searchList = (value: string): void => {
    /* Functionality to search expertise */
    let filterData = mainSubcategoryData?.filter((item: ChildrenProps) => {
      let search = [item?.expertise];
      let regex = search.toString().toLowerCase();
      return regex?.includes(value?.toLowerCase());
    });
    setSearch(value);
    setFilterData(filterData);
  };

  const addMainSubcategory = async () => {
    /* API call for adding/updating Main Subcategory */
    try {
      setAddSpinner(true);
      const formData: FormDataProps = {
        type: 2,
        expertiseId: _id,
        mainSubcategoryExpertise: mainSubcategory,
      };
      if (index) {
        formData.mainSubcategoryId = index;
      }
      const url = index ? Constant.editExpertise : Constant.addExpertise;
      const { data } = await axiosInstance.post(url, formData);
      setAddSpinner(false);
      getExpertise();
      handleClose();
      setOpenSnackbar(true);
      setSnackbarMessage(data?.message);
    } catch (error: any) {
      setAddSpinner(false);
      setOpenSnackbar(true);
      setSnackbarMessage(error?.data?.message);
    }
  };

  const validation = (): void => {
    let isValid = true;
    if (!mainSubcategory?.trim()) {
      isValid = false;
      setError(validationMessage.emptyMainSubcategory);
    } else {
      setError("");
    }
    if (isValid) {
      addMainSubcategory();
    }
  };

  const updateStatus = async (id: string, check: boolean) => {
    /* API call for updating expertise status */
    try {
      const formData = {
        type: 2,
        expertiseId: _id,
        mainSubcategoryId: id,
        status: check ? 1 : 0,
      };
      const { data } = await axiosInstance.post(
        Constant.updateExpertiseStatus,
        formData
      );
      getExpertise();
      setOpenSnackbar(true);
      setSnackbarMessage(data?.message);
    } catch (error: any) {
      setOpenSnackbar(true);
      setSnackbarMessage(error?.data?.message);
    }
  };

  return {
    search,
    onView,
    handleClose,
    handleOpen,
    open,
    setMainSubcategory,
    index,
    mainSubcategory,
    mainSubcategoryData,
    expertise,
    searchList,
    filterData,
    addSpinner,
    error,
    validation,
    updateStatus,
    loading,
    setOpenSnackbar,
    openSnackbar,
    snackbarMessage,
  };
};

export default mainSubcategoryController;
