import {
  Dashboard,
  Person,
  Article,
  FolderCopySharp,
  WorkOutlined,
  Settings,
  PeopleAlt,
  Assessment,
  Notifications,
  Star,
} from "@mui/icons-material";
import { DrawerDataProps } from "./drawer.interface";

export const drawerData: DrawerDataProps[] = [
  {
    id: 1,
    title: "Dashboard",
    icon: Dashboard,
    navigation: "dashboard",
  },
  {
    id: 2,
    title: "Users",
    icon: Person,
    navigation: "users",
  },
  {
    id: 3,
    title: "Company",
    icon: WorkOutlined,
    navigation: "company",
  },
  {
    id: 4,
    title: "All Users",
    icon: PeopleAlt,
    navigation: "allUsers",
  },
  {
    id: 5,
    title: "Sales Report",
    icon: Assessment,
    navigation: "SalesReport",
  },
  {
    id: 6,
    title: "Master",
    icon: FolderCopySharp,
    expand: true,
    subData: [
      {
        id: 1,
        icon: Star,
        name: "Qualifications",
        screen: "qualifications",
      },
      {
        id: 2,
        icon: Star,
        name: "Zone",
        screen: "zone",
      },
      // {
      //   id: 3,
      //   icon: Star,
      //   name: "Expertise",
      //   screen: "expertise",
      // },
      // {
      //   id: 4,
      //   icon: Star,
      //   name: "Machine",
      //   screen: "machining",
      // },
      // {
      //   id: 5,
      //   icon: Star,
      //   name: "Process",
      //   screen: "processes",
      // },
      {
        id: 6,
        icon: Star,
        name: "Sales",
        screen: "Sales",
      },
    ],
  },
  {
    id: 7,
    title: "Post Requirement",
    icon: FolderCopySharp,
    expand: true,
    subData: [
      {
        id: 1,
        icon: Star,
        name: "Product",
        screen: "product",
      },
      {
        id: 2,
        icon: Star,
        name: "Jobwork",
        screen: "jobwork",
      },
      {
        id: 3,
        icon: Star,
        name: "Job Vacancy",
        screen: "jobVacancy",
      },
    ],
  },
  {
    id: 8,
    title: "Portfolio",
    icon: FolderCopySharp,
    expand: true,
    subData: [
      {
        id: 1,
        icon: Star,
        name: "Product",
        screen: "portfolio/product",
      },
      {
        id: 2,
        icon: Star,
        name: "Jobwork",
        screen: "portfolio/jobwork",
      },
    ],
  },
  {
    id: 9,
    title: "Mass Notification",
    icon: Notifications,
    navigation: "massNotification",
  },
  {
    id: 10,
    title: "Settings",
    icon: Settings,
    navigation: "appBar/account",
  },
  {
    id: 11,
    title: "About Us",
    icon: Article,
    navigation: "aboutUs",
  },
];
