export const cellData = [
  {
    id: 1,
    title: "Title",
  },
  {
    id: 2,
    title: "Detail",
  },
  {
    id: 3,
    title: "Created On",
  },
];
