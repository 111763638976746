import React from "react";
import {
  AddIcon,
  BackButton,
  MasterModal,
  SnackBar,
  Spinner,
  Table,
} from "@component";
import machineSubcategoryController from "./machineSubcategory.controller";
import { tableCell } from "../../expertise/expertise.const";
import {
  StyledBox,
  StyledContainer,
  StyledSearchInput,
  StyledText,
} from "../../expertise/expertise.style";

const MachineSubcategory: React.FC = () => {
  const {
    handleClose,
    handleOpen,
    open,
    setSubcategory,
    index,
    search,
    subcategory,
    subcategoryData,
    title,
    searchList,
    filterData,
    addSpinner,
    error,
    validation,
    updateStatus,
    loading,
    setOpenSnackbar,
    openSnackbar,
    snackbarMessage,
  } = machineSubcategoryController();
  let data = tableCell.filter(
    (item) => item.id !== 7 && item.id !== 3 && item.id !== 4
  );
  return (
    <StyledContainer>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <StyledBox>
            <BackButton />
            <StyledText variant="h4">{title}</StyledText>
            <StyledSearchInput
              placeholder="Type something..."
              value={search}
              onChange={(val) => {
                searchList(val?.target?.value);
              }}
            />
            <AddIcon onAdd={() => handleOpen()} />
          </StyledBox>
          {subcategoryData && (
            <Table
              tableData={filterData !== null ? filterData : subcategoryData}
              tableCellData={data}
              isEditButton
              handleEdit={(item) => handleOpen(item)}
              handleSwitch={(id, check) => updateStatus(id, check)}
              search={search}
            />
          )}
        </>
      )}
      <MasterModal
        open={open}
        handleClose={handleClose}
        title={index ? "Update Subcategory" : "Add Subcategory"}
        buttonTitle={index ? "Update" : "Add"}
        placeholder="Subcategory"
        value={subcategory}
        setValue={(val) => setSubcategory(val?.target?.value)}
        loading={addSpinner}
        handleButton={validation}
        error={error}
      />
      <SnackBar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        onCloseClick={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message={snackbarMessage}
        autoHideDuration={5000}
      />
    </StyledContainer>
  );
};

export default MachineSubcategory;
