import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  ExpertiseDataprops,
  FormDataProps,
} from "../expertise/expertise.interface";
import { ProcessesControllerProps } from "./processes.interface";
import validationMessage from "@utils/validation/validationMessage";

const processesController = (): ProcessesControllerProps => {
  const navigation = useNavigate();
  const [search, setSearch] = useState<string>();
  const [process, setProcess] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<string>();
  const [processData, setProcessData] = useState<any>([]);
  const [filterData, setFilterData] = useState<ExpertiseDataprops[] | null>(
    null
  );
  const [addSpinner, setAddSpinner] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  useEffect(() => {
    getProcess();
  }, []);

  const onView = (id: string): void => {
    if (id) {
      const list = processData?.filter((item: any) => item?._id === id)[0];
      navigation("processMainSubcategory", { state: list });
    }
  };

  const handleOpen = (item?: any): void => {
    setOpen(true);
    if (item?._id) {
      setIndex(item?._id);
      setProcess(item?.expertise);
    }
  };

  const handleClose = (): void => {
    setOpen(false);
    setIndex("");
    setProcess("");
    setError("");
  };

  const getProcess = async () => {
    /* API call for getting process data */
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(Constant.getProcess);
      setLoading(false);
      const list = data?.data?.map((item: any, index: number) => ({
        _id: item?._id,
        serialNo: index + 1,
        expertise: item?.processes,
        children: item?.mainSubcategory,
        isSubcategory: item?.mainSubcategory?.length ? "Yes" : "No",
        subcategoryLength: item?.mainSubcategory?.length,
        status: item?.status,
      }));
      setProcessData(list);
    } catch (error) {
      setLoading(false);
    }
  };

  const searchList = (value: string): void => {
    /* Functionality to search process */
    let filterData = processData?.filter((item: ExpertiseDataprops) => {
      let search = [item?.expertise];
      let regex = search.toString().toLowerCase();
      return regex?.includes(value?.toLowerCase());
    });
    setSearch(value);
    setFilterData(filterData);
  };

  const addProcess = async () => {
    /* API call for adding/updating Process */
    try {
      setAddSpinner(true);
      const formData: FormDataProps = {
        type: 1,
        processes: process,
      };
      if (index) {
        formData.processesId = index;
      }
      const url = index ? Constant.editProcess : Constant.addProcess;
      const { data } = await axiosInstance.post(url, formData);
      setAddSpinner(false);
      handleClose();
      getProcess();
      setOpenSnackbar(true);
      setSnackbarMessage(data?.message);
    } catch (error: any) {
      setAddSpinner(false);
      setOpenSnackbar(true);
      setSnackbarMessage(error?.data?.message);
    }
  };

  const validation = (): void => {
    let isValid = true;
    if (!process?.trim()) {
      isValid = false;
      setError(validationMessage.emptyProcess);
    } else {
      setError("");
    }
    if (isValid) {
      addProcess();
    }
  };

  const updateStatus = async (id: string, check: boolean) => {
    /* API call for updating process status */
    try {
      const formData = {
        type: 1,
        processesId: id,
        status: check ? 1 : 0,
      };
      const { data } = await axiosInstance.post(
        Constant.updateProcessStatus,
        formData
      );
      setOpenSnackbar(true);
      setSnackbarMessage(data?.message);
      getProcess();
    } catch (error: any) {
      setOpenSnackbar(true);
      setSnackbarMessage(error?.data?.message);
    }
  };

  return {
    search,
    loading,
    onView,
    handleClose,
    handleOpen,
    open,
    index,
    setProcess,
    process,
    processData,
    searchList,
    filterData,
    addSpinner,
    error,
    validation,
    updateStatus,
    setOpenSnackbar,
    openSnackbar,
    snackbarMessage,
  };
};

export default processesController;
