import React, { memo } from "react";
import { Avatar, useTheme } from "@mui/material";
import { AdminListProps } from "./adminList.interface";
import { image } from "@assets";
import Text from "@component/text/Text";
import { useAppSelector } from "@services/redux/controller";
import {
  StyledAdminBox,
  StyledBox,
  StyledButton,
  StyledContainer,
  StyledEmailText,
  StyledTextWithBackground,
} from "./adminList.style";

const AdminList: React.FC<AdminListProps> = ({ item }) => {
  const theme = useTheme();
  const user = useAppSelector((state) => state.userReducer);
  return (
    <StyledContainer>
      <Avatar src={image.userProfile} />
      <StyledBox>
        <Text variant="h6" color={theme.palette.primary.main}>
          {`${item.firstName} ${item.lastName}`}
        </Text>
        <StyledEmailText variant="h6">{item.email}</StyledEmailText>
      </StyledBox>
      <StyledAdminBox>
        <StyledTextWithBackground>Admin</StyledTextWithBackground>
        <StyledButton
          size="large"
          variant="text"
          disabled={user.userData.adminType === 0}
          id={`deactivate${item._id}`}
        >
          Deactivate
        </StyledButton>
      </StyledAdminBox>
    </StyledContainer>
  );
};

export default memo(AdminList);
