import { Box, IconButton, styled } from "@mui/material";
import CircularProgress from "@component/circularProgress/CircularProgress";
import Text from "@component/text/Text";

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: theme.spacing(6, 0),
  borderBottom: "1px solid",
  borderBottomColor: theme.palette.grey[400],
  padding: theme.spacing(4, 0),
}));

export const StyledPlaceholder = styled("img")(({ theme }) => ({
  height: 110,
  width: 105,
  borderRadius: theme.spacing(2),
  marginRight: theme.spacing(12),
  [theme.breakpoints.down("sm")]: {
    height: 95,
    width: 90,
  },
}));

export const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.primary.dark,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const StyledIconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  marginTop: theme.spacing(4),
}));
