import { IconButton, styled } from "@mui/material";

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(6),
  background: theme.palette.primary.main,
  alignItems: "center",
  justifyContent: "center",
  marginLeft: theme.spacing(6),
  "&:hover": {
    background: theme.palette.grey.A400,
  },
}));
