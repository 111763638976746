export const registerTypeList = [
  {
    id: 1,
    name: "All Permissions",
    number: 0,
  },
  {
    id: 2,
    name: "Partial Permissions",
    number: 1,
  },
];
