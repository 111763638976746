import React, { memo } from "react";
import { MenuItem, useTheme } from "@mui/material";
import addUserController from "./addUser.controller";
import { AddUserProps } from "./addUser.interface";
import {
  StyledButton,
  StyledButtonContainer,
  StyledContainer,
  StyledErrorText,
  StyledInput,
} from "./addUser.style";
import { StyledSelection } from "../userProfileStepOne/userProfileStepOne.style";

const AddUser: React.FC<AddUserProps> = ({ handleNextButton, userId }) => {
  const theme = useTheme();
  const {
    firstName,
    setFirstName,
    email,
    setEmail,
    mobileNo,
    setMobileNo,
    selectZone,
    handleZone,
    password,
    setPassword,
    error,
    validation,
    loading,
    zones,
    editData,
    setOtherZone,
    otherZone,
  } = addUserController({ handleNextButton, userId });
  return (
    <StyledContainer>
      <StyledInput
        variant="outlined"
        placeholder="Full Name"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={firstName}
        onChange={(val) => setFirstName(val?.target?.value)}
        helperText={error.firstName}
      />
      <StyledInput
        variant="outlined"
        placeholder="Email"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={email}
        onChange={(val) => setEmail(val?.target?.value)}
        helperText={error.email}
      />
      <StyledInput
        variant="outlined"
        placeholder="Mobile Number"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={mobileNo}
        onChange={(val) => setMobileNo(val.target.value.slice(0, 10))}
        helperText={error.mobileNo}
        sx={{ mb: 3 }}
      />
      <StyledSelection
        labelname={editData[0]?.zone ? editData[0]?.zone : "Zone"}
        inputlabelstyle={{
          "&.Mui-focused": {
            color: theme.palette.grey[900],
          },
        }}
        value={selectZone}
        onChange={(e: any) => handleZone(e)}
        MenuProps={{
          PaperProps: { sx: { maxHeight: "33%" } },
        }}
      >
        {zones.map((item) => (
          <MenuItem key={item._id} value={item?.zone}>
            {item?.zone}
          </MenuItem>
        ))}
      </StyledSelection>
      <StyledErrorText>{error.zone}</StyledErrorText>
      {selectZone === "Other" && (
        <StyledInput
          variant="outlined"
          placeholder="Other Zone"
          InputLabelProps={{
            style: { color: theme.palette.common.black },
          }}
          value={otherZone}
          onChange={(val) => setOtherZone(val?.target?.value)}
          helperText={error.otherZone}
        />
      )}
      {!userId && (
        <StyledInput
          variant="outlined"
          placeholder="Password"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={password}
          onChange={(val) => setPassword(val?.target?.value)}
          helperText={error.password}
          showPasswordIcon
        />
      )}
      <StyledButtonContainer>
        <StyledButton onClick={validation} loading={loading}>
          Next
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default memo(AddUser);
